import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import HelloSign from '../lib/hellosign';

import {
  editRelease,
  readRelease,
} from '../state/releases';

const baseStyles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  hellosignContainer: {
    margin: '25px',
  },
  mainBody: {
    margin: '10px',
  },
  providerStyle: {
    margin: '0px auto 25px',
    width: '650px',
  },
};


export class SignleRelease extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.openDocument = this.openDocument.bind(this);
    this.editRelease = this.editRelease.bind(this);
  }
  componentWillMount() {
    const { new_release } = this.props.location.query;
    const { clinicId, releaseId } = this.props.routeParams;
    if (!new_release) {
      this.props.readRelease(clinicId, releaseId);
    } else {
      this.openDocument();
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.release !== this.props.release) {
      this.openDocument();
    }
  }
  openDocument() {
    const { router, release } = this.props;
    const { clinicId } = this.props.routeParams;
    const { editRelease } = this;
    const container = this.hellosignContainer;
    HelloSign.open({
      url: release.hellosign_edit_url,
      container,
      messageListener: (eventData) => {
        if (eventData.event === HelloSign.EVENT_TEMPLATE_CREATED) {
          const { title } = eventData.template_info;
          editRelease({ name: title, status: 'Complete' });
          router.push(`/clinics/${clinicId}/releases`);
        } else if (eventData.event === HelloSign.EVENT_CANCELED) {
          router.push(`/clinics/${clinicId}/releases`);
        }
      },
    });
  }
  editRelease(update) {
    const { releaseId, clinicId } = this.props.routeParams;
    this.props.editRelease(clinicId, releaseId, update);
  }
  render() {
    const { release, isFetching } = this.props;
    let noReleaseMessage;
    if (!release && isFetching) {
      noReleaseMessage = <h1> Fetching </h1>;
    }

    if (!release && !isFetching) {
      noReleaseMessage = <h1> Not Found </h1>;
    }
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          { noReleaseMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{release.name}</h1>
            </div>
          }
          <div ref={container => this.hellosignContainer = container} style={baseStyles.hellosignContainer} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { releases } = state;
  const { releaseId } = ownProps.routeParams;
  const release = releases.data[releaseId];

  return {
    release,
    isFetching: releases.isFetching,
  };
}

const actionCreators = {
  editRelease,
  readRelease,
};

SignleRelease.defaultProps = {
  isFetching: true,
  release: null,
};

SignleRelease.propTypes = {
  readRelease: PropTypes.func.isRequired,
  editRelease: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  location: PropTypes.object.isRequired,
  release: PropTypes.object,
  routeParams: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(SignleRelease);
