import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, find, map, reject } from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/RemoveCircle';
import { withStyles } from '@material-ui/core/styles';

import Header from '../components/header';
import User from '../components/user';
import AutoCompleteSelect from '../components/auto-complete-select';
import { apiFetch } from '../lib/fetch';

import { browseCustomers } from '../state/customers';
import { browseClinicsIfNecessary } from '../state/clinics';

import {
  editUser, readUser,
} from '../state/users';


const styles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
  userContainer: {
    margin: '0px auto 25px',
    width: '900px',
  },
};

export class SingleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessableClinics: [],
    };
    this.handleEditUser = this.handleEditUser.bind(this);
  }
  componentWillMount() {
    const { customers, userId } = this.props;

    if (userId) {
      this.props.readUser(userId);

      if (!customers || !customers.length) {
        this.props.browseCustomers();
      }

      this.props.browseClinicsIfNecessary();

      apiFetch(`/users/${userId}/clinics`)
        .then((accessableClinics) => {
          this.setState({ accessableClinics });
        });
    } else {
      this.props.router.push('/users');
    }
  }
  handleEditUser(details) {
    return this.props.editUser(details);
  }
  handleAddUserClinic = (clinicId) => {
    const { userId } = this.props;
    const options = {
      method: 'POST',
      body: { user_id: userId },
    };

    const clinic = find(this.props.clinics, { id: clinicId });
    this.setState({ accessableClinics: [...this.state.accessableClinics, clinic] }, () => {
      apiFetch(`/clinics/${clinicId}/users`, options)
        .catch((e) => {
          this.setState({
            accessableClinics: reject(this.state.accessableClinics, { id: clinicId }),
          });
        });
    });
  }
  handleRemoveClinic = (clinicId) => {
    const { userId } = this.props;
    const options = { method: 'DELETE' };
    const oldAccessableClinics = this.state.accessableClinics;
    this.setState({ accessableClinics: reject(this.state.accessableClinics, { id: clinicId }) }, () => {
      apiFetch(`/clinics/${clinicId}/users/${userId}`, options)
        .catch((e) => {
          this.setState({
            accessableClinics: oldAccessableClinics,
          });
        });
    });
  }
  render() {
    const { classes, isFetching, user } = this.props;
    const { accessableClinics } = this.state;

    let noUserMessage;
    if (!user && isFetching) {
      noUserMessage = <h1> Fetching </h1>;
    }

    if (!user && !isFetching) {
      noUserMessage = <h1> Not Found </h1>;
    }

    const options = reject(this.props.clinics, c => find(accessableClinics, { id: c.id }));
    const roles = get(user, 'roles', []);
    return (
      <div className={classes.container}>
        <Header />
        <div className={classes.mainBody}>
          {noUserMessage ||
            <div>
              <h1 className={classes.headerStyle}>{user.name}</h1>
              <User
                disabledFields={['role']}
                customers={this.props.customers}
                user={user}
                handleSubmit={this.handleEditUser}
                showClose={false}
                submitText="Update User"
                classes={{ container: classes.userContainer }}
              />
            </div>
          }
        </div>
        {(roles.includes('PROVIDER') || roles.includes('PRO_PROVIDER')) && (
          <React.Fragment>
            <h2>Clinic Access</h2>
            <AutoCompleteSelect
              onSelectItem={(item, { option }) => this.handleAddUserClinic(option.id)}
              items={this.state.accessableClinics}
              options={options}
            />
            <List dense={true}>
              {this.state.accessableClinics.map(i => (
                <ListItem key={i.id}>
                  <ListItemText
                    primary={i.name}
                  />
                  <ListItemSecondaryAction onClick={() => this.handleRemoveClinic(i.id)}>
                    <IconButton aria-label="Delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )}
      </div>
    );
  }
}

// Currently this relies on having all users/users/clinics prefetched
// Next iteration will do reads for these if they don't exist
function mapStateToProps(state, ownProps) {
  const { users, customers, clinics } = state;
  const userId = get(ownProps, 'location.state.userId');
  const user = users.data[userId];
  const formattedClinics = map(clinics.data || [], c => Object.assign({ key: c.id, value: c.id }, c))
  return {
    customers: map(customers.data || [], c => c),
    clinics: formattedClinics,
    user,
    userId,
    isFetching: users.isFetching,
  };
}

const actionCreators = {
  browseClinicsIfNecessary,
  browseCustomers,
  editUser,
  readUser,
};

SingleUser.defaultProps = {
  customers: [],
  isFetching: true,
  user: null,
};

SingleUser.propTypes = {
  browseClinicsIfNecessary: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinics: PropTypes.array.isRequired,
  customers: PropTypes.array,
  isFetching: PropTypes.bool,
  editUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  userId: PropTypes.string.isRequired,
  readUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(SingleUser));
