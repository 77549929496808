import { promiseHandler } from 'cooldux';
import { isEmpty, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'conditions');
const { editEnd, editHandler } = promiseHandler('edit', 'conditions');
const { addEnd, addError, addHandler } = promiseHandler('add', 'conditions');
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'conditions');

export function browseConditions() {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/conditions');
    return browseHandler(promise, dispatch);
  };
}

export function editCondition(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isEmpty),
    };
    const promise = apiFetch(`/conditions/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addCondition(newCondition) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newCondition, isEmpty),
    };
    const promise = apiFetch('/conditions', options);
    return addHandler(promise, dispatch);
  };
}

export function deleteCondition(conditionId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/conditions/${conditionId}`, options)
      .then(() => conditionId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, conditions) {
  const data = {};
  conditions.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, condition) {
  const data = { ...state.data, [condition.id]: condition };
  return { ...state, error: null, data };
}

function finishAdd(state, condition) {
  const data = { ...state.data, [condition.id]: condition };
  return { ...state, error: null, data };
}

function finishDelete(state, conditionId) {
  return { ...state, data: omit(state.data, conditionId) };
}

function conditions(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    default:
      return state;
  }
}

export default conditions;
