import React, { Component } from 'react';
import { capitalize, get } from 'lodash';
import Paper from 'material-ui/Paper';
import { TextField, Divider } from 'material-ui';
import { colors } from '../lib/styles';
import ProviderSelection from '../components/provider-selection';

import { apiFetch } from '../lib/fetch';

import State from '../components/state';
import SubmitButton from '../components/submit-button';
const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: '20px',
    padding: '20px',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  submitArea: {
    margin: '30px',
    textAlign: 'center',
    width: '100%',
  },
  submitButton: {
    margin: '0 auto',
  },
  menuItem: {
    color: colors.primaryColor,
  },
  highlightDark: {
    color: colors.highlightDark,
  },
  physicianDetails: {
    fontSize: '10px',
    color: colors.primaryColor,
    textAlign: 'left',
    marginLeft: '15px',
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  text: {
    color: colors.primaryColor,
  },
  underline: {
    display: 'none',
  },
  whiteBackgroundSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
};

const initialState = {
  selection: null,
  errors: {},
  physician: {
    first_name: '',
    last_name: '',
    city: '',
    state: 'none',
  },
  physicianSearchError: '',
};

class NpiSearch extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleUpdateFirstName = this.handleText.bind(this, 'first_name');
    this.handleUpdateLastName = this.handleText.bind(this, 'last_name');
    this.handleUpdateCity = this.handleText.bind(this, 'city');
    this.handleUpdateState = this.handleText.bind(this, 'state');
  }
  handleText(field, e, text, value) {
    this.setState({
      errors: {
        ...this.state.errors,
        form: '',
        [field]: '',
      },
      physician: {
        ...this.state.physician,
        [field]: value || text,
      },
    });
  }
  handleMoveOn = () => {
    this.props.onSelectProvider({});
  }

  handleSelectProvider = (provider = {}) => {
    const prefix = get(provider, 'basic.name_prefix', '');
    const first = get(provider, 'basic.first_name', '');
    const last = get(provider, 'basic.last_name', '');
    const name = `${capitalize(prefix)} ${capitalize(first)} ${capitalize(last)}`;

    const address = get(provider, 'addresses.0.address_1', '');
    const city = capitalize(get(provider, 'addresses.0.city', ''));
    const state = capitalize(get(provider, 'addresses.0.state', ''));

    const zip_code = get(provider, 'addresses.0.postal_code', '');
    const fax_number = get(provider, 'addresses.0.fax_number', '');
    const phone_number = get(provider, 'addresses.0.telephone_number', '');

    const npi_number = provider.number;
    const selectedProvider = {
      name,
      npi_number,
      phone_number,
      fax_number,
      address,
      city,
      zip_code,
      state,
    };
    this.props.onSelectProvider(selectedProvider);
  }
  handleStartOver = () => {
    this.setState(initialState);
  }
  handleSearch = () => {
    const { first_name, last_name, city, state } = this.state.physician;
    const errors = {};
    if (!city) {
      errors.city = 'City is required!';
    }

    if (!last_name) {
      errors.last_name = 'Last Name is required';
    }

    if (Object.keys(errors).length) {
      return this.setState({ errors });
    }

    const params = {
      first_name,
      last_name,
      city,
      state: state === 'none' ? null : state,
    };

    this.setState({ searching: true });
    apiFetch('/npi-search', { params })
      .then(({ Errors, results }) => {
        if (Errors && Errors.length) {
          throw new Error('Bad NPI Search');
        }
        this.setState({
          searching: false,
          selection: results,
        });
      })
      .catch(() => {
        this.setState({
          selection: null,
          searching: false,
          physicianSearchError: 'An Error Occured while searching. Please try again.',
        });
      });
  }
  render() {
    const { errors, searching, selection, physician, physicianSearchError } = this.state;

    if (physicianSearchError || (selection && !selection.length)) {
      return (
        <Paper style={styles.container}>
          <section style={{ margin: '20px', height: '250px' }}>
            {physicianSearchError || 'No Physicians found.'}
          </section>
          <section style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SubmitButton onSubmit={this.handleMoveOn} text="Manual Entry" btnStyle={{ margin: '10px' }} />
            <SubmitButton onSubmit={this.handleStartOver} text="Start Over" btnStyle={{ margin: '10px' }} />
          </section>
        </Paper>
      );
    }
    if (selection) {
      return (
        <Paper style={styles.container}>
          <ProviderSelection
            providers={selection}
            onStartOver={this.handleStartOver}
            onSelectProvider={this.handleSelectProvider}
            onGiveUp={this.handleGiveUp}
          />
          <section style={{ display: 'flex', justifyContent: 'space-between'  }}>
            <SubmitButton onSubmit={this.handleMoveOn} text="Manual Entry" btnStyle={{ margin: '10px' }} />
            <SubmitButton onSubmit={this.handleStartOver} text="Start Over" btnStyle={{ margin: '10px' }} />
          </section>
        </Paper>
      );
    }
    return (
      <Paper style={styles.container}>
        <section>
          <p
            style={styles.physicianDetails}
          >Physician Search
          </p>
        </section>
        <section className="form" style={styles.whiteBackgroundSection}>
          <TextField
            hintText="Physician First Name"
            floatingLabelText="Physician First Name"
            floatingLabelShrinkStyle={styles.text}
            floatingLabelFocusStyle={styles.highlightDark}
            underlineStyle={styles.underline}
            hintStyle={styles.highlightDark}
            inputStyle={styles.text}
            onChange={this.handleUpdateFirstName}
            style={styles.marginLeft}
            value={physician.first_name}
            errorText={errors.first_name}
          />
          <Divider />
          <TextField
            hintText="Physician Last Name *"
            floatingLabelText="Physician Last Name *"
            floatingLabelShrinkStyle={styles.text}
            floatingLabelFocusStyle={styles.highlightDark}
            underlineStyle={styles.underline}
            hintStyle={styles.highlightDark}
            inputStyle={styles.text}
            onChange={this.handleUpdateLastName}
            style={styles.marginLeft}
            value={physician.last_name}
            errorText={errors.last_name}
          />
          <Divider />
          <section style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              hintText="Los Angeles *"
              floatingLabelText="Practice City *"
              floatingLabelShrinkStyle={styles.text}
              floatingLabelFocusStyle={styles.highlightDark}
              underlineStyle={styles.underline}
              hintStyle={styles.highlightDark}
              inputStyle={styles.text}
              onChange={this.handleUpdateCity}
              style={styles.marginLeft}
              value={physician.city}
              errorText={errors.city}
            />
            <State
              onChange={this.handleUpdateState}
              errorText={errors.state}
              text="Practice State"
              value={physician.state}
            />
          </section>
          <Divider />
        </section>
        <div style={styles.submitArea}>
          <SubmitButton disabled={searching} onSubmit={this.handleSearch} text="Search For Providers" btnStyle={styles.submitButton} />
          {this.state.errors.form}
        </div>
      </Paper>
    );
  }
}

export default NpiSearch;
