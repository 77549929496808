import queryString from 'query-string';
import { isUndefined, omitBy, startsWith } from 'lodash';
import config from '../config';

export default async function handledFetch(path, options) {
  try {
    const res = await window.fetch(path, options);

    if (res.status >= 400) {
      const contentType = res.headers.get('content-type');

      const content = startsWith(contentType, 'application/json') ? await res.json() : await res.text();

      let err;

      switch (res.status) {
        case 400:
          err = new Error(content);
          break;
        case 401:
          err = new Error('Unauthorized');
          break;
        default:
          err = new Error('Bad response from server');
      }

      err.content = content;
      err.status = res.status;

      throw err;
    }

    return res;
  } catch (err) {
    throw err;
  }
}

export function apiFetch(path, options = {}) {
  let qs = '';
  const isFormData = (options.body instanceof FormData);
  if (typeof options.body === 'object' && !(isFormData)) {
    options.body = JSON.stringify(options.body);
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    }
  }

  if (options.params) {
    const params = omitBy(options.params, isUndefined);
    qs = `?${queryString.stringify(params)}`;
  }
  Object.assign(options, { credentials: 'include' });

  return handledFetch(`${config.API_URL}${path}${qs}`, options)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      return true;
    });
}
