import { promiseHandler } from 'cooldux';
import { isNil, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { readBodyPainStart, readBodyPainEnd, readBodyPainError, readBodyPainHandler } = promiseHandler('readBodyPain', 'reports');
const { readClinicalHeadacheStart, readClinicalHeadacheEnd, readClinicalHeadacheError, readClinicalHeadacheHandler } = promiseHandler('readClinicalHeadache', 'reports');
const { readClinicalHeadacheMedicationsStart, readClinicalHeadacheMedicationsEnd, readClinicalHeadacheMedicationsError, readClinicalHeadacheMedicationsHandler } = promiseHandler('readClinicalHeadacheMedications', 'reports');
const { readActivityStart, readActivityEnd, readActivityError, readActivityHandler } = promiseHandler('readActivity', 'reports');
const { readDemographicStart, readDemographicEnd, readDemographicError, readDemographicHandler } = promiseHandler('readDemographic', 'reports');

export function readClinicalHeadacheReport(filter) {
  return function dispatcher(dispatch) {
    const opts = {
      params: omitBy(filter, isNil),
    };

    const promise = apiFetch('/reports/clinical_headache', opts);
    return readClinicalHeadacheHandler(promise, dispatch);
  };
}

export function readBodyPainReport(filter) {
  return function dispatcher(dispatch) {
    const opts = {
      params: omitBy(filter, isNil),
    };

    const promise = apiFetch('/reports/body_pain', opts);
    return readBodyPainHandler(promise, dispatch);
  };
}


export function readClinicalHeadacheMedicationsReport(filter) {
  return function dispatcher(dispatch) {
    const opts = {
      params: omitBy(filter, isNil),
    };

    const promise = apiFetch('/reports/clinical_headache_medications', opts);
    return readClinicalHeadacheMedicationsHandler(promise, dispatch);
  };
}

export function readActivityReport(filter) {
  return function dispatcher(dispatch) {
    const opts = {
      params: omitBy(filter, isNil),
    };

    const promise = apiFetch('/reports/activity', opts);
    return readActivityHandler(promise, dispatch);
  };
}

export function readDemographicReport(filter) {
  return function dispatcher(dispatch) {
    const opts = {
      params: omitBy(filter, isNil),
    };

    const promise = apiFetch('/reports/demographic', opts);
    return readDemographicHandler(promise, dispatch);
  };
}

const initialState = {
  activityError: null,
  activityFetching: false,
  activityData: [],
  bodyPainError: null,
  bodyPainFetching: false,
  bodyPainData: {},
  clinicalHeadacheError: null,
  clinicalHeadacheFetching: false,
  clinicalHeadacheData: {},
  clinicalHeadacheMedicationsError: null,
  clinicalHeadacheMedicationsFetching: false,
  clinicalHeadacheMedicationsData: [],
  demographicError: null,
  demographicFetching: false,
  demographicData: [],
};

function finishRead(state, type, reports) {
  return { ...state, [`${type}Data`]: reports, [`${type}Fetching`]: false, [`${type}Error`]: null };
}

function errorRead(state, type, error) {
  return { ...state, [`${type}Fetching`]: false, [`${type}Error`]: error };
}

function startRead(state, type) {
  return { ...state, [`${type}Fetching`]: true, [`${type}Error`]: null };
}


function reports(state = initialState, action) {
  switch (action.type) {
    case readClinicalHeadacheStart.type:
      return startRead(state, 'clinicalHeadache');
    case readClinicalHeadacheEnd.type:
      return finishRead(state, 'clinicalHeadache', action.payload);
    case readClinicalHeadacheError.type:
      return errorRead(state, 'clinicalHeadache', action.payload);

    case readBodyPainStart.type:
      return startRead(state, 'bodyPain');
    case readBodyPainEnd.type:
      return finishRead(state, 'bodyPain', action.payload);
    case readBodyPainError.type:
      return errorRead(state, 'bodyPain', action.payload);

    case readClinicalHeadacheMedicationsStart.type:
      return startRead(state, 'clinicalHeadacheMedications');
    case readClinicalHeadacheMedicationsEnd.type:
      return finishRead(state, 'clinicalHeadacheMedications', action.payload);
    case readClinicalHeadacheMedicationsError.type:
      return errorRead(state, 'clinicalHeadacheMedications', action.payload);

    case readActivityStart.type:
      return startRead(state, 'activity');
    case readActivityEnd.type:
      return finishRead(state, 'activity', action.payload);
    case readActivityError.type:
      return errorRead(state, 'activity', action.payload);

    case readDemographicStart.type:
      return startRead(state, 'demographic');
    case readDemographicEnd.type:
      return finishRead(state, 'demographic', action.payload);
    case readDemographicError.type:
      return errorRead(state, 'demographic', action.payload);

    default:
      return state;
  }
}

export default reports;
