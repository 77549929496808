import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import DatePicker from 'material-ui/DatePicker';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import Header from '../components/header';
import Table from '../components/table';
import { browseLogs } from '../state/logs';

const baseStyles = {
  mainBody: {
    height: 'calc(100% - 80px)',
    maxHeight: '100%',
  },
  searchBtn: {
    margin: '10px',
  },
  searchFilter: {
    display: 'flex',
    padding: '25px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
  accessLogsTypeSelect: {
    paddingRight: 10,
  },
};

const allUsersCols = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'user_id', headerName: 'User ID', width: 300 },
  { field: 'accessed_user_id', headerName: 'Accessed User ID', width: 300 },
  { field: 'http_method', headerName: 'HTTP Method', width: 150 },
  { field: 'route', headerName: 'API Route', width: 300 },
  { field: 'ip_address', headerName: 'IP Address', width: 150 },
  { field: 'status_code', headerName: 'Status Code', width: 150 },
];

const providersOnlyCols = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'user_id', headerName: 'User ID', width: 300 },
  { field: 'accessed_user_id', headerName: 'Accessed User ID', width: 300 },
  { field: 'user_clinic_id', headerName: 'Clinic ID', width: 120 },
  { field: 'user_clinic_name', headerName: 'Clinic Name', width: 300 },
  { field: 'http_method', headerName: 'HTTP Method', width: 150 },
  { field: 'route', headerName: 'API Route', width: 300 },
  { field: 'ip_address', headerName: 'IP Address', width: 150 },
  { field: 'status_code', headerName: 'Status Code', width: 150 },
  {
    field: 'created_at',
    headerName: 'Timestamp',
    valueFormatter: (params) => {
      if (params && params.value) return params.value.replace('T', ' ');
      return '';
    },
    width: 200,
  },
];

export class AccessLogs extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      search_start_date: null,
      search_end_date: now,
      now,
      rowsPerPage: 100,
      page: 0,
      accessLogsType: 'all',
    };

    this.search = this.search.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }
  componentWillMount() {
    this.props.browseLogs();
  }
  handleChangeStart(e, date) {
    this.setState({
      search_start_date: date,
    }, this.search);
  }
  handleChangeEnd(e, date) {
    this.setState({
      search_end_date: date,
    }, this.search);
  }
  handleChangePage = (evt, page) => this.setState({ page }, this.search)
  handleChangeRowsPerPage = evt => this.setState({ rowsPerPage: evt.target.value }, this.search)
  search() {
    const { search_start_date, search_end_date, accessLogsType } = this.state;

    const queryVals = {
      search_start_date: search_start_date ? moment(search_start_date).startOf('day').toISOString() : undefined,
      search_end_date: search_end_date ? moment(search_end_date).endOf('day').toISOString() : undefined,
      limit: this.state.rowsPerPage,
      offset: (this.state.page * this.state.rowsPerPage),
    };

    if (accessLogsType === 'providersOnly') {
      queryVals.providers_only = true;
    }

    if (accessLogsType === 'loginAttemptsOnly') {
      queryVals.auth_routes_only = true;
    }

    this.props.browseLogs(queryVals);
  }

  handleChangeAccessLogsType = (e) => {
    const accessLogsType = e.target.value;

    this.setState({ accessLogsType }, this.search);
  }

  downloadAccessLogs = () => {
    const { logs } = this.props;
    let headers = [];
    const rows = [];
    const now = new Date();

    providersOnlyCols.forEach((column) => {
      headers.push(column.headerName);
    });

    headers = headers.join(',');

    logs.forEach((log) => {
      const row = [];

      providersOnlyCols.forEach((column) => {
        let val = log[column.field] || '';
        if (column.field === 'created_at' && val) {
          val = val.replace('T', ' ');
        }
        row.push(val);
      });

      rows.push(row.join(','));
    });

    const report = [headers, ...rows].join('\n');

    let month = now.getMonth() + 1;
    if (month < 10) month = '0' + month;
    const dateStr = `${now.getFullYear()}-${month}_${now.getHours()}:${now.getMinutes()}`;

    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
    downloadTag.setAttribute('download', `provider_access_logs_${dateStr}.csv`);
    downloadTag.style.display = 'none';

    document.body.appendChild(downloadTag);

    downloadTag.click();

    document.body.removeChild(downloadTag);
  }

  render() {
    const { logs, isFetching } = this.props;
    const { accessLogsType } = this.state;

    const cols = accessLogsType === 'providersOnly' ? providersOnlyCols : allUsersCols;

    return (
      <>
        <Header />
        <Box padding={1.25} style={baseStyles.mainBody}>
          <div style={baseStyles.searchFilter}>
            <div style={baseStyles.accessLogsTypeSelect}>
              <Select
                value={accessLogsType}
                onChange={this.handleChangeAccessLogsType}
              >
                <MenuItem value="all">All Users</MenuItem>
                <MenuItem value="providersOnly">Providers Only</MenuItem>
                <MenuItem value="loginAttemptsOnly">Login Attempts Only</MenuItem>
              </Select>
            </div>
            <DatePicker
              autoOk={true}
              hintText="Start Date"
              container="inline"
              mode="landscape"
              maxDate={this.state.search_end_date}
              onChange={this.handleChangeStart}
            />
            <DatePicker
              autoOk={true}
              hintText="End Date"
              container="inline"
              defaultDate={this.state.now}
              mode="landscape"
              minDate={this.state.search_start_date}
              maxDate={this.state.now}
              onChange={this.handleChangeEnd}
            />
            <Button
              disabled={!this.state.search_start_date}
              onClick={this.search}
              style={baseStyles.searchBtn}
              variant="contained"
            >Search Logs
            </Button>
            {accessLogsType === 'providersOnly' ? (
              <Button
                onClick={this.downloadAccessLogs}
                startIcon={<SaveAltIcon />}
                disabled={isFetching}
              >
                Download Access Logs
              </Button>
            ) : null}
          </div>
          <div style={{ marginTop: 10, height: '85%' }}>
            <Table cols={cols} rows={logs} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { isFetching, searchResults, data } = state.logs;
  return {
    isFetching,
    logs: searchResults.map(id => data[id]),
  };
}

const actionCreators = {
  browseLogs,
};

AccessLogs.defaultProps = {
  logs: {},
};

AccessLogs.propTypes = {
  browseLogs: PropTypes.func.isRequired,
  logs: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, actionCreators)(AccessLogs);
