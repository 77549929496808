import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardText } from 'material-ui';

const baseStyles = {
  container: {
    minWidth: '300px',
    margin: '10px',
  },
  li: {
    padding: '0 5',
  },
  ul: {
    listStyle: 'none',
    padding: 0,
  },
};

class ListOf extends Component {
  render() {
    return (
      <Card style={baseStyles.container}>
        <CardTitle
          title={this.props.title}
        />
        <CardText>
          <ul style={baseStyles.ul}>
            {this.props.items.map((i, idx) => (<li key={idx} style={baseStyles.li}>{i}</li>))}
          </ul>
        </CardText>
      </Card>
    );
  }
}

ListOf.defaultProps = {
  title: '',
  items: [],
};

ListOf.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

export default ListOf;
