import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Instance from '../components/instance';

import Header from '../components/header';
import { editInstance, readInstance, browseTypes } from '../state/emr';

const baseStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  instanceStyle: {
    margin: '0px auto 25px',
    width: '760px',
  },
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
};

export class SingleInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    const { instanceId, typeId } = this.props;
    this.props.readInstance(typeId, instanceId);
    this.props.browseTypes();
  }

  render() {
    const { emr, editInstance } = this.props;
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          <Instance
            instance={emr.instanceRead}
            handleSubmit={editInstance}
            submitText="Update Instance"
            typeData={emr.typeData}
            showClose={false}
            style={baseStyles.instanceStyle}
            router={this.props.router}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { emr, clinics } = state;
  const { typeId, instanceId } = ownProps.routeParams;
  return {
    emr, clinics, typeId, instanceId,
  };
}

SingleInstance.propTypes = {
  browseTypes: PropTypes.func.isRequired,
  editInstance: PropTypes.func.isRequired,
  emr: PropTypes.object.isRequired,
  instanceId: PropTypes.string.isRequired,
  readInstance: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  typeId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  editInstance, readInstance, browseTypes,
})(SingleInstance);
