import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Header from '../components/header';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  boldText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
  },
  deleteButton: {
    marginTop: 10,
  },
  deleteButtonDialog: {
    background: colors.errorRed,
    '&:hover': {
      background: colors.errorRed,
    },
  },
  errorText: {
    color: colors.errorRed,
  },
  pageContainer: {
    padding: 20,
  },
  pageSubheader: {
    color: colors.errorRed,
    fontSize: 20,
  },
  pageHeader: {
    marginBottom: 10,
  },
  searchButton: {
    marginLeft: 20,
  },
  textField: {
    width: 300,
  },
  userSearchContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 30,
    marginTop: 30,
  },
};

class DeleteUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      email: '',
      error: '',
      noUserFound: false,
      user: null,
      userDeleted: false,
    };
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
      error: '',
      userDeleted: false,
      noUserFound: false,
    });
  };

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };

  handleDeleteUser = () => {
    const { user } = this.state;
    const requestOptions = {
      method: 'DELETE',
    };

    apiFetch(`/users/${user.id}/delete_patient_data`, requestOptions)
      .then(() => {
        this.setState({
          dialogOpen: false,
          user: null,
          userDeleted: true,
        });
      })
      .catch(() => {
        this.setState({
          error: 'Failed to delete user',
        });
      });
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };

  handleSearchForUser = () => {
    const { email } = this.state;

    this.setState({
      noUserFound: false,
      user: null,
      userDeleted: false,
    });

    apiFetch('/users/details-for-user-delete', { params: { email } })
      .then((res) => {
        this.setState({ user: res[0] });
      }).catch((e) => {
        if (e.status === 404) {
          this.setState({ noUserFound: true });
        } else {
          this.setState({ error: e.content.message });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const {
      dialogOpen,
      email,
      error,
      noUserFound,
      user,
      userDeleted,
    } = this.state;

    return (
      <div className={classes.pageContainer}>
        <Dialog
          open={dialogOpen}
          onClose={this.handleCloseDialog}
        >
          <DialogTitle>Delete User?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting a user is an irreversible action. User data will be permanently deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={this.handleDeleteUser}
              variant="contained"
              className={classes.deleteButtonDialog}
            >
              Delete User
            </Button>
          </DialogActions>
        </Dialog>
        <Header />
        <h1 className={classes.pageHeader}>Delete User</h1>
        <div className={classes.pageSubheader}>Deleting a user is an irreversible action</div>
        <div className={classes.userSearchContainer}>
          <TextField
            error={this.state.error}
            helperText={this.state.searchError}
            label="Email"
            value={email}
            onChange={this.handleChangeEmail}
            className={classes.textField}
          />
          <Button
            onClick={this.handleSearchForUser}
            variant="contained"
            className={classes.searchButton}
          >
            Search for User
          </Button>
        </div>
        {userDeleted ? (
          <div className={classes.boldText}>User successfully deleted</div>
        ) : null}
        {error ? (
          <div className={classes.errorText}>{error}</div>
        ) : null}
        {noUserFound ? (
          <div className={classes.boldText}>User not found</div>
        ) : null}
        {user ? (
          <div>
            <div className={classes.boldText}>Found User:</div>
            <div>
              <p>{`Name: ${user.first_name} ${user.last_name}`}</p>
              <p>{`DOB: ${moment(user.birth_date).format('MM/DD/YYYY')}`}</p>
            </div>
            <Button
              onClick={this.handleOpenDialog}
              variant="contained"
              className={classes.deleteButton}
            >
              Delete User
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

DeleteUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteUser);
