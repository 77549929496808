import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import Condition from '../components/condition';
import {
  browseConditions, editCondition,
} from '../state/conditions';


const baseStyles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
  conditionStyle: {
    margin: '0px auto 25px',
    width: '650px',
  },
};

export class SingleCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditCondition = this.handleEditCondition.bind(this);
  }
  componentWillMount() {
    const { condition } = this.props;
    // Needs to be a read instead of browse
    // Currently this relies on having all users/conditions/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    if (!condition) {
      this.props.browseConditions();
    }
  }
  handleEditCondition(details) {
    return this.props.editCondition(details)
      .then(() => this.props.router.push('/conditions'))
      .catch(() => {
        // Handle Error
      });
  }
  render() {
    const { condition, isFetching } = this.props;
    let noConditionMessage;
    if (!condition && isFetching) {
      noConditionMessage = <h1> Fetching </h1>;
    }

    if (!condition && !isFetching) {
      noConditionMessage = <h1> Not Found </h1>;
    }
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          { noConditionMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{condition.name}</h1>
              <Condition
                condition={condition}
                handleSubmit={this.handleEditCondition}
                showClose={false}
                submitText="Update Condition"
                style={baseStyles.conditionStyle}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

// Currently this relies on having all users/conditions/clinics prefetched
// Next iteration will do reads for these if they don't exist
function mapStateToProps(state, ownProps) {
  const { conditions } = state;
  const condition = conditions.data[ownProps.routeParams.conditionId];

  return {
    condition,
    isFetching: conditions.isFetching,
  };
}

const actionCreators = {
  browseConditions,
  editCondition,
};

SingleCondition.defaultProps = {
  customers: [],
  isFetching: true,
  condition: null,
};

SingleCondition.propTypes = {
  browseConditions: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  editCondition: PropTypes.func.isRequired,
  condition: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(SingleCondition);
