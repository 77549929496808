import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, omit } from 'lodash';

import Paper from 'material-ui/Paper';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

const baseStyles = {
  btn: {
    margin: '5px',
  },
  buttons: {
    marginTop: '20px',
    minWidth: '40%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
};

class Symptom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.symptom,
      dataChanged: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
    this.onUpdateSymptomType = this.onUpdateField.bind(this, 'type');
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.symptom !== this.props.symptom) {
      this.setState({ ...nextProps.symptom });
    }
  }
  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      name: '',
      dataChanged: false,
      type: '',
      error: '',
    });
  }
  onUpdateField(fieldName, e, value, fieldValue) {
    if (fieldName === 'type') {
      value = fieldValue;
    }
    this.setState({
      [fieldName]: value,
      dataChanged: true,
    });
  }
  onSubmit() {
    const payload = omit(this.state, ['error', 'dataChanged']);
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          dataChanged: false,
          error: '',
          ...this.props.symptom,
        });
        this.props.handleCloseAdd();
      })
      .catch(() => {
        this.setState({
          error: 'An error has occured',
        });
      });
  }
  render() {
    const { style } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, style);

    return (
      <Paper style={containerStyle}>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Symptom Name"
            hintText="Weight Loss"
            onChange={this.onUpdateName}
            value={this.state.name}
          />
          <SelectField
            autoComplete="off"
            floatingLabelText="Symptom Type"
            value={this.state.type}
            onChange={this.onUpdateSymptomType}
          >
            <MenuItem value="GENERAL" primaryText="General" />
            <MenuItem value="EYES" primaryText="Eyes" />
            <MenuItem value="ALLERGIES" primaryText="Allergies" />
            <MenuItem value="HEART" primaryText="Heart" />
            <MenuItem value="MUSCLE_JOINTS" primaryText="Muslce/Joints" />
            <MenuItem value="BLOOD" primaryText="Blood" />
            <MenuItem value="LUNGS" primaryText="Lungs" />
            <MenuItem value="SKIN" primaryText="Skin" />
            <MenuItem value="STOMACH" primaryText="Stomach" />
            <MenuItem value="NEUROLOGIC" primaryText="Neurologic" />
            <MenuItem value="EAR_NOSE_THROAT" primaryText="Ear, Nose & Throat" />
            <MenuItem value="URINARY_GENITAL" primaryText="Urinary or Genital" />
            <MenuItem value="MENTAL_HEALTH" primaryText="Mental Health" />
          </SelectField>
        </div>
        <div style={baseStyles.fieldRow}>
          <div style={baseStyles.buttons}>
            { this.props.showClose ? (
              <Button
                onClick={this.onCancelAdd}
                style={baseStyles.btn}
                variant="contained"
              >Cancel
              </Button>
            ) : null}
            <Button
              disabled={!this.state.dataChanged}
              onClick={this.onSubmit}
              style={baseStyles.btn}
              variant="contained"
            >{this.props.submitText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

Symptom.defaultProps = {
  handleCloseAdd: noop,
  symptom: {
    customer_id: '',
    user_id: '',
    name: '',
    symptom_specialty: '',
  },
  showClose: true,
  style: {},
  submitText: 'Create Symptom',
};

Symptom.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  symptom: PropTypes.object,
  showClose: PropTypes.bool,
  style: PropTypes.object,
  submitText: PropTypes.string,
};

export default Symptom;
