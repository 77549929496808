import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, isEmpty } from 'lodash';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import User from '../components/user';
import Header from '../components/header';
import Table from '../components/table';
import { browseCustomers } from '../state/customers';
import { browseUsers, addUser } from '../state/users';
import { browseClinics } from '../state/clinics'
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    width: '100%',
  },
  topSection: {
    margin: '15px auto',
    width: '900px',
  },
  mainBody: {
    height: 'calc(100% - 150px)',
    maxHeight: '100%',
  },
  title: {
    fontWeight: 600,
    fontSize: '.75rem',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  emailLookUpContainer: {
    marginLeft: '4rem',
  },
  emailButton: {
    marginLeft: '.5rem',
    marginTop: '1rem',
  },
  lookUpError: {
    marginLeft: '1rem',
    marginTop: '1rem',
  },
};

const renderCell = (params) => {
  const style = {};
  if (params.row.lockedOut) style.color = colors.errorRed;
  return <span style={style}>{params.value}</span>;
};

const cols = [
  {
    field: 'id',
    headerName: 'ID',
    width: 300,
    renderCell,
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 150,
    renderCell,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
    renderCell,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 300,
    renderCell,
  },
];

export class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      searchText: '',
      searchError: [],
      btnDisable: true,
      error: false,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    const { customers } = this.props;
    this.props.browseClinics();
    this.props.browseUsers();
    if (!customers || !customers.length) {
      this.props.browseCustomers();
    }
  }
  startAdding() {
    this.setState({
      adding: true,
    });
  }
  handleAddUser(details) {
    return this.props.addUser(details);
  }
  handleCloseAdd() {
    this.setState({ adding: false });
  }
  buildUsers = (users) => {
    const now = Date.now();

    return map(users, (u) => {
      let lockedOut = false;

      if (u.locked_out_at && u.lockout_duration) {
        const proLockedOutAt = new Date(u.locked_out_at).getTime();
        const proLockoutDuration = Number(u.lockout_duration);
        if (proLockedOutAt + proLockoutDuration > now || u.lockout_duration === 'indefinite') {
          lockedOut = true;
        }
      }

      return {
        id: u.id,
        username: u.username,
        email: u.email,
        role: u.roles.sort().join(', ').replace(/_/g, ' '),
        lockedOut,
      };
    });
  }
  handleEmailSearchChange = (event) => {
    this.setState({ searchText: event.target.value });
    if (!this.state.searchText === '' || (this.state.searchText.includes('@') && this.state.searchText.length > 4)) {
      this.setState({ btnDisable: false })
    } else {
      this.setState({ btnDisable: true })
    }
  }
  searchUser = async () => {
    if (!this.state.searchText === '' || (this.state.searchText.includes('@') && this.state.searchText.length > 4)) {
      apiFetch('/users', { params: { email: this.state.searchText } }).then((res) => {
        if (isEmpty(res)) {
          this.setState({ searchError: 'USER NOT FOUND', error: true });
        } else {
          this.setState({ searchError: '' });
          this.props.router.push({ pathname: '/user', state: { userId: res[0].id, data: res[0] } });
        }
      }).catch((e) => {
        this.setState({ searchError: 'USER NOT FOUND', error: true });
      });
    }
  }
  render() {
    const { users, self, router } = this.props;

    let addNewSection = (
      <>
        <div style={styles.emailLookUpContainer}>
          <TextField
            error={this.state.error}
            helperText={this.state.searchError}
            id="outlined-basic"
            label="Email"
            onChange={this.handleEmailSearchChange}
          />
          <Button
            variant="contained"
            style={styles.emailButton}
            onClick={this.searchUser}
            disabled={this.state.btnDisable}
          >Search User
          </Button>
        </div>
        {
          self.daysUntilExpired && self.daysUntilExpired < 14 && (
            <div style={{ marginTop: '10px', color: colors.errorRed }}>
              Your password is going to expire in {self.daysUntilExpired} days
              <Button
                onClick={() => this.props.router.push('/password-reset')}
                variant="contained"
                style={{
              backgroundColor: colors.errorRed,
              color: colors.white,
              marginLeft: '10px',
            }}
              >
                Reset Now
              </Button>
            </div>
          )
        }
        <Button
          onClick={this.startAdding}
          variant="contained"
        >Add User
        </Button>
      </>
    );

    if (this.state.adding) {
      addNewSection = (<User
        customers={this.props.customers}
        handleSubmit={this.handleAddUser}
        handleCancel={this.handleCloseAdd}
        classes={{ container: styles.topSection }}
      />);
    }

    const rows = this.buildUsers(users.data);

    const handleClick = ({ email }) => {
      apiFetch('/users', { params: { email } }).then((res) => {
        router.push({ pathname: '/user', state: { userId: res[0].id, data: res[0] } });
      });
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={{ maxHeight: '100%', height: `calc(100% - ${this.state.adding ? 100 : 150}px)` }}>
          <Box style={styles.addBtn}>{addNewSection}</Box>
          <div style={{ marginTop: 10, height: `${this.state.adding ? 75 : 100}%` }}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    customers, users, clinics, patients, self,
  } = state;
  return {
    customers: map(customers.data || [], c => c),
    users,
    clinics: clinics.data,
    patients,
    self,
  };
}

const actionCreators = {
  addUser,
  browseCustomers,
  browseUsers,
  browseClinics,
};

Users.defaultProps = {
  customers: [],
  users: {},
};

Users.propTypes = {
  addUser: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  browseUsers: PropTypes.func.isRequired,
  customers: PropTypes.array,
  router: PropTypes.object.isRequired,
  users: PropTypes.object,
  browseClinics: PropTypes.func.isRequired,
  self: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(Users));
