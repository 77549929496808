import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../lib/styles';

const StyledTableRow = withStyles({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: colors.highlightGray,
    },
  },
  hover: {
    color: 'black',
  },
})(TableRow);

export default StyledTableRow;
