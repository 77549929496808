import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const baseStyles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  headerTitle: {
    textAlign: 'center',
  },
  link: {
    margin: '0 5px',
  },
};

class ClinicSubheader extends Component {
  render() {
    const { currentPage, clinic } = this.props;
    return (
      <div>
        <h1 style={baseStyles.headerTitle}>{clinic.name}</h1>
        <div style={baseStyles.container}>
          <div>
            {currentPage !== 'main' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}`}>Main</Link>) :
              (<div style={baseStyles.link}>Main</div>)
            }
          </div>
          <div>
            {currentPage !== 'releases' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/releases`}>Releases</Link>) :
              (<div style={baseStyles.link}>Releases</div>)
            }
          </div>
          <div>
            {currentPage !== 'pros' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/pros`}>Clinic PROs</Link>) :
              (<div style={baseStyles.link}>Clinic PROs</div>)
            }
          </div>
          <div>
            {currentPage !== 'surgeries' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/surgeries`}>Clinic Surgery Options</Link>) :
              (<div style={baseStyles.link}>Clinic Surgery Options</div>)
            }
          </div>
          <div>
            {currentPage !== 'features' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/features`}>Features</Link>) :
              (<div style={baseStyles.link}>Features</div>)
            }
          </div>
          <div>
            {currentPage !== 'filters' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/filters`}>Filters</Link>) :
              (<div style={baseStyles.link}>Filters</div>)
            }
          </div>
          <div>
            {currentPage !== 'flags' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/flags`}>Flags</Link>) :
              (<div style={baseStyles.link}>Flags</div>)
            }
          </div>
          <div>
            {currentPage !== 'billing' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/billing`}>Billing</Link>) :
              (<div style={baseStyles.link}>Billing</div>)
            }
          </div>
          <div>
            {currentPage !== 'invoicing' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/invoicing`}>Invoicing</Link>) :
              (<div style={baseStyles.link}>Invoicing</div>)
            }
          </div>
          <div>
            {currentPage !== 'deletePatients' ?
              (<Link style={baseStyles.link} to={`/clinics/${clinic.id}/deletePatients`}>Delete Patients</Link>) :
              (<div style={baseStyles.link}>Delete Patients</div>)
            }
          </div>
        </div>
      </div>
    );
  }
}

ClinicSubheader.propTypes = {
  currentPage: PropTypes.string.isRequired,
  clinic: PropTypes.object.isRequired,
};

export default ClinicSubheader;
