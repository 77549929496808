import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, omit } from 'lodash';

import Paper from 'material-ui/Paper';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

const baseStyles = {
  btn: {
    margin: '5px',
  },
  buttons: {
    marginTop: '20px',
    minWidth: '30%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
  stateField: {
    marginRight: '50px',
    width: '105px',
  },
  zipField: {
    width: '120px',
  },
};

class Clinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.clinic,
      dataChanged: false,
      error: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
    this.onUpdatePhone = this.onUpdateField.bind(this, 'phone');
    this.onUpdateFax = this.onUpdateField.bind(this, 'fax');
    this.onUpdateAddress = this.onUpdateField.bind(this, 'address');
    this.onUpdateAddress2 = this.onUpdateField.bind(this, 'address_2');
    this.onUpdateCity = this.onUpdateField.bind(this, 'city');
    this.onUpdateState = this.onUpdateField.bind(this, 'state');
    this.onUpdateZip = this.onUpdateField.bind(this, 'zip_code');
    this.onUpdateNpi = this.onUpdateField.bind(this, 'npi_number');
    this.onUpdateCustomerId = this.onUpdateField.bind(this, 'customer_id');
    this.onUpdateAppointmentRooms = this.onUpdateField.bind(this, 'appointment_rooms');
    this.onUpdateSourceId = this.onUpdateField.bind(this, 'source_id');
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.clinic !== this.props.clinic) {
      this.setState({ ...nextProps.clinic });
    }
  }
  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      ...this.props.clinic,
      error: '',
    });
  }
  onUpdateField(fieldName, e, value, fieldValue) {
    if (fieldName === 'customer_id') {
      value = fieldValue;
    }
    if (fieldName === 'source_id') {
      value = fieldValue;
    }
    this.setState({
      [fieldName]: value,
      dataChanged: true,
    });
  }
  onSubmit() {
    const payload = omit(this.state, ['error', 'dataChanged', 'imageCacheId', 'patients']);
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          ...this.props.clinic,
          error: '',
          dataChanged: false,
        });
        this.props.handleCloseAdd();
      })
      .catch(() => {
        this.setState({
          error: 'An error has occured',
        });
      });
  }
  render() {
    const { customers, style, source_id } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, style);
    const customerOptions = customers.map(c => <MenuItem key={c.id} value={c.id} primaryText={c.name} />);
    const sourceOptions = source_id.map(s => <MenuItem key={s.id} value={s.id} primaryText={s.name} />);
    return (
      <Paper style={containerStyle}>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Clinic Name"
            hintText="Bob's Urgent Care"
            onChange={this.onUpdateName}
            value={this.state.name}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Phone Number"
            hintText="480-555-1234"
            onChange={this.onUpdatePhone}
            value={this.state.phone}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Fax Number"
            hintText="480-555-1234"
            onChange={this.onUpdateFax}
            value={this.state.fax}
          />
        </div>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Street Address"
            hintText="430 E Broadway"
            onChange={this.onUpdateAddress}
            value={this.state.address}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Street Address 2"
            hintText="Suite 460"
            onChange={this.onUpdateAddress2}
            value={this.state.address_2}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="City"
            hintText="Mesa"
            onChange={this.onUpdateCity}
            value={this.state.city}
          />
        </div>
        <div style={baseStyles.fieldRow}>
          <div style={{ display: 'flex' }}>
            <TextField
              autoComplete="off"
              floatingLabelText="State"
              hintText="AZ"
              onChange={this.onUpdateState}
              value={this.state.state}
              style={baseStyles.stateField}
            />
            <TextField
              autoComplete="off"
              floatingLabelText="Postal Code"
              hintText="85281"
              onChange={this.onUpdateZip}
              value={this.state.zip_code}
              style={baseStyles.zipField}
            />
          </div>
          <SelectField
            floatingLabelText="Customer"
            value={this.state.customer_id}
            onChange={this.onUpdateCustomerId}
          >
            <MenuItem value={null} primaryText="" />
            {customerOptions}
          </SelectField>
          <TextField
            autoComplete="off"
            floatingLabelText="NPI Number"
            hintText="1023456789"
            onChange={this.onUpdateNpi}
            value={this.state.npi_number}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Appointment Rooms"
            hintText="2"
            onChange={this.onUpdateAppointmentRooms}
            value={this.state.appointment_rooms}
          />
        </div>
        <div>
          <SelectField
            floatingLabelText="Source ID"
            onChange={this.onUpdateSourceId}
            value={this.state.source_id}
          >
            {sourceOptions}
          </SelectField>
        </div>
        <div>
          <div style={baseStyles.buttons}>
            {this.props.showClose ? (<Button
              onClick={this.onCancelAdd}
              style={baseStyles.btn}
              variant="contained"
            >Cancel
            </Button>) : null}
            <Button
              disabled={!this.state.dataChanged}
              onClick={this.onSubmit}
              style={baseStyles.btn}
              variant="contained"
            >{this.props.submitText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

Clinic.defaultProps = {
  clinic: {
    id: '',
    name: '',
    phone: '',
    fax: '',
    address: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
    appointment_rooms: '',
    source_id: '',
  },
  customers: [],
  handleCloseAdd: noop,
  showClose: true,
  submitText: 'Create Clinic',
  style: {},
};

Clinic.propTypes = {
  clinic: PropTypes.object,
  customers: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  showClose: PropTypes.bool,
  submitText: PropTypes.string,
  style: PropTypes.object,
};

export default Clinic;
