import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { find, map, reject } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import compareAlphabetically from '../lib/compare-alphabetically';
import Checkbox from '../base/checkbox';
import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { browseClinicsIfNecessary } from '../state/clinics';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '125px',
  },
  checkbox: {
    top: '0px !important',
  },
  container: {
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
  },
  error: {
    color: colors.errorRed,
  },
  row: {
    cursor: 'pointer',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};


export class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pros: [],
      availablePros: [],
      availableProsLoading: false,
      clinicProsLoading: false,
      error: '',
    };
  }
  UNSAFE_componentWillMount() {
    const { clinicId } = this.props;
    this.props.browseClinicsIfNecessary();
    this.setState({ availableProsLoading: true, clinicProsLoading: true });

    apiFetch('/pro_types')
      .then((availablePros) => {
        this.setState({
          availableProsLoading: false,
          availablePros: availablePros.sort(compareAlphabetically),
        });
      })
      .catch((e) => {
        this.setState({
          availableProsLoading: false,
          error: 'An Error has occurred fetching available pros',
        });
      });
    apiFetch(`/clinics/${clinicId}/pros`)
      .then((pros) => {
        this.setState({
          pros,
          clinicProsLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          clinicProsLoading: false,
          error: 'An error has occurred fetching the clinic\'s pros',
        });
      });
  }
  handleTogglePro = (proType, clinicPro) => {
    const { clinicId } = this.props;
    this.setState({ error: '' });
    if (clinicPro) {
      return apiFetch(`/clinics/${clinicId}/pros/${proType}`, { method: 'DELETE' })
        .then(() => {
          this.setState((prevState) => {
            return {
              pros: reject(prevState.pros, { pro_type: proType }),
            };
          });
        })
        .catch(this.handleError);
    }
    const reqOpts = {
      method: 'POST',
      body: {
        pro_type: proType,
      },
    };
    return apiFetch(`/clinics/${clinicId}/pros`, reqOpts)
      .then((result) => {
        this.setState(prevState => ({
          pros: prevState.pros.concat(result),
        }));
      })
      .catch(this.handleError);
  }
  handleError = () => {
    this.setState({
      error: 'An error occurred updating the clinic\'s pros',
    });
  }
  render() {
    const { classes, clinic } = this.props;
    const {
      availablePros, availableProsLoading, pros, clinicProsLoading, error,
    } = this.state;
    const rows = map(availablePros, (pro_type) => {
      const clinicPro = find(pros, { pro_type });
      return (
        <ListItem
          key={pro_type}
          className={classes.listItem}
          onClick={() => this.handleTogglePro(pro_type, clinicPro)}
        >
          <Checkbox
            checked={Boolean(clinicPro)}
            classes={{ root: classes.checkbox }}
          />
          <ListItemText>{(pro_type.split('_').join(' '))}</ListItemText>
        </ListItem>
      );
    });


    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="pros" clinic={clinic} />
          {(availableProsLoading || clinicProsLoading) && (
            <CircularProgress size={100} thickness={7} style={{ margin: '50px' }} />
          )}
          {error && (
            <h2 className={classes.error}>{error}</h2>
          )}
          <List>
            {rows}
          </List>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics, releases } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId, // in case clinic isn't loaded we can still fetch releases
    clinicReleases: releases.data,
  };
}

Features.propTypes = {
  browseClinicsIfNecessary: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { browseClinicsIfNecessary })(withStyles(styles)(Features));
