import { browserHistory } from 'react-router';
import { makeActionCreator, promiseHandler } from 'cooldux';
import { apiFetch } from '../lib/fetch';

const { loginStart, loginEnd, loginError } = promiseHandler('login');
const logoutAC = makeActionCreator('user-LOGOUT');

function verifyRole(data) {
  if (data.roles.includes('NEEDS_MFA') && data.roles.length === 1) {
    return data;
  }
  if (data.roles.includes('ADMIN') || data.roles.includes('SUPER_ADMIN')) {
    return data;
  }

  throw new Error('Not an Admin User');
}

export function verifyUser() {
  return function dispatcher(dispatch) {
    dispatch(loginStart());

    const requestOptions = {
      credentials: 'include',
      method: 'GET',
    };

    return apiFetch('/users/me', requestOptions)
      .then(verifyRole)
      .then((json) => {
        dispatch(loginEnd(json));
        return json;
      })
      .catch((err) => {
        dispatch(loginError());
        throw err;
      });
  };
}

export function login(email, password) {
  return function dispatcher(dispatch) {
    dispatch(loginStart());
    const body = {
      email,
      password,
    };

    const requestOptions = {
      body,
      credentials: 'include',
      method: 'POST',
    };
    return apiFetch('/auth', requestOptions)
      .then(verifyRole)
      .then((json) => {
        dispatch(loginEnd(json));
        return json;
      })
      .catch((err) => {
        dispatch(loginError(err));
        throw err;
      });
  };
}

export function logout() {
  return function dispatcher(dispatch) {
    dispatch(logoutAC());

    return apiFetch('/logout')
      .then(() => browserHistory.push('/'));
  };
}

const initialState = {
  loggedIn: false,
  isFetching: false,
  clinics: [],
};

function user(state = initialState, action) {
  switch (action.type) {
    case loginStart.type:
      return { ...state, isFetching: true };
    case loginEnd.type:
      return { ...state, loggedIn: true, isFetching: false, ...action.payload };
    case loginError.type:
    case logoutAC.type:
      return { ...initialState };
    default:
      return state;
  }
}

export default user;
