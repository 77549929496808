import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import ReportSearchBar from '../components/report-search-bar';
import TableReport from '../components/table-report';
import { readClinicalHeadacheMedicationsReport } from '../state/reports';
import { browseClinics } from '../state/clinics';

const baseStyles = {
  container: {
    width: '100%',
  },
  statContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};

const preventiveColumns = [
  {
    display_name: 'Medication Name',
    name: 'medication',
  },
  {
    display_name: 'Total Users',
    name: 'total_uses', // Looks like a typo but it's not!
  },
];

const abortiveColumns = [
  {
    display_name: 'Medication Name',
    name: 'medication',
  },
  {
    display_name: 'Total Uses',
    name: 'total_uses',
  },
  {
    display_name: 'Successes',
    name: 'successful_uses',
  },
];

export class ClinicalHeadacheMedicationsReport extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      offset: 0,
      start_date: null,
      end_date: now,
      clinic_id: null,
      now,
    };

    this.search = this.search.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.onUpdateClinicId = this.onUpdateField.bind(this, 'clinic_id');
  }
  componentWillMount() {
    this.props.fetchData();
    this.props.browseClinics();
  }
  onUpdateField(fieldName, e, value, fieldValue) {
    this.setState({
      [fieldName]: fieldValue || value || null,
    });
  }
  handleChangeStart(e, date) {
    this.setState({
      start_date: date,
    });
  }
  handleChangeEnd(e, date) {
    this.setState({
      end_date: date,
    });
  }
  search() {
    const { clinic_id, start_date, end_date } = this.state;
    this.props.fetchData({
      clinic_id,
      start_date: moment(start_date).startOf('day').toISOString(),
      end_date: moment(end_date).endOf('day').toISOString(),
    });
  }
  render() {
    const { clinics, report } = this.props;

    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          <ReportSearchBar
            onChangeStart={this.handleChangeStart}
            onChangeEnd={this.handleChangeEnd}
            onUpdateClinicId={this.onUpdateClinicId}
            onSearch={this.search}
            clinics={clinics}
            clinic_id={this.state.clinic_id}
            end_date={this.state.end_date}
            start_date={this.state.start_date}
          />

          <div style={baseStyles.statContainer}>
            <TableReport
              title="Preventive Meds"
              columns={preventiveColumns}
              tableData={report.sorted_preventive}
              rank={true}
            />
            <TableReport
              title="Abortive Meds"
              columns={abortiveColumns}
              tableData={report.sorted_abortive}
              rank={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinics,
    reports: {
      clinicalHeadacheMedicationsData,
      isFetching,
    },
  } = state;
  return {
    clinics: clinics.data,
    report: clinicalHeadacheMedicationsData,
    isFetching,
  };
}

const actionCreators = {
  browseClinics,
  fetchData: readClinicalHeadacheMedicationsReport,
};

ClinicalHeadacheMedicationsReport.defaultProps = {
  clinics: {},
  report: {},
};

ClinicalHeadacheMedicationsReport.propTypes = {
  browseClinics: PropTypes.func.isRequired,
  clinics: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
  report: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(ClinicalHeadacheMedicationsReport);
