import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, omit } from 'lodash';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import VisibilityOn from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const styles = {
  btn: {
    margin: '5px',
  },
  buttons: {
    flex: 1,
    marginTop: '20px',
    minWidth: '30%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px auto',
    padding: '10px 20px',
  },
  errorText: {
    color: colors.errorRed,
    margin: '0 auto',
  },
  field: {
    flex: 1,
    margin: '0 5px',
    minWidth: '100px',
  },
  fieldRow: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorRed: {
    '& .MuiInputBase-input': {
      color: colors.errorRed,
    },
  },
};

const getUserLockedOut = (user) => {
  const { locked_out_at, lockout_duration } = user;
  const now = Date.now();
  let lockedOut = false;

  if (locked_out_at && lockout_duration) {
    const proLockedOutAt = new Date(locked_out_at).getTime();
    const proLockoutDuration = Number(lockout_duration);
    if (proLockedOutAt + proLockoutDuration > now || lockout_duration === 'indefinite') {
      lockedOut = true;
    }
  }

  return lockedOut;
};

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      dataChanged: false,
      ...this.props.user,
      userLockedOut: getUserLockedOut(this.props.user),
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateUsername = this.onUpdateField.bind(this, 'username');
    this.onUpdatePassword = this.onUpdateField.bind(this, 'password');
    this.onUpdateEmail = this.onUpdateField.bind(this, 'email');
    this.onUpdatePin = this.onUpdateField.bind(this, 'pin');
    this.onUpdateRole = this.onUpdateField.bind(this, 'roles');
    this.onUpdateCustomerId = this.onUpdateField.bind(this, 'customer_id');
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.setState({
        ...nextProps.user,
        userLockedOut: getUserLockedOut(nextProps.user),
      });
    }
  }
  onCancelAdd() {
    this.props.handleCancel();
    this.setState({
      username: '',
      password: '',
      email: '',
      pin: '',
      roles: [],
      dataChanged: false,
      showPass: false,
    });
  }
  onUpdateField(fieldName, e) {
    this.setState({
      [fieldName]: e.target.value,
      dataChanged: true,
    });
  }
  onSubmit() {
    const omittedFields = this.props.disabledFields.concat([
      'error',
      'dataChanged',
      'picture',
      'showPass',
      'locked_out_at',
      'lockout_duration',
    ]);
    const payload = omit(this.state, omittedFields);
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          password: '',
          pin: '',
          dataChanged: false,
          ...this.props.user,
        });
        this.props.handleCancel();
      })
      .catch((e) => {
        let errorMessage = get(e, 'content.message', 'An error has occured');
        if (e.status === 422) {
          errorMessage = 'Username or Email Already Taken';
        }
        this.setState({
          error: errorMessage,
        });
      });
  }
  render() {
    const { classes, customers } = this.props;
    const { userLockedOut } = this.state;
    const customerOptions = customers.map(customer => <MenuItem value={customer.id} key={customer.id}>{customer.name}</MenuItem>);

    const userIdentifierClass = userLockedOut ? `${classes.field} ${classes.errorRed}` : classes.field;

    return (
      <Paper className={classes.container}>
        <div className={classes.errorText}>{this.state.error}</div>
        <div className={classes.fieldRow}>
          <TextField
            autoComplete="off"
            className={userIdentifierClass}
            label="Username"
            onChange={this.onUpdateUsername}
            required
            value={this.state.username}
            style={{ color: colors.errorRed }}
          />
          <div className={classes.fieldRow}>
            <TextField
              autoComplete="new-password"
              className={classes.field}
              label="Password"
              type={this.state.showPass ? 'text' : 'password'}
              onChange={this.onUpdatePassword}
              required
              value={this.state.password}
            />
            <IconButton
              aria-label="Show/Hide Password"
              onClick={() => this.setState(prevState => ({ showPass: !prevState.showPass }))}
            >
              {this.state.showPass ? <VisibilityOff /> : <VisibilityOn />}
            </IconButton>
          </div>
          <TextField
            autoComplete="off"
            className={userIdentifierClass}
            label="Email"
            onChange={this.onUpdateEmail}
            required
            value={this.state.email}
          />
        </div>
        <div className={classes.fieldRow}>
          <TextField
            autoComplete="off"
            className={classes.field}
            label="Pin"
            onChange={this.onUpdatePin}
            required
            value={this.state.pin}
          />
          <FormControl>
            <InputLabel htmlFor="select-multiple-roles">Roles</InputLabel>
            <Select
              multiple
              className={classes.field}
              label="Roles"
              input={<Input id="select-multiple-roles" />}
              value={this.state.roles}
              onChange={this.onUpdateRole}
              renderValue={selected => (
                selected.map(value => (
                  <Chip key={value} label={value} />
                ))
              )}
            >
              <MenuItem value="ADMIN" key="ADMIN">ADMIN</MenuItem>
              <MenuItem value="PROVIDER" key="PROVIDER">PROVIDER</MenuItem>
              <MenuItem value="PRO_PROVIDER" key="PRO PROVIDER">PRO_PROVIDER</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="select-customer">Customer</InputLabel>
            <Select
              className={classes.field}
              label="Customer"
              input={<Input id="select-customer" />}
              value={this.state.customer_id || ' '}
              onChange={this.onUpdateCustomerId}
            >
              {customerOptions}
            </Select>
          </FormControl>
          <div className={classes.buttons}>
            {this.props.showClose ? (
              <Button
                onClick={this.onCancelAdd}
                variant="contained"
                className={classes.btn}
              >Cancel
              </Button>
            ) : null}
            <Button
              disabled={!this.state.dataChanged}
              label={this.props.submitText}
              onClick={this.onSubmit}
              variant="contained"
              className={classes.btn}
            >{this.props.submitText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

User.defaultProps = {
  disabledFields: [],
  customers: [],
  handleCancel: () => { },
  submitText: 'Create User',
  showClose: true,
  user: {
    username: '',
    password: '',
    email: '',
    terms_and_conditions: true,
    pin: '',
    roles: ['PROVIDER'],
    customer_id: '',
  },
};

User.propTypes = {
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array,
  disabledFields: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  showClose: PropTypes.bool,
  submitText: PropTypes.string,
  user: PropTypes.object,
};

export default withStyles(styles)(User);
