import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import Pqrs from '../components/pqrs';
import {
  browsePqrs, editPqrs,
} from '../state/pqrs';


const baseStyles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
  pqrsStyle: {
    margin: '0px auto 25px',
    width: '650px',
  },
};

export class SinglePqrs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditPqrs = this.handleEditPqrs.bind(this);
  }
  componentWillMount() {
    const { pqrs } = this.props;
    // Needs to be a read instead of browse
    // Currently this relies on having all users/pqrs/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    if (!pqrs) {
      this.props.browsePqrs();
    }
  }
  handleEditPqrs(details) {
    return this.props.editPqrs(details)
      .then(() => this.props.router.push('/pqrs'))
      .catch(() => {
        // Handle Error
      });
  }
  render() {
    const { pqrs, isFetching } = this.props;
    let noPqrsMessage;
    if (!pqrs && isFetching) {
      noPqrsMessage = <h1> Fetching </h1>;
    }

    if (!pqrs && !isFetching) {
      noPqrsMessage = <h1> Not Found </h1>;
    }
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          { noPqrsMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{pqrs.name}</h1>
              <Pqrs
                pqrs={pqrs}
                handleSubmit={this.handleEditPqrs}
                showClose={false}
                submitText="Update Pqrs"
                style={baseStyles.pqrsStyle}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const pqrs = state.pqrs.data[ownProps.routeParams.pqrsId];

  return {
    pqrs,
    isFetching: pqrs.isFetching,
  };
}

const actionCreators = {
  browsePqrs,
  editPqrs,
};

SinglePqrs.defaultProps = {
  customers: [],
  isFetching: true,
  pqrs: null,
};

SinglePqrs.propTypes = {
  browsePqrs: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  editPqrs: PropTypes.func.isRequired,
  pqrs: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(SinglePqrs);
