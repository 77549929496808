import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';

import Header from '../components/header';
import Provider from '../components/provider';
import { browseCustomers } from '../state/customers';
import {
  browseProviders, editProvider,
} from '../state/providers';


const baseStyles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
  providerStyle: {
    margin: '0px auto 25px',
    width: '650px',
  },
};

export class Clinics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditProvider = this.handleEditProvider.bind(this);
  }
  componentWillMount() {
    const { customers, provider } = this.props;
    // Needs to be a read instead of browse
    // Currently this relies on having all users/providers/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    if (!provider) {
      this.props.browseProviders();
    }

    if (!customers || !customers.length) {
      this.props.browseCustomers();
    }
  }
  handleEditProvider(details) {
    return this.props.editProvider(details)
      .then(() => {
        // Display Success Message
      })
      .catch(() => {
        // Handle Error
      });
  }
  render() {
    const { provider, isFetching } = this.props;
    let noProviderMessage;
    if (!provider && isFetching) {
      noProviderMessage = <h1> Fetching </h1>;
    }

    if (!provider && !isFetching) {
      noProviderMessage = <h1> Not Found </h1>;
    }
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          { noProviderMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{provider.name}</h1>
              <Provider
                customers={this.props.customers}
                provider={provider}
                handleSubmit={this.handleEditProvider}
                showClose={false}
                submitText="Update Provider"
                style={baseStyles.providerStyle}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

// Currently this relies on having all users/providers/clinics prefetched
// Next iteration will do reads for these if they don't exist
function mapStateToProps(state, ownProps) {
  const { customers, providers } = state;
  const provider = providers.data[ownProps.routeParams.providerId];

  return {
    customers: map(customers.data || [], c => c),
    provider,
    isFetching: providers.isFetching,
  };
}

const actionCreators = {
  browseCustomers,
  browseProviders,
  editProvider,
};

Clinics.defaultProps = {
  customers: [],
  isFetching: true,
  provider: null,
};

Clinics.propTypes = {
  browseCustomers: PropTypes.func.isRequired,
  browseProviders: PropTypes.func.isRequired,
  customers: PropTypes.array,
  isFetching: PropTypes.bool,
  editProvider: PropTypes.func.isRequired,
  provider: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(Clinics);
