import { promiseHandler } from 'cooldux';
import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'releases');
const { readStart, readEnd, readError, readHandler } = promiseHandler('read', 'releases');
const { editStart, editEnd, editHandler } = promiseHandler('edit', 'releases');
const { addEnd, addError, addHandler } = promiseHandler('add', 'releases');
const { removeReleaseEnd, removeReleaseHandler } = promiseHandler('removeRelease', 'releases');

export function browseReleases(clinicId) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinicId}/esign_templates`);
    return browseHandler(promise, dispatch);
  };
}

export function readRelease(clinicId, releaseId) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinicId}/esign_templates/${releaseId}`);
    return readHandler(promise, dispatch);
  };
}

export function editRelease(clinicId, releaseId, body) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'PUT',
      body,
    };
    const promise = apiFetch(`/clinics/${clinicId}/esign_templates/${releaseId}`, options);
    return editHandler(promise, dispatch);
  };
}

export function removeRelease(clinicId, releaseId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/clinics/${clinicId}/esign_templates/${releaseId}`, options)
      .then(() => ({ id: releaseId }));

    return removeReleaseHandler(promise, dispatch);
  };
}

export function addRelease(clinicId, newRelease) {
  return function dispatcher(dispatch) {
    const promise = window.fetch(newRelease.file)
      .then(res => res.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append('file', blob);
        formData.append('subject', newRelease.subject);
        formData.append('message', newRelease.message);
        formData.append('name', newRelease.name);

        const options = {
          method: 'POST',
          body: formData,
        };
        return apiFetch(`/clinics/${clinicId}/esign_templates`, options);
      });

    return addHandler(promise, dispatch);
  };
}


export function addReleaseFromAccount(clinicId, release) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: release,
    };
    const promise = apiFetch(`/clinics/${clinicId}/esign_template_from_account`, options);

    return addHandler(promise, dispatch);
  };
}

const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, releases) {
  const data = {};
  releases.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishRead(state, release) {
  const data = {
    ...state.data,
    [release.id]: release,
  };

  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, release) {
  const data = {
    ...state.data,
    [release.id]: release,
  };

  return { ...state, data, isEditing: false, error: null };
}

function finishAdd(state, release) {
  const data = { ...state.data, [release.id]: release };
  return { ...state, error: null, data };
}

function finishRemove(state, release) {
  const data = { ...state.data, [release.id]: undefined };

  return { ...state, error: null, data };
}


function release(state = initialState, action) {
  switch (action.type) {
    case removeReleaseEnd.type:
      return finishRemove(state, action.payload);
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case readStart.type:
      return { ...state, isFetching: true };
    case readEnd.type:
      return finishRead(state, action.payload);
    case readError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case editStart.type:
      return { ...state, isEditing: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editEnd.error:
      return { ...state, isEditing: false, error: action.payload };
    default:
      return state;
  }
}

export default release;
