import { promiseHandler } from 'cooldux';
import { omit, omitBy, isEmpty } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { addTypeEnd, addTypeError, addTypeHandler } = promiseHandler('addType', 'emr');
const { addIntegrationEnd, addIntegrationError, addIntegrationHandler } = promiseHandler('addIntegration', 'emr');
const { addInstanceEnd, addInstanceError, addInstanceHandler } = promiseHandler('addInstance', 'emr');

const {
  browseTypeStart, browseTypeEnd,
  browseTypeError, browseTypeHandler,
} = promiseHandler('browseType', 'emr');

const {
  browseInstanceStart, browseInstanceEnd,
  browseInstanceError, browseInstanceHandler,
} = promiseHandler('browseInstance', 'emr');

const { editInstanceEnd, editInstanceHandler } = promiseHandler('editInstance', 'emr');

const {
  readIntegrationsStart, readIntegrationsEnd,
  readIntegrationsError, readIntegrationsHandler,
} = promiseHandler('readIntegrations', 'emr');

const {
  readInstanceStart, readInstanceEnd,
  readInstanceError, readInstanceHandler,
} = promiseHandler('readInstance', 'emr');

const initialState = {
  error: null,
  isFetching: false,
  typeData: {},
  integrations: [],
  instanceRead: {},
  instanceData: {},
  instance: {},
  addResult: {},
};

export function addInstance(data) {
  const options = {
    method: 'POST',
    body: {
      redox_destination_id: data.redox_destination_id,
      name: data.name,
      version: data.version,
    },
  };

  const promise = apiFetch(`/emr_types/${data.typeId}/instances`, options);
  return dispatch => addInstanceHandler(promise, dispatch);
}

export function addIntegration(instanceId, clinicId) {
  const options = {
    method: 'POST',
    body: {
      emr_instance_id: instanceId,
    },
  };
  const promise = apiFetch(`/clinics/${clinicId}/emr_instances`, options);
  return dispatch => addIntegrationHandler(promise, dispatch);
}

export function addType(data) {
  const options = {
    method: 'POST',
    body: omit(data, isEmpty),
  };
  const promise = apiFetch('/emr_types', options);
  return dispatch => addTypeHandler(promise, dispatch);
}

export function browseInstances(type_id) {
  const promise = apiFetch(`/emr_types/${type_id}/instances`);
  return dispatch => browseInstanceHandler(promise, dispatch);
}

export function browseTypes() {
  const promise = apiFetch('/emr_types');
  return dispatch => browseTypeHandler(promise, dispatch);
}

export function editInstance(update, instance) {
  const body = omit(update, ['id', 'created_at', 'updated_at', 'clinic_name', 'clinic_id']);
  const options = {
    method: 'PUT',
    body: omitBy(body, isEmpty),
  };
  const promise = apiFetch(`/emr_types/${instance.typeId}/instances/${instance.instanceId}`, options);
  return dispatch => editInstanceHandler(promise, dispatch);
}

export function readIntegrations(clinicId) {
  const promise = apiFetch(`/clinics/${clinicId}/emr_instances`);
  return dispatch => readIntegrationsHandler(promise, dispatch);
}

export function readInstance(typeId, instanceId) {
  const promise = apiFetch(`/emr_types/${typeId}/instances/${instanceId}`);
  return dispatch => readInstanceHandler(promise, dispatch);
}

function finishTypeBrowse(state, emr) {
  const typeData = {};
  emr.forEach((u) => {
    typeData[u.id] = u;
  });
  return { ...state, typeData, isFetching: false, error: null };
}

function finishInstanceBrowse(state, emr) {
  const instanceData = {};
  emr.forEach((u) => {
    instanceData[u.id] = u;
  });
  return { ...state, instanceData, isFetching: false, error: null };
}

function finishTypeAdd(state, emr) {
  const typeData = { ...state.typeData, [emr.id]: emr };
  return { ...state, error: null, typeData };
}

function finishIntegrationAdd(state, integration) {
  const integrationData = { ...state.integrations, [integration.id]: state.instanceData[integration.emr_instance_id] };
  return { ...state, error: null, integrations: integrationData };
}

function finishInstanceAdd(state, emr) {
  const instanceData = { ...state.instanceData, [emr.id]: emr };
  return { ...state, error: null, instanceData };
}

function finishInstanceEdit(state, instance) {
  return { ...state, instanceRead: instance, error: null };
}

function emr(state = initialState, action) {
  switch (action.type) {
    case browseTypeStart.type:
      return { ...state, isFetching: true };
    case browseTypeEnd.type:
      return finishTypeBrowse(state, action.payload);
    case browseTypeError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addTypeEnd.type:
      return finishTypeAdd(state, action.payload);
    case addTypeError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addIntegrationEnd.type:
      return finishIntegrationAdd(state, action.payload);
    case addIntegrationError.type:
      return { ...state, isFetching: false, error: action.payload };
    case browseInstanceStart.type:
      return { ...state, isFetching: true };
    case browseInstanceEnd.type:
      return finishInstanceBrowse(state, action.payload);
    case browseInstanceError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addInstanceEnd.type:
      return finishInstanceAdd(state, action.payload);
    case addInstanceError.type:
      return { ...state, isFetching: false, error: action.payload };
    case readIntegrationsStart.type:
      return { ...state, isFetching: true };
    case readIntegrationsEnd.type:
      return { ...state, integrations: action.payload, isFetching: false, error: null };
    case readIntegrationsError.type:
      return { ...state, isFetching: false, error: action.payload };
    case readInstanceStart.type:
      return { ...state, isFetching: true };
    case readInstanceEnd.type:
      return { ...state, instanceRead: action.payload, isFetching: false, error: null };
    case readInstanceError.type:
      return { ...state, isFetching: false, error: action.payload };
    case editInstanceEnd.type:
      return finishInstanceEdit(state, action.payload);
    default:
      return state;
  }
}

export default emr;
