import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';

import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const tierPricing = {
  per_event: {
    tier_1: 0.50,
    tier_2: 0.35,
    tier_3: 0.10,
    tier_4: 1.00,
  },
  per_patient: {
    tier_1: 8.00,
    tier_2: 6.50,
    tier_3: 5.00,
    tier_4: 20.00,
  },
};

const tierPatientCount = {
  tier_1: {
    min: 0,
    max: 99,
  },
  tier_2: {
    min: 100,
    max: 999,
  },
  tier_3: {
    min: 1000,
    max: null,
  },
  tier_4: {
    min: null,
    max: null,
  },
};

const styles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '125px',
  },
  checkbox: {
    top: '0px !important',
  },
  container: {
    width: '100%',
  },
  dd: {
    display: 'inline',
    marginLeft: 0,
    whiteSpace: 'pre-wrap',
  },
  dl: {
    marginBottom: 0,
    marginTop: 5,
  },
  dt: {
    display: 'inline',
  },
  listItem: {
    cursor: 'pointer',
  },
  error: {
    color: colors.errorRed,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1000,
    minWidth: 400,
    width: 'max-content',
  },
  row: {
    cursor: 'pointer',
  },
  saveButton: {
    marginLeft: 10,
  },
  select: {
    minWidth: 240,
  },
  selectFormControl: {
    display: 'block',
    marginTop: 10,
  },
  tiersDescriptionHeader: {
    fontWeight: 'bold',
    marginTop: 10,
  },
};


class ClinicBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      billingType: 'per_patient',
      tier: 'tier_1',
      noBillingInfo: false,
      platform: 'informed_virtual_care',
      price: 8.00,
      fetchingBillingInfo: true,
    };

    this.handleChangePlatform = this.handleChange.bind(this, 'platform');
    this.handleChangePrice = this.handleChange.bind(this, 'price');

    apiFetch(`/clinics/${props.clinicId}/billing`)
      .then((data) => {
        this.setState({
          billingType: data.type,
          tier: data.tier,
          platform: data.platform,
          price: data.rate,
          fetchingBillingInfo: false,
        });
      })
      .catch((e) => {
        const stateUpdate = {
          fetchingBillingInfo: false,
        };
        if (e.status === 404) stateUpdate.noBillingInfo = true;

        this.setState(stateUpdate);
      });
  }

  handleChange = (index, e) => {
    this.setState({ [index]: e.target.value });
  }

  handleChangeBillingType = (e) => {
    const { tier } = this.state;

    this.setState({
      billingType: e.target.value,
      price: tierPricing[e.target.value][tier],
    });
  }

  handleChangeTier = (e) => {
    const { billingType } = this.state;

    this.setState({
      tier: e.target.value,
      price: tierPricing[billingType][e.target.value],
    });
  }

  handleSave = () => {
    const { clinicId } = this.props;
    const {
      billingType,
      tier,
      platform,
      price,
      noBillingInfo,
    } = this.state;

    const options = {
      body: {
        type: billingType,
        tier,
        rate: price,
        platform,
        min_patients: tierPatientCount[tier].min,
        max_patients: tierPatientCount[tier].max,
      },
    };

    if (noBillingInfo) {
      options.method = 'POST';
    } else {
      options.method = 'PUT';
    }

    apiFetch(`/clinics/${clinicId}/billing`, options)
      .then(() => {
        this.setState({ noBillingInfo: false });
      });
  }

  render() {
    const { classes, clinic } = this.props;
    const {
      billingType,
      noBillingInfo,
      tier,
      platform,
      price,
      fetchingBillingInfo,
    } = this.state;

    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="billing" clinic={clinic} />
          <div className={classes.pageContent}>
            {!fetchingBillingInfo && noBillingInfo ? (
              <div className={classes.error}>Billing has not been set up for this clinic</div>
            ) : null}
            {!fetchingBillingInfo ? (
              <>
                <RadioGroup row value={billingType} onChange={this.handleChangeBillingType}>
                  <FormControlLabel
                    value="per_patient"
                    control={<Radio color="primary" />}
                    label="Per Patient"
                    labelPlacement="start"
                    style={{ marginLeft: 0 }}
                  />
                  <FormControlLabel
                    value="per_event"
                    control={<Radio color="primary" />}
                    label="Per Event"
                    labelPlacement="start"
                  />
                </RadioGroup>
                <FormControl className={classes.selectFormControl}>
                  <InputLabel id="tier-label" shrink>Select Tier</InputLabel>
                  <Select
                    labelId="tier-label"
                    displayEmpty
                    value={tier}
                    onChange={this.handleChangeTier}
                    label="Select Tier"
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="tier_1">Tier 1</MenuItem>
                    <MenuItem value="tier_2">Tier 2</MenuItem>
                    <MenuItem value="tier_3">Tier 3</MenuItem>
                    <MenuItem value="tier_4">Tier 4</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.selectFormControl}>
                  <InputLabel id="platform-label" shrink>Platform</InputLabel>
                  <Select
                    labelId="platform-label"
                    displayEmpty
                    value={platform}
                    onChange={this.handleChangePlatform}
                    label="Platform"
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="clinic_operated">Clinic Operated</MenuItem>
                    <MenuItem value="informed_virtual_care">Informed Virtual Care</MenuItem>
                  </Select>
                </FormControl>
                <Box display="flex" alignItems="end">
                  <TextField
                    label="Price ($)"
                    value={price}
                    onChange={this.handleChangePrice}
                    type="number"
                  />
                  <Button
                    onClick={this.handleSave}
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
                <Box mt={3}>
                  <div className={classes.tiersDescriptionHeader}>Description of Tiers (Per Patient):</div>
                  {billingType === 'per_patient' ? (
                    <dl className={classes.dl}>
                      <div>
                        <dt className={classes.dt}>Tier 1: </dt>
                        <dd className={classes.dd}> 0 - 99 patients - $8.00 per patient</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 2: </dt>
                        <dd className={classes.dd}> 100 - 999 patients - $6.50 per patient</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 3: </dt>
                        <dd className={classes.dd}> 1000+ patients - $5.00 per patient</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 4: </dt>
                        <dd className={classes.dd}> List price, no commitments - $20.00 per patient</dd>
                      </div>
                    </dl>
                  ) : (
                    <dl className={classes.dl}>
                      <div>
                        <dt className={classes.dt}>Tier 1: </dt>
                        <dd className={classes.dd}> 0 - 99 patients - $0.50 per event</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 2: </dt>
                        <dd className={classes.dd}> 100 - 999 patients - $0.35 per event</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 3: </dt>
                        <dd className={classes.dd}> 1000+ patients - $0.10 per eventt</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 4: </dt>
                        <dd className={classes.dd}> List price, no commitments - $1.00 per event</dd>
                      </div>
                    </dl>
                  )}
                  <div className={classes.tiersDescriptionHeader}>Minimum billing, regardless of usage:</div>
                  {billingType === 'per_event' ? (
                    <dl className={classes.dl}>
                      <div>
                        <dt className={classes.dt}>Tier 1: </dt>
                        <dd className={classes.dd}> 20 patients, $160.00</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 2: </dt>
                        <dd className={classes.dd}> 100 patients, $650.00</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 3: </dt>
                        <dd className={classes.dd}> 1,000 patients, $5,000.00</dd>
                      </div>
                    </dl>
                  ) : (
                    <dl className={classes.dl}>
                      <div>
                        <dt className={classes.dt}>Tier 1: </dt>
                        <dd className={classes.dd}> 100 events, $50.00</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 2: </dt>
                        <dd className={classes.dd}> 5,000 events, $1,750.00</dd>
                      </div>
                      <div>
                        <dt className={classes.dt}>Tier 3: </dt>
                        <dd className={classes.dd}> 50,000 events, $5,000.00</dd>
                      </div>
                    </dl>
                  )}
                </Box>
              </>
            ) : <CircularProgress />}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId,
  };
}

ClinicBilling.propTypes = {
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(ClinicBilling));
