import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import { apiFetch } from '../lib/fetch';
import Header from '../components/header';

const styles = {
  pageContent: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  pageTitle: {
    marginBottom: 20,
    paddingTop: 10,
  },
  userClinicList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

const userClinicItemStyles = {
  clinicName: {
    margin: '10px 10px 5px 10px',
  },
  clinicList: {
    marginBottom: 10,
    marginRight: 10,
    marginTop: 5,
    paddingBottom: 5,
    paddingLeft: 28,
    paddingTop: 0,
  },
  userName: {
    margin: 10,
  },
  userClinicItem: {
    marginRight: 10,
  },
};

const getProcessedUserData = (data) => {
  const uniqueUserClinicData = {};

  data.forEach((userClinicData) => {
    if (uniqueUserClinicData[userClinicData.user_id]) {
      uniqueUserClinicData[userClinicData.user_id].clinics.push({
        id: userClinicData.clinic_id,
        name: userClinicData.clinic_name,
      });
    } else {
      uniqueUserClinicData[userClinicData.user_id] = {
        userId: userClinicData.user_id,
        userName: userClinicData.email,
        clinics: [{
          id: userClinicData.clinic_id,
          name: userClinicData.clinic_name,
        }],
      };
    }
  });

  return uniqueUserClinicData;
};

const UserClinicItem = props => (
  <Paper style={userClinicItemStyles.userClinicItem}>
    <div style={userClinicItemStyles.userName}>{props.userName}</div>
    <div style={userClinicItemStyles.clinicName}>Patient&apos;s Clinics:</div>
    <ul style={userClinicItemStyles.clinicList}>
      {props.clinics.map(clinic => (
        <li>{clinic.name}</li>
      ))}
    </ul>
  </Paper>
);
UserClinicItem.propTypes = {
  userName: PropTypes.string.isRequired,
  clinics: PropTypes.array.isRequired,
};

class UsersAssociatedWithNumber extends React.Component {
  constructor(props) {
    super(props);
    const { number } = this.props.location.query;

    this.state = {
      fetchingUsersData: true,
      userClinicData: {},
    };

    const requestOptions = {
      params: { number },
    };

    apiFetch('/users_associated_with_number', requestOptions)
      .then((data) => {
        this.setState({
          fetchingUsersData: false,
          userClinicData: getProcessedUserData(data),
        });
      })
      .catch(() => {
        this.setState({ fetchingUsersData: false });
      });
  }

  render() {
    const { number } = this.props.location.query;
    const { fetchingUsersData, userClinicData } = this.state;
    const userClinicDataKeys = Object.keys(userClinicData);

    return (
      <div>
        <Header />
        <div style={styles.pageContent}>
          <div style={styles.pageTitle}>{`Users associated with ${number}`}</div>
          {fetchingUsersData ? (
            <CircularProgress />
          ) : null}
          {!fetchingUsersData && userClinicDataKeys.length > 0 ? (
            <div style={styles.userClinicList}>
              {userClinicDataKeys.map(userId => (
                <UserClinicItem {...userClinicData[userId]} />
              ))}
            </div>
          ) : (
            <div>No data found</div>
          )}
        </div>
      </div>
    );
  }
}

UsersAssociatedWithNumber.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      number: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default UsersAssociatedWithNumber;
