import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, noop, omit } from 'lodash';

import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const baseStyles = {
  btn: {
    margin: '5px',
    backgroundColor: '#fff',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
  btnRow: {
    marginTop: '20px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
  selectField: {
    width: '166px',
  },
};

class Instance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.instance,
      dataChanged: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
    this.onUpdateRedoxId = this.onUpdateField.bind(this, 'redox_destination_id');
    this.onUpdateVersion = this.onUpdateField.bind(this, 'version');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.instance !== this.props.instance) {
      this.setState({ ...nextProps.instance });
    }
  }

  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      emr_types_id: '',
      id: '',
      name: '',
      redox_destination_id: '',
      version: '',
    });
  }

  onUpdateField(fieldName, e) {
    this.setState({
      ...this.state,
      [fieldName]: e.target.value,
      dataChanged: true,
    });
  }

  onSubmit() {
    const instance = {
      instanceId: this.props.instance.id,
      typeId: this.props.instance.emr_types_id,
    };
    const payload = omit(this.state, ['error', 'dataChanged']);
    this.props.handleSubmit(payload, instance)
      .then(() => {
        this.setState({
          dataChanged: false,
          ...this.props.instance,
        });
      });
  }
  onCheckActive() {
    this.setState({
      active: !this.state.active,
      dataChanged: true,
    });
  }


  render() {
    const { dataChanged } = this.state;
    const { typeData, submitText, router } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, this.props.style);

    const typesMenuItems = map(typeData, row => (
      <MenuItem key={row.id} value={row.id} >
        {row.name}
      </MenuItem>));
    return (
      <Paper style={containerStyle}>
        <div style={baseStyles.fieldRow}>
          <FormControl>
            <InputLabel id="select-label">
              Choose A Type
            </InputLabel>
            <Select
              labelId="select-label"
              style={baseStyles.selectField}
              value={String(this.state.emr_types_id)}
              disabled
            >
              {typesMenuItems}
            </Select>
          </FormControl>
          <TextField
            autoComplete="off"
            label="Name"
            placeholder="Instance Name"
            onChange={this.onUpdateName}
            value={this.state.name || ''}
          />
        </div>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            label="Version"
            placeholder="Version"
            onChange={this.onUpdateVersion}
            value={this.state.version || ''}
          />
          <TextField
            autoComplete="off"
            label="Destination"
            placeholder="123-456-789"
            onChange={this.onUpdateRedoxId}
            value={this.state.redox_destination_id || ''}
          />
        </div>

        <div style={baseStyles.btnRow}>
          <Button
            disabled={!dataChanged}
            label={submitText}
            onClick={this.onSubmit}
            style={baseStyles.btn}
            variant="contained"
          >
            {submitText}
          </Button>
          <Button
            onClick={() => router.push('/emr/instances')}
            style={baseStyles.btn}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Paper>
    );
  }
}

Instance.defaultProps = {
  handleCloseAdd: noop,
  instance: {
    emr_types_id: '',
    id: '',
    name: '',
    redox_destination_id: '',
    version: '',
  },
  style: {},
  submitText: 'Create Instance',
};

Instance.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  instance: PropTypes.object,
  router: PropTypes.object.isRequired,
  style: PropTypes.object,
  submitText: PropTypes.string,
  typeData: PropTypes.object.isRequired,
};

export default Instance;
