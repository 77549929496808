import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, omit, map } from 'lodash';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const baseStyles = {
  btn: {
    margin: '5px',
    backgroundColor: '#fff',
  },
  buttons: {
    marginTop: '20px',
    minWidth: '40%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
    marginBottom: '15px',
  },
};

class AddInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.emr,
      dataChanged: false,
      name: '',
      typeId: '',
      redox_destination_id: '',
      version: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
    this.onUpdateTypeId = this.onUpdateField.bind(this, 'typeId');
    this.onUpdateVersion = this.onUpdateField.bind(this, 'version');
    this.onUpdateRedoxDestinationId = this.onUpdateField.bind(this, 'redox_destination_id');
  }

  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      name: '',
      dataChanged: false,
      error: '',
    });
  }

  onUpdateField(fieldName, e) {
    this.setState({
      [fieldName]: e.target.value,
      dataChanged: true,
    });
  }

  onSubmit() {
    const payload = omit(this.state, ['error', 'dataChanged']);
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          dataChanged: false,
          error: '',
          ...this.props.emr,
        });
        this.props.handleCloseAdd();
      })
      .catch(() => {
        this.setState({
          error: 'An error has occurred',
        });
      });
  }
  render() {
    const { style, types } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, style);

    const typeMenuItems = map(types, row => (
      <MenuItem key={row.id} value={row.id}>
        {row.name}
      </MenuItem>));

    return (
      <Paper style={containerStyle}>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            label="Instance Name"
            placeholder="Give Instance a Name"
            onChange={this.onUpdateName}
            value={this.state.name}
          />
          <TextField
            select
            autoComplete="off"
            style={{ width: '166px' }}
            label="Choose a Type"
            value={this.state.typeId}
            onChange={this.onUpdateTypeId}
          >
            {typeMenuItems}
          </TextField>
        </div>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            label="Redox Destination Id"
            placeholder="Type Destination Id"
            onChange={this.onUpdateRedoxDestinationId}
            value={this.state.redox_destination_id}
          />
          <TextField
            autoComplete="off"
            label="Version"
            placeholder="Version Number"
            onChange={this.onUpdateVersion}
            value={this.state.version}
          />
        </div>
        <div style={baseStyles.fieldRow}>
          <div style={baseStyles.buttons}>
            { this.props.showClose ?
              <Button
                variant="contained"
                onClick={this.onCancelAdd}
                style={baseStyles.btn}
              >
                Cancel
              </Button>
            :
              null
            }
            <Button
              variant="contained"
              disabled={!this.state.dataChanged}
              onClick={this.onSubmit}
              style={baseStyles.btn}
            >
              {this.props.submitText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

AddInstance.defaultProps = {
  handleCloseAdd: noop,
  emr: {
    name: '',
  },
  showClose: true,
  style: {},
  submitText: 'Create Instance',
};

AddInstance.propTypes = {
  emr: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  showClose: PropTypes.bool,
  style: PropTypes.object,
  submitText: PropTypes.string,
  types: PropTypes.object.isRequired,
};

export default AddInstance;
