import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { map } from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Header from '../components/header';
import Table from '../components/table';
import { browseCustomers } from '../state/customers';
import { browseProviders, editProvider, addProvider, deleteProvider } from '../state/providers';

const baseStyles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  mainBody: {
    height: 'calc(100% - 120px)',
    maxHeight: '100%',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
};

export class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.handleAddProvider = this.handleAddProvider.bind(this);
  }
  componentWillMount() {
    const { customers } = this.props;
    this.props.browseProviders();
    if (!customers || !customers.length) {
      this.props.browseCustomers();
    }
  }
  handleAddProvider(details) {
    return this.props.addProvider(details);
  }
  render() {
    const { providers, router } = this.props;

    const rows = map(providers.data, row => row);

    const cols = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'name', headerName: 'Name', width: 300 },
      { field: 'provider_specialty', headerName: 'Specialty', width: 300 },
    ];

    const handleClick = ({ id }) => {
      router.push(`/providers/${id}`);
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={baseStyles.mainBody}>
          <Box style={baseStyles.addBtn}>
            <Button
              component={Link}
              label="Add Provider"
              to="/add-provider"
              variant="contained"
            >
              Add Provider
            </Button>
          </Box>
          <div style={baseStyles.dataGrid}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { customers, providers } = state;
  return {
    customers: map(customers.data || [], c => c),
    providers,
  };
}

const actionCreators = {
  addProvider,
  editProvider,
  browseCustomers,
  browseProviders,
  deleteProvider,
};

Providers.defaultProps = {
  customers: [],
  providers: {},
};

Providers.propTypes = {
  addProvider: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  browseProviders: PropTypes.func.isRequired,
  customers: PropTypes.array,
  providers: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Providers);
