import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Cropper from 'react-cropper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import 'cropperjs/dist/cropper.css';
import { DialogContent } from '@material-ui/core';

const baseStyles = {
  container: {},
};

class CropModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onCropImage = this.onCropImage.bind(this);
  }
  onCropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    const cropResult = this.cropper.getCroppedCanvas().toDataURL();
    this.props.handleUpload(cropResult);
    this.props.handleModalClose();
  }
  render() {
    return (

      <Dialog
        fullWidth={true}
        style={baseStyles.container}
        open={this.props.open}
        onClose={this.props.handleModalClose}
      >
        <DialogTitle>Crop Your Image</DialogTitle>
        <DialogContent>
          <Cropper
            style={{ height: 400, width: '100%' }}
            aspectRatio={this.props.aspectRatio}
            guides={false}
            src={this.props.src}
            ref={(cropper) => { this.cropper = cropper; }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCropImage} variant="contained">Set new {this.props.imageTypeName} image</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
CropModal.defaultProps = {
  aspectRatio: 1,
  handleModalClose: noop,
  handleUpload: noop,
  imageTypeName: '',
  open: false,
  src: '',
};

CropModal.propTypes = {
  aspectRatio: PropTypes.number,
  handleModalClose: PropTypes.func,
  handleUpload: PropTypes.func,
  imageTypeName: PropTypes.string,
  open: PropTypes.bool,
  src: PropTypes.node,
};

export default CropModal;
