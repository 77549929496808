import { promiseHandler } from 'cooldux';
import { isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'customers');
const { editEnd, editHandler } = promiseHandler('edit', 'customers');
const { addEnd, addError, addHandler } = promiseHandler('add', 'customers');
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'customers');

export function browseCustomers() {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/customers');
    return browseHandler(promise, dispatch);
  };
}

export function editCustomer(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/customers/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addCustomer(newCustomer) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newCustomer, isUndefined),
    };
    const promise = apiFetch('/customers', options);
    return addHandler(promise, dispatch);
  };
}

export function deleteCustomer(customerId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/customers/${customerId}`, options)
      .then(() => customerId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, customers) {
  const data = {};
  customers.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, customer) {
  const data = { ...state.data, [customer.id]: customer };
  return { ...state, error: null, data };
}

function finishAdd(state, customer) {
  const data = { ...state.data, [customer.id]: customer };
  return { ...state, error: null, data };
}

function finishDelete(state, customerId) {
  return { ...state, data: omit(state.data, customerId) };
}

function customers(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    default:
      return state;
  }
}

export default customers;
