import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const progress = {
  height: '400px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function Loading(props) {
  return (
    <div style={{ ...progress, ...props.style }}>
      <CircularProgress size={150} />
    </div>
  );
}
