import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Card, TableHeader, TableHeaderColumn, TableBody, TableRowColumn, CardTitle, Table, TableRow } from 'material-ui';

const baseStyles = {
  container: {
    maxWidth: '600px',
    margin: '10px',
  },
  rankWidth: {
    width: '5px',
  },
};

class TableReport extends Component {
  render() {
    if (!this.props.columns || !this.props.tableData) {
      return (
        <Card style={baseStyles.container}>
          <CardTitle
            title={this.props.title}
          />
        </Card>
      );
    }

    const containerStyle = Object.assign({
      width: this.props.columns.length * 250,
    }, baseStyles.container);

    return (
      <Card style={containerStyle} >
        <CardTitle
          title={this.props.title}
        />
        <Table
          fixedHeader={true}
          selectable={false}
          multiSelectable={false}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow>
              {this.props.rank ?
                (<TableHeaderColumn style={baseStyles.rankWidth} tooltip="Rank">Rank</TableHeaderColumn>)
                : null}
              {map(this.props.columns, col => (
                <TableHeaderColumn key={col.display_name} tooltip={col.display_name}>{col.display_name}</TableHeaderColumn>),
              )}
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
            deselectOnClickaway={false}
            showRowHover={false}
            stripedRows={true}
          >
            {map(this.props.tableData, (row, index) => {
              return (
                <TableRow key={index}>
                  {this.props.rank ? <TableRowColumn style={baseStyles.rankWidth}>{index + 1}</TableRowColumn> : null}
                  {map(this.props.columns, (col, idx) => <TableRowColumn key={idx} >{row[col.name]}</TableRowColumn>)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    );
  }
}

TableReport.defaultProps = {
  columns: [],
  tableData: [],
  rank: false,
  title: 'A Report',
};

TableReport.propTypes = {
  tableData: PropTypes.array,
  columns: PropTypes.array,
  rank: PropTypes.bool,
  title: PropTypes.string,
};

export default TableReport;
