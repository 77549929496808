import { promiseHandler } from 'cooldux';
import { find, get, isEmpty, omit, omitBy, uniqueId } from 'lodash';
import queryString from 'query-string';

import { apiFetch } from '../lib/fetch';

const {
  browseStart, browseEnd, browseError, browseHandler,
} = promiseHandler('browse', 'clinics');
const { editEnd, editHandler } = promiseHandler('edit', 'clinics');
const { addEnd, addError, addHandler } = promiseHandler('add', 'clinics');
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'clinics');

const {
  readClinicStart, readClinicEnd, readClinicError, readClinicHandler
} = promiseHandler('readClinic', 'clinics');

const {
  browseImagesEnd, browseImagesHandler,
} = promiseHandler('browseImages', 'clinics');

const {
  browsePatientsEnd, browsePatientsHandler,
} = promiseHandler('browsePatients', 'clinics');

const { browseClinicProviderEnd, browseClinicProviderHandler } = promiseHandler('browseClinicProvider', 'clinics');
const {
  addClinicProviderEnd, addClinicProviderHandler,
} = promiseHandler('addClinicProvider', 'clinics');
const {
  deleteClinicProviderEnd, deleteClinicProviderHandler,
} = promiseHandler('deleteClinicProvider', 'clinics');

const {
  browseClinicUserEnd, browseClinicUserHandler,
} = promiseHandler('browseClinicUser', 'clinics');
const {
  addClinicUserEnd, addClinicUserHandler,
} = promiseHandler('addClinicUser', 'clinics');
const {
  deleteClinicUserEnd, deleteClinicUserHandler,
} = promiseHandler('deleteClinicUser', 'clinics');

const {
  addClinicImageEnd, addClinicImageHandler,
} = promiseHandler('addClinicImage', 'clinics');

/* global FormData */
export function browseClinics(queryObj) {
  return function dispatcher(dispatch) {
    const query = `?${queryString.stringify(queryObj)}`;
    const promise = apiFetch(`/clinics${queryObj ? query : ''}`);
    return browseHandler(promise, dispatch);
  };
}

export function editClinic(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, ['id', 'created_at', 'updated_at', 'customer_name']);
    const options = {
      method: 'PUT',
      body: omitBy(body, isEmpty),
    };
    const promise = apiFetch(`/clinics/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addClinic(newClinic) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newClinic, isEmpty),
    };
    const promise = apiFetch('/clinics', options);
    return addHandler(promise, dispatch);
  };
}

export function readClinic(clinic_id) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'GET',
    };
    const promise = apiFetch(`/clinics/${clinic_id}`, options).then((res) => res);
    return readClinicHandler(promise, dispatch);
  };
}

export function deleteClinic(clinic_id) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/clinics/${clinic_id}`, options)
      .then(() => clinic_id);

    return deleteHandler(promise, dispatch);
  };
}

export function browseClinicProviders(clinic_id) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/providers`)
      .then((providers) => {
        return { providers, clinic_id };
      });
    return browseClinicProviderHandler(promise, dispatch);
  };
}

export function deleteClinicProvider(clinic_id, providerId) {
  const options = {
    method: 'DELETE',
  };
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/providers/${providerId}`, options);
    return deleteClinicProviderHandler(promise, dispatch);
  };
}

export function addClinicProvider(clinic_id, provider_id) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: { provider_id },
    };
    const promise = apiFetch(`/clinics/${clinic_id}/providers`, options);
    return addClinicProviderHandler(promise, dispatch);
  };
}

export function browseClinicUsers(clinic_id) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/users`)
      .then((users) => {
        return { users, clinic_id };
      });
    return browseClinicUserHandler(promise, dispatch);
  };
}

export function browseClinicImages(clinic_id) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/images`)
      .then((images) => {
        return { images, clinic_id };
      });
    return browseImagesHandler(promise, dispatch);
  };
}

export function browseClinicPatients(clinic_id) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/patients_export`)
      .then((patients) => {
        return { patients, clinic_id };
      });
    return browsePatientsHandler(promise, dispatch);
  };
}

export function deleteClinicUser(clinic_id, userId) {
  const options = {
    method: 'DELETE',
  };
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}/users/${userId}`, options)
      .then((something) => {
        console.log('request went through');
        return something;
      });
    return deleteClinicUserHandler(promise, dispatch);
  };
}

export function addClinicUser(clinic_id, user_id) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: { user_id },
    };
    const promise = apiFetch(`/clinics/${clinic_id}/users`, options);
    return addClinicUserHandler(promise, dispatch);
  };
}

export function addClinicImage(clinic_id, img) {
  return function dispatcher(dispatch) {
    const promise = window.fetch(img)
      .then(res => res.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append('image', blob);
        const options = {
          method: 'POST',
          body: formData,
        };
        return apiFetch(`/clinics/${clinic_id}/images`, options);
      })
      .then(({ image }) => {
        return { clinic_id, image };
      });

    return addClinicImageHandler(promise, dispatch);
  };
}
export function browseClinicsIfNecessary() {
  return function dispatcher(dispatch, getState) {
    const { clinics } = getState();
    if (!Object.keys(clinics.data).length) {
      dispatch(browseClinics());
    }
  };
}

const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, clinics) {
  const data = {};
  clinics.forEach((u) => {
    data[u.id] = u;
  });
  return {
    ...state, data, isFetching: false, error: null,
  };
}

function finishEdit(state, clinic) {
  // Because clinics/providers are added clientside
  const updatedClinic = Object.assign({
    users: get(state, `data[${clinic.id}].users`, []),
    providers: get(state, `data[${clinic.id}].providers`, []),
    logo: get(state, `data[${clinic.id}].logo`, null),
  }, clinic);

  const data = { ...state.data, [clinic.id]: updatedClinic };
  return { ...state, error: null, data };
}

function finishAdd(state, clinic) {
  const data = { ...state.data, [clinic.id]: clinic };
  return { ...state, error: null, data };
}

function finishDelete(state, clinic_id) {
  return { ...state, data: omit(state.data, clinic_id) };
}

function finishAddProvider(state, { clinic_id, provider_id }) {
  const currentProviders = state.data[clinic_id].providers || [];
  const newClinicData = {
    ...state.data[clinic_id],
    providers: currentProviders.concat(provider_id),
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishBrowseProviders(state, { providers, clinic_id }) {
  const newClinicData = {
    ...state.data[clinic_id],
    providers: providers.map(p => p.id),
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishDeleteProvider(state, { provider_id, clinic_id }) {
  const currentProviders = state.data[clinic_id].providers || [];
  const newClinicData = {
    ...state.data[clinic_id],
    providers: currentProviders.filter(p => p.id !== provider_id),
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishAddUser(state, { clinic_id, user_id }) {
  const currentUsers = state.data[clinic_id].users || [];
  const newClinicData = {
    ...state.data[clinic_id],
    users: currentUsers.concat(user_id),
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishBrowseUsers(state, { clinic_id, users }) {
  const newClinicData = {
    ...state.data[clinic_id],
    users: users.map(u => u.id),
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishDeleteUser(state, { clinic_id, user_id }) {
  const currentUsers = state.data[clinic_id].users || [];
  const newClinicData = {
    ...state.data[clinic_id],
    providers: currentUsers.filter(u => u.id !== user_id),
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishBrowseImages(state, { clinic_id, images }) {
  const imgName = find(images, { image_type: 'LOGO' }).name;
  const newClinicData = {
    ...state.data[clinic_id],
    logo: imgName,
    imageCacheId: uniqueId('logo'),

  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishBrowsePatients(state, { clinic_id, patients }) {
  const newClinicData = {
    ...state.data[clinic_id],
    patients,
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function finishAddImage(state, { clinic_id, image }) {
  const newClinicData = {
    ...state.data[clinic_id],
    imageCacheId: uniqueId('logo'),
    logo: image,
  };

  const data = {
    ...state.data,
    [clinic_id]: newClinicData,
  };
  return { ...state, data };
}

function clinic(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    case browseClinicUserEnd.type:
      return finishBrowseUsers(state, action.payload);
    case addClinicUserEnd.type:
      return finishAddUser(state, action.payload);
    case readClinicStart.type:
      return { ...state, isFetching: true };
    case readClinicEnd.type:
      return finishBrowse(state, [action.payload]);
    case readClinicError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteClinicUserEnd.type:
      return finishDeleteUser(state, action.payload);
    case browseClinicProviderEnd.type:
      return finishBrowseProviders(state, action.payload);
    case addClinicProviderEnd.type:
      return finishAddProvider(state, action.payload);
    case deleteClinicProviderEnd.type:
      return finishDeleteProvider(state, action.payload);
    case browseImagesEnd.type:
      return finishBrowseImages(state, action.payload);
    case browsePatientsEnd.type:
      return finishBrowsePatients(state, action.payload);
    case addClinicImageEnd.type:
      return finishAddImage(state, action.payload);
    default:
      return state;
  }
}

export default clinic;
