import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReportSearchBar from '../components/report-search-bar';
import Header from '../components/header';
import KeyedListOf from '../components/keyed-list-of';
import ListOf from '../components/list-of';
import { readActivityReport } from '../state/reports';
import { browseClinics } from '../state/clinics';

const baseStyles = {
  container: {
    width: '100%',
  },
  statContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};

export class ActivityReport extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      offset: 0,
      start_date: null,
      end_date: now,
      clinic_id: null,
      now,
    };

    this.search = this.search.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.onUpdateClinicId = this.onUpdateField.bind(this, 'clinic_id');
  }
  componentWillMount() {
    this.props.fetchData();
    this.props.browseClinics();
  }
  onUpdateField(fieldName, e, value, fieldValue) {
    this.setState({
      [fieldName]: fieldValue || value || null,
    });
  }
  handleChangeStart(e, date) {
    this.setState({
      start_date: date,
    });
  }
  handleChangeEnd(e, date) {
    this.setState({
      end_date: date,
    });
  }
  search() {
    const { clinic_id, start_date, end_date } = this.state;
    this.props.fetchData({
      clinic_id,
      start_date: moment(start_date).startOf('day').toISOString(),
      end_date: moment(end_date).endOf('day').toISOString(),
    });
  }
  render() {
    const { clinics, report } = this.props;

    const statItems = {
      'Check Ins': report.checkins,
      Headaches: report.headache_count,
      'Pain Events': report.pain_count,
    };
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          <ReportSearchBar
            onChangeStart={this.handleChangeStart}
            onChangeEnd={this.handleChangeEnd}
            onUpdateClinicId={this.onUpdateClinicId}
            onSearch={this.search}
            clinics={clinics}
            clinic_id={this.state.clinic_id}
            end_date={this.state.end_date}
            start_date={this.state.start_date}
          />
          <div style={baseStyles.statContainer}>
            <KeyedListOf title="Primary Statistics" items={statItems} />
            <ListOf title="Physicians" items={report.patient_providers} />
            <ListOf title="Insurances" items={report.insurances} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    clinics,
    reports: {
      activityData,
      isFetching,
    },
  } = state;
  return {
    clinics: clinics.data,
    report: activityData,
    isFetching,
  };
}

const actionCreators = {
  browseClinics,
  fetchData: readActivityReport,
};

ActivityReport.defaultProps = {
  clinics: {},
  report: {},
};

ActivityReport.propTypes = {
  browseClinics: PropTypes.func.isRequired,
  clinics: PropTypes.object,
  fetchData: PropTypes.func.isRequired,
  report: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(ActivityReport);
