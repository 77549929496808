import { promiseHandler } from 'cooldux';
import { isEmpty, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'surgeries');
const { readStart, readEnd, readError, readHandler } = promiseHandler('read', 'surgeries');
const { editEnd, editHandler } = promiseHandler('edit', 'surgeries');
const { addEnd, addError, addHandler } = promiseHandler('add', 'surgeries');
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'surgeries');

export function browseSurgeries() {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/surgery');
    return browseHandler(promise, dispatch);
  };
}

export function readSurgery(surgeryId) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/surgery/${surgeryId}`);
    return readHandler(promise, dispatch);
  };
}

export function editSurgery(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isEmpty),
    };
    const promise = apiFetch(`/surgery/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addSurgery(newSurgery) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newSurgery, isEmpty),
    };
    const promise = apiFetch('/surgery', options);
    return addHandler(promise, dispatch);
  };
}

export function deleteSurgery(surgeryId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/surgery/${surgeryId}`, options)
      .then(() => surgeryId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, surgeries) {
  const data = {};
  surgeries.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishRead(state, surgery) {
  const data = {
    ...state.data,
    [surgery.id]: surgery,
  };

  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, surgery) {
  const data = { ...state.data, [surgery.id]: surgery };
  return { ...state, error: null, data };
}

function finishAdd(state, surgery) {
  const data = { ...state.data, [surgery.id]: surgery };
  return { ...state, error: null, data };
}

function finishDelete(state, surgeryId) {
  return { ...state, data: omit(state.data, surgeryId) };
}

function surgeries(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case readStart.type:
      return { ...state, isFetching: true };
    case readEnd.type:
      return finishRead(state, action.payload);
    case readError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    default:
      return state;
  }
}

export default surgeries;
