import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  Box,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import moment from 'moment';

import Header from '../components/header';
import Table from '../components/table';
import config from '../config';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const styles = {
  clinicName: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  dataGrid: {
    height: '100%',
    marginTop: 30,
  },
  dateText: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 24,
    justifyContent: 'space-around',
    minWidth: 130,
  },
  pageContentContainer: {
    padding: 10,
  },
  select: {
    marginRight: 20,
  },
};

const sharedColumns = [
  { field: 'name', headerName: 'Name', width: 300 },
  { field: 'numOfPatients', headerName: 'Total Patients', width: 150 },
  { field: 'prosRequested', headerName: 'Requested', width: 150 },
  { field: 'prosReturned', headerName: 'Returned', width: 150 },
  { field: 'prosFailed', headerName: 'Failed', width: 150 },
];

const clinicDataGridColumnsOne = [
  { field: 'date', headerName: 'Date', width: 150 },
  { field: 'time', headerName: 'Time', width: 150 },
  { field: 'result', headerName: 'Vital Results', width: 150 },
];

const clinicDataGridColumnsTwo = [
  { field: 'browser', headerName: 'Browser', width: 150 },
  { field: 'browserEngine', headerName: 'Browser Engine', width: 150 },
  { field: 'operatingSystem', headerName: 'Operating System', width: 150 },
  {
    field: 'device',
    headerName: 'Mobile Device',
    width: 150,
    // eslint-disable-next-line react/prop-types
    renderCell: ({ value }) => (
      <span title={value} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
    ),
  },
];

const buildCsvReport = (dataGridColumns, dataGridRows, reportTitle) => {
  let headers = [];
  const rows = [];

  dataGridColumns.forEach((column) => {
    headers.push(column.headerName);
  });

  headers = headers.join(',');

  dataGridRows.forEach((curRow) => {
    let row = [];

    dataGridColumns.forEach((column) => {
      const val = curRow[column.field] !== undefined ? curRow[column.field] : curRow[column.field] || 0;
      row.push(val);
    });

    row = row.join(',');
    rows.push(row);
  });

  return [reportTitle, headers, ...rows].join('\n');
};

class Ivc extends React.Component {
  constructor(props) {
    super(props);

    const now = moment();
    const curMonth = now.month();
    const curYear = now.year();

    const endDate = now.endOf('month').toDate();
    const startDate = now.startOf('month').toDate();

    this.state = {
      curMonth,
      curYear,
      startDate,
      endDate,
      curDataType: 'dataStatusCodes',
      data: null,
      aggregateDataGridColumns: [],
      aggregateDataGridRows: [],
      showAggregateData: true,
      clinicName: '',
      clinicId: null,
      clinicDataGridColumns: [],
      clinicDataGridRows: [],
    };

    this.fetchIvcAggregateData();
  }

  fetchIvcAggregateData = () => {
    const { curDataType, startDate, endDate } = this.state;

    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    window.fetch(`${config.API_URL}/ivc_aggregate_data?start_date=${startDateString}&end_date=${endDateString}`, {
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        const aggregateDataGridColumns = this.buildAggregateColumns(curDataType, jsonData);
        const aggregateDataGridRows = this.buildAggregateRows(curDataType, jsonData);

        this.setState({ data: jsonData, aggregateDataGridColumns, aggregateDataGridRows });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  fetchClinicData = () => {
    const { clinicId, startDate, endDate } = this.state;

    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    this.setState({
      clinicDataGridColumns: [],
      clinicDataGridRows: [],
    });

    window.fetch(
      `${config.API_URL}/clinics/${clinicId}/ivc_data?start_date=${startDateString}&end_date=${endDateString}`,
      { credentials: 'include' },
    )
      .then((response) => {
        return response.json();
      })
      .then((jsonData) => {
        const dataStatusColumns = jsonData.dataStatusCodeColumns.map((dataStatusCode) => {
          return {
            field: dataStatusCode,
            headerName: dataStatusCode,
            width: 150,
          };
        });

        const clinicDataGridColumns = [
          ...clinicDataGridColumnsOne,
          ...dataStatusColumns,
          ...clinicDataGridColumnsTwo,
        ];

        const clinicDataGridRows = jsonData.proData;

        clinicDataGridRows.forEach((row) => {
          const completionDate = moment(row.completionDate);
          const date = completionDate.format('MM/DD/YYYY');
          const time = completionDate.format('hh:mm A');

          row.date = date;
          row.time = time;
        });

        this.setState({ clinicDataGridColumns, clinicDataGridRows: jsonData.proData });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  buildAggregateColumns = (curDataType, data) => {
    const columns = [...sharedColumns];

    if (curDataType === 'dataStatusCodes') {
      Object.keys(data[0].dataStatusCodes).forEach((code) => {
        columns.push({
          field: code,
          headerName: code,
          width: 180,
        });
      });
    } else {
      Object.keys(data[0][curDataType]).forEach((dataTypeItem) => {
        columns.push({
          field: dataTypeItem,
          headerName: dataTypeItem,
          width: 180,
        });
      });
    }

    return columns;
  };

  buildAggregateRows = (curDataType, data) => {
    const rows = [];

    Object.values(data).forEach((curRow) => {
      const row = {
        id: curRow.id,
        name: curRow.name,
        numOfPatients: curRow.numOfPatients,
        prosRequested: curRow.prosRequested,
        prosReturned: curRow.prosReturned,
        prosFailed: curRow.prosFailed,
      };

      if (curDataType === 'dataStatusCodes') {
        Object.entries(curRow.dataStatusCodes).forEach(([code, occurrences]) => {
          row[code] = occurrences;
        });
      } else {
        Object.entries(curRow[curDataType]).forEach(([column, value]) => {
          row[column] = value;
        });
      }

      rows.push(row);
    });

    return rows;
  };

  handleChangeDataType = (e) => {
    const { data } = this.state;
    const newDataType = e.target.value;

    const aggregateDataGridColumns = this.buildAggregateColumns(newDataType, data);
    const aggregateDataGridRows = this.buildAggregateRows(newDataType, data);

    this.setState({ curDataType: newDataType, aggregateDataGridColumns, aggregateDataGridRows });
  }

  handleDownloadAggregateReport = () => {
    const {
      data,
      curMonth,
      curYear,
    } = this.state;

    const dataTypes = {
      dataStatusCodes: 'Status Codes',
      operatingSystems: 'Operating Systems',
      browsers: 'Browsers',
      browserEngines: 'Browser Engines',
      devices: 'Devices',
    };

    const reports = [];

    Object.entries(dataTypes).forEach(([dataType, reportTitle]) => {
      const aggregateDataGridColumns = this.buildAggregateColumns(dataType, data);
      const aggregateDataGridRows = this.buildAggregateRows(dataType, data);

      const report = buildCsvReport(aggregateDataGridColumns, aggregateDataGridRows, reportTitle);
      reports.push(report);
    });

    const report = reports.join('\n\n');
    let month = curMonth + 1;
    if (month < 10) month = '0' + month;
    const dateStr = `${curYear}-${month}`;

    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
    downloadTag.setAttribute('download', `ivc_report_${dateStr}.csv`);
    downloadTag.style.display = 'none';

    document.body.appendChild(downloadTag);

    downloadTag.click();

    document.body.removeChild(downloadTag);
  };

  handleDownloadClinicReport = () => {
    const {
      curMonth,
      curYear,
      clinicDataGridColumns,
      clinicDataGridRows,
      clinicName,
    } = this.state;

    const report = buildCsvReport(clinicDataGridColumns, clinicDataGridRows, clinicName);

    let month = curMonth + 1;
    if (month < 10) month = '0' + month;
    const dateStr = `${curYear}-${month}`;

    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
    downloadTag.setAttribute('download', `${clinicName}_ivc_report_${dateStr}.csv`);
    downloadTag.style.display = 'none';

    document.body.appendChild(downloadTag);

    downloadTag.click();

    document.body.removeChild(downloadTag);
  };

  handleClickPrevMonth = () => {
    const { curMonth, curYear, showAggregateData } = this.state;
    let prevMonth = curMonth - 1;
    let newCurYear = curYear;

    if (prevMonth < 0) {
      prevMonth = 11;
      newCurYear -= 1;
    }

    const curDate = moment({ year: newCurYear, month: prevMonth });
    const endDate = curDate.endOf('month').toDate();
    const startDate = curDate.startOf('month').toDate();

    const updatedState = {
      endDate,
      startDate,
      curMonth: prevMonth,
      curYear: newCurYear,
    };

    if (showAggregateData) {
      updatedState.data = null;
      updatedState.aggregateDataGridColumns = [];
      updatedState.aggregateDataGridRows = [];
    } else {
      updatedState.clinicDataGridColumns = [];
      updatedState.clinicDataGridRows = [];
    }

    this.setState(updatedState, () => {
      if (showAggregateData) {
        this.fetchIvcAggregateData();
      } else {
        this.fetchClinicData();
      }
    });
  }

  handleClickNextMonth = () => {
    const { curMonth, curYear, showAggregateData } = this.state;
    let nextMonth = curMonth + 1;
    let newCurYear = curYear;

    if (nextMonth > 11) {
      nextMonth = 0;
      newCurYear += 1;
    }

    const curDate = moment({ year: newCurYear, month: nextMonth });
    const endDate = curDate.endOf('month').toDate();
    const startDate = curDate.startOf('month').toDate();

    const updatedState = {
      endDate,
      startDate,
      curMonth: nextMonth,
      curYear: newCurYear,
    };

    if (showAggregateData) {
      updatedState.data = null;
      updatedState.aggregateDataGridColumns = [];
      updatedState.aggregateDataGridRows = [];
    } else {
      updatedState.clinicDataGridColumns = [];
      updatedState.clinicDataGridRows = [];
    }

    this.setState(updatedState, () => {
      if (showAggregateData) {
        this.fetchIvcAggregateData();
      } else {
        this.fetchClinicData();
      }
    });
  }

  render() {
    const { classes, router } = this.props;
    const {
      curDataType,
      curMonth,
      curYear,
      data,
      aggregateDataGridColumns,
      aggregateDataGridRows,
      showAggregateData,
      clinicDataGridColumns,
      clinicDataGridRows,
      clinicName,
    } = this.state;

    const dataGridColumns = showAggregateData ? aggregateDataGridColumns : clinicDataGridColumns;
    const dataGridRows = showAggregateData ? aggregateDataGridRows : clinicDataGridRows;
    const downloadReportFunc = showAggregateData ? this.handleDownloadAggregateReport : this.handleDownloadClinicReport;

    const handleClick = (row) => {
      this.setState({
        clinicId: row.id,
        clinicName: row.name,
        showAggregateData: false,
      }, () => {
        this.fetchClinicData();
      });
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={{ height: 'calc(100% - 150px)' }}>
          <Box display="flex" justifyContent="space-between">
            <div>
              <Box display="flex" alignItems="center">
                <ArrowBackIosIcon onClick={this.handleClickPrevMonth} />
                <div className={classes.dateText}>
                  {`${months[curMonth]} ${curYear}`}
                </div>
                <ArrowForwardIosIcon onClick={this.handleClickNextMonth} />
              </Box>
            </div>
            {data ? (
              <div>
                {showAggregateData ? (
                  <Select
                    value={curDataType}
                    onChange={this.handleChangeDataType}
                    className={classes.select}
                  >
                    <MenuItem value="dataStatusCodes">Status Codes</MenuItem>
                    <MenuItem value="operatingSystems">Operating Systems</MenuItem>
                    <MenuItem value="browsers">Browsers</MenuItem>
                    <MenuItem value="browserEngines">Browser Engines</MenuItem>
                    <MenuItem value="devices">Devices</MenuItem>
                  </Select>
                ) : (
                  <Button
                    onClick={() => this.setState({ showAggregateData: true })}
                    color="primary"
                  >
                    Back to All Clinics Data
                  </Button>
                )}
                <Button
                  onClick={downloadReportFunc}
                  startIcon={<SaveAltIcon />}
                >
                  Download Report
                </Button>
              </div>
            ) : null}
          </Box>
          {clinicName ? (
            <div className={classes.clinicName}>{clinicName}</div>
          ) : null}
          <div className={classes.dataGrid}>
            <Table cols={dataGridColumns} rows={dataGridRows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

Ivc.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ivc);
