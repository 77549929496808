import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import Paper from 'material-ui/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import Header from '../components/header';
import Customer from '../components/customer';

import {
  browseCustomers, editCustomer,
} from '../state/customers';

const formattedCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const baseStyles = {
  datePickerContainer: {
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  dateText: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 24,
    justifyContent: 'space-around',
    minWidth: 130,
  },
  errorText: {
    color: colors.errorRed,
    marginTop: 10,
  },
  exportButton: {
    backgroundColor: colors.primaryColor,
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    marginTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  headerStyle: {
    textAlign: 'center',
  },
  invoices: {
    padding: '10px 20px',
  },
  mainBody: {
    margin: '10px',
  },
  customerStyle: {
    margin: '0px auto 25px',
    width: '950px',
  },
};

const exportCsv = (report, month, year) => {
  const dateStr = moment({ month, year }).format('YYYY-MM');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
  downloadTag.setAttribute('download', `invoice_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

const getPerEventInvoice = (invoice) => {
  const { invoiceData, totals } = invoice;

  const aggTitle = 'Totals';
  const aggHeaders = 'Appointments,Pro Submissions,Vitals,Total Events,Cost';
  const aggRow = `${totals.appointments},${totals.proSubmissions},${totals.vitals},${totals.events},${formattedCurrency.format(totals.cost)}`; // eslint-disable-line max-len
  const aggReport = [aggTitle, aggHeaders, aggRow].join('\n');

  const appointmentsTitle = 'Appointments';
  const appointmentsHeaders = 'Patient,Recorded At';
  const appointmentsRows = [];
  invoiceData.appointments.forEach((appointment) => {
    appointmentsRows.push(`${appointment.cell_phone},${appointment.recorded_at}`);
  });
  const appointmentsReport = [appointmentsTitle, appointmentsHeaders, ...appointmentsRows].join('\n');

  const vitalsTitle = 'Vitals';
  const vitalsHeaders = 'Patient,Recorded At';
  const vitalsRows = [];
  invoiceData.vitals.forEach((vital) => {
    vitalsRows.push(`${vital.cell_phone},${vital.recorded_at}`);
  });
  const vitalsReport = [vitalsTitle, vitalsHeaders, ...vitalsRows].join('\n');

  const proTitle = 'Pro Submissions';
  const proHeaders = 'Type,Patient,Recorded At';
  const proRows = [];
  Object.entries(invoiceData.proSubmissions).forEach(([pro_type, proSubmissions]) => {
    proSubmissions.forEach((proSubmission) => {
      proRows.push(`${pro_type},${proSubmission.cell_phone},${proSubmission.recorded_at}`);
    });
  });
  const proReport = [proTitle, proHeaders, ...proRows].join('\n');

  const perEventInvoiceReport = [aggReport, appointmentsReport, vitalsReport, proReport].join('\n,\n');

  return perEventInvoiceReport;
};

const getPerPatientInvoice = (invoice) => {
  const { invoiceData, totals } = invoice;

  const aggTitle = 'Totals';
  const aggHeaders = 'Patients,Cost';
  const aggRow = `${totals.patients},${formattedCurrency.format(totals.cost)}`;
  const aggReport = [aggTitle, aggHeaders, aggRow].join('\n');

  const patientTitle = 'Patients';
  const patientHeaders = 'Patient,Appointments,Pro Submissions,Vitals';
  const patientRows = [];
  Object.entries(invoiceData).forEach(([patientId, patientData]) => {
    patientRows.push(`${patientId},${patientData.appointments},${patientData.proSubmissions},${patientData.vitals}`);
  });
  const patientReport = [patientTitle, patientHeaders, ...patientRows].join('\n');

  const perPatientInvoiceReport = [aggReport, patientReport].join('\n,\n');

  return perPatientInvoiceReport;
};

const exportInvoice = (invoices, month, year) => {
  let clinicInvoices = [];

  Object.entries(invoices).forEach(([clinicName, clinicInvoice]) => {
    let report;

    if (clinicInvoice) {
      if (clinicInvoice.type === 'perPatient') {
        report = getPerPatientInvoice(clinicInvoice);
      } else {
        report = getPerEventInvoice(clinicInvoice);
      }
    } else {
      report = 'No billing info for this clinic';
    }

    clinicInvoices.push(`${clinicName}\n${report}`);
  });

  clinicInvoices = clinicInvoices.join('\n,\n,\n');

  exportCsv(clinicInvoices, month, year);
};

export class Customers extends Component {
  constructor(props) {
    super(props);

    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();

    this.state = {
      editing: false,
      invoices: null,
      noInvoiceData: false,
      month,
      year,
    };
    this.handleEditCustomer = this.handleEditCustomer.bind(this);
  }
  componentWillMount() {
    const { customer } = this.props;

    this.getInvoices();

    // Needs to be a read instead of browse
    // Currently this relies on having all users/customers/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    //
    if (!customer) {
      return this.props.browseCustomers();
    }
  }

  getInvoices = () => {
    const { customerId } = this.props;
    const { month, year } = this.state;

    const options = {
      params: {
        month,
        year,
      },
    };

    this.setState({
      invoices: null,
      noInvoiceData: false,
    });

    apiFetch(`/customers/${customerId}/invoice`, options)
      .then((response) => {
        const noInvoiceData = Object.values(response).every(clinicInvoice => clinicInvoice === null);
        this.setState({
          invoices: response,
          noInvoiceData,
        });
      })
      .catch((e) => {
        if (e.status === 404) {
          this.setState({
            noInvoiceData: true,
          });
        }
      });
  }

  handleClickPrevMonth = () => {
    const { month, year } = this.state;
    let prevMonth = month - 1;
    let newyear = year;

    if (prevMonth < 0) {
      prevMonth = 11;
      newyear -= 1;
    }

    this.setState({
      month: prevMonth,
      year: newyear,
    }, () => { this.getInvoices(); });
  }

  handleClickNextMonth = () => {
    const { month, year } = this.state;
    let nextMonth = month + 1;
    let newyear = year;

    if (nextMonth > 11) {
      nextMonth = 0;
      newyear += 1;
    }

    this.setState({
      month: nextMonth,
      year: newyear,
    }, () => { this.getInvoices(); });
  }

  handleEditCustomer(details) {
    return this.props.editCustomer(details)
      .then(() => {
        // Display Success Message
      })
      .catch(() => {
        // Handle Error
      });
  }

  render() {
    const { customer, isFetching } = this.props;
    const {
      invoices,
      noInvoiceData,
      month,
      year,
    } = this.state;

    let noCustomerMessage;
    if (!customer && isFetching) {
      noCustomerMessage = <h1> Fetching </h1>;
    }

    if (!customer && !isFetching) {
      noCustomerMessage = <h1> Not Found </h1>;
    }
    return (
      <div>
        <Header />
        <div style={baseStyles.mainBody}>
          { noCustomerMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{customer.name}</h1>
              <Customer
                customer={customer}
                handleSubmit={this.handleEditCustomer}
                showClose={false}
                submitText="Update Customer"
                style={baseStyles.customerStyle}
              />
              <Paper style={baseStyles.invoices}>
                <div>Invoicing</div>
                <div style={baseStyles.datePickerContainer}>
                  <Box display="flex" alignItems="center">
                    <ArrowBackIosIcon onClick={this.handleClickPrevMonth} />
                    <div style={baseStyles.dateText}>
                      {`${months[month]} ${year}`}
                    </div>
                    <ArrowForwardIosIcon onClick={this.handleClickNextMonth} />
                  </Box>
                </div>
                {noInvoiceData ? (
                  <div style={baseStyles.errorText}>No invoices found for any clinic</div>
                ) : null}
                {invoices && !noInvoiceData ? (
                  <>
                    <div>Invoice data available</div>
                    <Button
                      style={baseStyles.exportButton}
                      onClick={() => exportInvoice(invoices, month, year)}
                      variant="contained"
                    >
                      Export Invoice
                    </Button>
                  </>
                ) : null}
              </Paper>
            </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { customers } = state;
  const customer = customers.data[ownProps.routeParams.customerId];

  return {
    customer,
    customerId: ownProps.routeParams.customerId,
    isFetching: customers.isFetching,
  };
}

const actionCreators = {
  browseCustomers,
  editCustomer,
};

Customers.defaultProps = {
  isFetching: true,
  customer: null,
};

Customers.propTypes = {
  browseCustomers: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  editCustomer: PropTypes.func.isRequired,
  customer: PropTypes.object,
  customerId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Customers);
