import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { omit, forEach, findIndex } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import Checkbox from '../base/checkbox';
import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { browseClinicsIfNecessary } from '../state/clinics';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '125px',
  },
  addFiltersButton: {
    marginBottom: 40,
    marginLeft: 20,
    marginTop: 5,
  },
  checkbox: {
    padding: 0,
  },
  checkboxWrapper: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    width: 210,
  },
  container: {
    width: '100%',
  },
  errorMessage: {
    color: colors.errorRed,
    marginBottom: 10,
    marginLeft: 22,
  },
  filterDetailsRow: {
    display: 'flex',
  },
  filterDetailsText: {
    verticalAlign: 'bottom',
  },
  listItem: {
    height: '60px',
    marginBottom: 10,
    alignItems: 'flex-end'
  },
  tableHeader: {
    textAlign: 'center',
  },
  textField: {
    marginLeft: 5,
    marginRight: 5,
    width: 50,
    '& input': {
      padding: 0,
    },
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
  dropdown: {
    border: '1px solid black',
    paddingLeft: '5px',
  },
  img: {
    marginLeft: '10px',
  },
  formControl: {
    marginLeft: '10px',
  }
};


export class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BP_HIGH: { checked: false, primary_trigger: '', secondary_trigger: '', flag: null },
      BP_LOW: { checked: false, primary_trigger: '', secondary_trigger: '', flag: null },
      SPO2_HIGH: { id: false, checked: false, primary_trigger: '', flag: null },
      SPO2_LOW: { id: false, checked: false, primary_trigger: '', flag: null },
      SWALLOWING: { id: false, checked: false, primary_trigger: '', flag: null },
      DIARRHEA: { id: false, checked: false, primary_trigger: 'true', secondary_trigger: '', flag: null },
      WEIGHT_GAIN: { id: false, checked: false, primary_trigger: '', flag: null },
      WEIGHT_LOSS: { id: false, checked: false, primary_trigger: '', flag: null },
      TEMPERATURE_HIGH: { id: false, checked: false, primary_trigger: '', flag: null },
      TEMPERATURE_LOW: { id: false, checked: false, primary_trigger: '', flag: null },
      WEAKNESS: { id: false, checked: false, primary_trigger: 'true', flag: null },
      FALLS: { id: false, checked: false, primary_trigger: 'true', flag: null },
      INCONTINENCE_BLADDER: { id: false, primary_trigger: 'true', secondary_trigger: 'true', checked: false, flag: null },
      INCONTINENCE_BOWEL: { id: false, checked: false, primary_trigger: 'true', secondary_trigger: 'true', flag: null },
      NAUSEA: { id: false, checked: false, primary_trigger: 'true', flag: null },
      VOMITING: { id: false, checked: false, primary_trigger: 'true', flag: null },
      BMI_HIGH: { id: false, checked: false, primary_trigger: '', flag: null },
      BMI_LOW: { id: false, checked: false, primary_trigger: '', flag: null },
      HR_HIGH: { id: false, checked: false, primary_trigger: '', flag: null },
      HR_LOW: { id: false, checked: false, primary_trigger: '', flag: null },
      BR_HIGH: { id: false, checked: false, primary_trigger: '', flag: null },
      BR_LOW: { id: false, checked: false, primary_trigger: '', flag: null },
      errors: {},
      snackbar: false,
      flags: [],
    };
  }

  async componentDidMount() {
    const flags = await apiFetch(`/clinics/${this.props.clinicId}/flags`);
    await apiFetch(`/clinics/${this.props.clinicId}/triggers`)
      .then((res) => {
        const state = { ...this.state };
        forEach(res, (trigger) => {
          // find index of flag from trigger flag_id
          // eslint-disable-next-line eqeqeq
          const flagIndex = findIndex(flags, (flag) => { return flag.id == trigger.flag_id; });
          // set trigger flag if it exists
          state[trigger.trigger_type].flag = flagIndex > -1 ? flags[flagIndex] : null;
          if (state[trigger.trigger_type].primary_trigger === '') state[trigger.trigger_type].primary_trigger = trigger.primary_trigger;
          if (state[trigger.trigger_type].secondary_trigger === '') state[trigger.trigger_type].secondary_trigger = trigger.secondary_trigger;
          state[trigger.trigger_type].checked = true;
          state[trigger.trigger_type].id = trigger.id;
        });
        this.setState({ ...state, flags });
      });
  }

  onChangeChecked = async (e) => {
    const state = { ...this.state };
    const trigger = e.target.name;
    state[trigger].checked = !this.state[trigger].checked;
    if (!this.state[trigger].checked && this.state[trigger].id) {
      await apiFetch(`/clinics/${this.props.clinicId}/triggers/${this.state[trigger].id}`, { method: 'DELETE' })
        .then(() => {
          state[trigger].id = false;
          if (state[trigger].primary_trigger) state[trigger].primary_trigger = '';
          if (state[trigger].secondary_trigger) state[trigger].secondary_trigger = '';
        });
    }
    this.setState({ ...state });
  }

  onChangeInput = (value, trigger, ordinal) => {
    const state = { ...this.state };
    state[trigger][ordinal] = value;
    this.setState({ ...state });
  }

  handleClickAddFilters = () => {
    const errors = {};
    forEach(this.state, (value, key) => {
      if (key === 'errors' || key === 'snackbar') return;
      if (!value.checked) return;
      if (value.primary_trigger === '') errors[`${key}_primary_trigger`] = true;
      if (value.secondary_trigger === '') errors[`${key}_secondary_trigger`] = true;
    });
    if (Object.keys(errors).length) {
      this.setState({ errors });
      return;
    }
    // submit filters to api
    forEach(this.state, (payload, key) => {
      if (key === 'errors' || key === 'snackbar') return;
      if (!payload.checked) return;
      const cleanPayload = omit(payload, ['id', 'checked', 'flag']);
      cleanPayload.flag_id = payload.flag ? payload.flag.id : null;
      if (payload.id && payload.primary_trigger) {
        const reqOpts = {
          method: 'PUT',
          body: cleanPayload,
        };
        return apiFetch(`/clinics/${this.props.clinicId}/triggers/${payload.id}`, reqOpts);
      }
      if (!payload.id) {
        const reqOpts = {
          method: 'POST',
          body: { trigger_type: key, ...cleanPayload },
        };
        return apiFetch(`/clinics/${this.props.clinicId}/triggers`, reqOpts)
          .then((res) => {
            const state = { ...this.state };
            state[res.trigger_type].id = res.id;
            this.setState(state);
          });
      }
    });
    this.setState({ errors, snackbar: true });
  }

  render() {
    const { classes, clinic } = this.props;

    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="filters" clinic={clinic} />
          <List>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.BP_HIGH.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="BP_HIGH"
                />
                <ListItemText>High Blood Pressure</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Systolic Rate: Greater than
                </Typography>
                <TextField
                  disabled={!this.state.BP_HIGH.checked}
                  className={classes.textField}
                  name="BP_HIGH_primary_trigger"
                  value={this.state.BP_HIGH.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BP_HIGH', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.BP_HIGH_primary_trigger}
                />
                <Typography component="span" className={classes.filterDetailsText}>
                  Diastolic Rate: Greater than
                </Typography>
                <TextField
                  disabled={!this.state.BP_HIGH.checked}
                  className={classes.textField}
                  name="BP_HIGH_secondary_trigger"
                  value={this.state.BP_HIGH.secondary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BP_HIGH', 'secondary_trigger'); }}
                  type="number"
                  error={this.state.errors.BP_HIGH_secondary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.BP_HIGH.flag && this.state.BP_HIGH.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'BP_HIGH', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.BP_HIGH.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.BP_HIGH.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.BP_HIGH.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.BP_LOW.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="BP_LOW"
                />
                <ListItemText>Low Blood Pressure</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>Systolic Rate: Less than</Typography>
                <TextField
                  disabled={!this.state.BP_LOW.checked}
                  className={classes.textField}
                  name="BP_LOW_primary_trigger"
                  value={this.state.BP_LOW.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BP_LOW', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.BP_LOW_primary_trigger}
                />
                <Typography component="span" className={classes.filterDetailsText}>Diastolic Rate: Less than</Typography>
                <TextField
                  disabled={!this.state.BP_LOW.checked}
                  className={classes.textField}
                  name="BP_LOW_secondary_trigger"
                  value={this.state.BP_LOW.secondary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BP_LOW', 'secondary_trigger'); }}
                  type="number"
                  error={this.state.errors.BP_LOW_secondary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.BP_LOW.flag && this.state.BP_LOW.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'BP_LOW', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.BP_LOW.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.BP_LOW.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.BP_LOW.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.SPO2_HIGH.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="SPO2_HIGH"
                />
                <ListItemText>High SpO2</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Greater than
                </Typography>
                <TextField
                  disabled={!this.state.SPO2_HIGH.checked}
                  className={classes.textField}
                  name="SPO2_HIGH_primary_trigger"
                  value={this.state.SPO2_HIGH.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'SPO2_HIGH', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.SPO2_HIGH_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.SPO2_HIGH.flag && this.state.SPO2_HIGH.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'SPO2_HIGH', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.SPO2_HIGH.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.SPO2_HIGH.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.SPO2_HIGH.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.SPO2_LOW.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="SPO2_LOW"
                />
                <ListItemText>Low SpO2</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Less than
                </Typography>
                <TextField
                  disabled={!this.state.SPO2_LOW.checked}
                  className={classes.textField}
                  name="SPO2_LOW_primary_trigger"
                  value={this.state.SPO2_LOW.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'SPO2_LOW', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.SPO2_LOW_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.SPO2_LOW.flag && this.state.SPO2_LOW.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'SPO2_LOW', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.SPO2_LOW.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.SPO2_LOW.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.SPO2_LOW.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.SWALLOWING.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="SWALLOWING"
                />
                <ListItemText>Swallowing</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <span>Difficulty Swallowing = true</span>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.SWALLOWING.flag && this.state.SWALLOWING.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'SWALLOWING', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.SWALLOWING.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.SWALLOWING.flag && <img
                alt="icon"
                height={30}
                src={this.state.SWALLOWING.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.DIARRHEA.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="DIARRHEA"
                />
                <ListItemText>Diarrhea</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Diarrhea = True &gt; Number of times per day
                </Typography>
                <TextField
                  disabled={!this.state.DIARRHEA.checked}
                  className={classes.textField}
                  name="DIARRHEA_secondary_trigger"
                  value={this.state.DIARRHEA.secondary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'DIARRHEA', 'secondary_trigger'); }}
                  type="number"
                  error={this.state.errors.DIARRHEA_secondary_trigger}
                />
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.DIARRHEA.flag && this.state.DIARRHEA.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'DIARRHEA', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.DIARRHEA.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.DIARRHEA.flag && <img
                alt="icon"
                height={30}
                src={this.state.DIARRHEA.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.WEIGHT_GAIN.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="WEIGHT_GAIN"
                />
                <ListItemText>Weight Gain</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Greater than
                </Typography>
                <TextField
                  disabled={!this.state.WEIGHT_GAIN.checked}
                  className={classes.textField}
                  name="WEIGHT_GAIN_primary_trigger"
                  value={this.state.WEIGHT_GAIN.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'WEIGHT_GAIN', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.WEIGHT_GAIN_primary_trigger}
                  inputProps={{
                    step: 0.1,
                  }}
                />
                <Typography component="span" className={classes.filterDetailsText}>lbs</Typography>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.WEIGHT_GAIN.flag && this.state.WEIGHT_GAIN.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'WEIGHT_GAIN', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.WEIGHT_GAIN.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.WEIGHT_GAIN.flag && <img
                alt="icon"
                height={30}
                src={this.state.WEIGHT_GAIN.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.WEIGHT_LOSS.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="WEIGHT_LOSS"
                />
                <ListItemText>Weight Loss</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Greater than
                </Typography>
                <TextField
                  disabled={!this.state.WEIGHT_LOSS.checked}
                  className={classes.textField}
                  name="WEIGHT_LOSS_primary_trigger"
                  value={this.state.WEIGHT_LOSS.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'WEIGHT_LOSS', 'primary_trigger'); }}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  error={this.state.errors.WEIGHT_LOSS_primary_trigger}
                />
                <Typography component="span" className={classes.filterDetailsText}>lbs</Typography>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.WEIGHT_LOSS.flag && this.state.WEIGHT_LOSS.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'WEIGHT_LOSS', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.WEIGHT_LOSS.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.WEIGHT_LOSS.flag && <img
                alt="icon"
                height={30}
                src={this.state.WEIGHT_LOSS.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.TEMPERATURE_HIGH.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="TEMPERATURE_HIGH"
                />
                <ListItemText>High Temperature</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Greater than
                </Typography>
                <TextField
                  disabled={!this.state.TEMPERATURE_HIGH.checked}
                  className={classes.textField}
                  name="TEMPERATURE_HIGH_primary_trigger"
                  value={this.state.TEMPERATURE_HIGH.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'TEMPERATURE_HIGH', 'primary_trigger'); }}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  error={this.state.errors.TEMPERATURE_HIGH_primary_trigger}
                />
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.TEMPERATURE_HIGH.flag && this.state.TEMPERATURE_HIGH.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'TEMPERATURE_HIGH', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.TEMPERATURE_HIGH.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.TEMPERATURE_HIGH.flag && <img
                alt="icon"
                height={30}
                src={this.state.TEMPERATURE_HIGH.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.TEMPERATURE_LOW.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="TEMPERATURE_LOW"
                />
                <ListItemText>Temperature_LOW</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>Less than</Typography>
                <TextField
                  disabled={!this.state.TEMPERATURE_LOW.checked}
                  className={classes.textField}
                  name="TEMPERATURE_LOW_primary_trigger"
                  value={this.state.TEMPERATURE_LOW.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'TEMPERATURE_LOW', 'primary_trigger'); }}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  error={this.state.errors.TEMPERATURE_LOW_primary_trigger}
                />
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.TEMPERATURE_LOW.flag && this.state.TEMPERATURE_LOW.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'TEMPERATURE_LOW', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.TEMPERATURE_LOW.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.TEMPERATURE_LOW.flag && <img
                alt="icon"
                height={30}
                src={this.state.TEMPERATURE_LOW.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.WEAKNESS.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="WEAKNESS"
                />
                <ListItemText>Weakness</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <span>Weakness in arms/legs = true</span>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.WEAKNESS.flag && this.state.WEAKNESS.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'WEAKNESS', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.WEAKNESS.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.WEAKNESS.flag && <img
                alt="icon"
                height={30}
                src={this.state.WEAKNESS.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.FALLS.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="FALLS"
                />
                <ListItemText>Falls</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <span>Experienced any falls = true</span>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.FALLS.flag && this.state.FALLS.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'FALLS', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.FALLS.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.FALLS.flag && <img
                alt="icon"
                height={30}
                src={this.state.FALLS.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.INCONTINENCE_BLADDER.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="INCONTINENCE_BLADDER"
                />
                <ListItemText>Incontinence (Bladder)</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Difficulty controlling bladder = true OR unable to urinate = true
                </Typography>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.INCONTINENCE_BLADDER.flag && this.state.INCONTINENCE_BLADDER.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'INCONTINENCE_BLADDER', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.INCONTINENCE_BLADDER.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.INCONTINENCE_BLADDER.flag && <img
                alt="icon"
                height={30}
                src={this.state.INCONTINENCE_BLADDER.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.INCONTINENCE_BOWEL.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="INCONTINENCE_BOWEL"
                />
                <ListItemText>Incontinence (Bowel)</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Difficulty controlling bowels = true OR unable to have BM = true
                </Typography>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.INCONTINENCE_BOWEL.flag && this.state.INCONTINENCE_BOWEL.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'INCONTINENCE_BOWEL', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.INCONTINENCE_BOWEL.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.INCONTINENCE_BOWEL.flag && <img
                alt="icon"
                height={30}
                src={this.state.INCONTINENCE_BOWEL.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.NAUSEA.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="NAUSEA"
                />
                <ListItemText>Nausea</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Nausea = true
                </Typography>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.NAUSEA.flag && this.state.NAUSEA.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'NAUSEA', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.NAUSEA.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.NAUSEA.flag && <img
                alt="icon"
                height={30}
                src={this.state.NAUSEA.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.VOMITING.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="VOMITING"
                />
                <ListItemText>Vomiting</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Vomiting = true
                </Typography>
              </div>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={this.state.VOMITING.flag && this.state.VOMITING.flag.id}
                  onChange={(e) => {
                    // eslint-disable-next-line eqeqeq
                    const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                    this.onChangeInput(this.state.flags[flagIndex], 'VOMITING', 'flag');
                  }}
                  name=""
                  className={classes.dropdown}
                  disabled={!this.state.VOMITING.checked}
                >
                  <option value={null}>None Selected</option>
                  {this.state.flags.map((flag) => {
                    return (
                      <option key={flag.id} value={flag.id}>
                        {flag.description}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              {this.state.VOMITING.flag && <img
                alt="icon"
                height={30}
                src={this.state.VOMITING.flag.icon}
                style={styles.img}
                width={30}
              />}
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.BMI_HIGH.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="BMI_HIGH"
                />
                <ListItemText>HIGH BMI</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  Greater than
                </Typography>
                <TextField
                  disabled={!this.state.BMI_HIGH.checked}
                  className={classes.textField}
                  name="BMI_HIGH_primary_trigger"
                  value={this.state.BMI_HIGH.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BMI_HIGH', 'primary_trigger'); }}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  error={this.state.errors.BMI_HIGH_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.BMI_HIGH.flag && this.state.BMI_HIGH.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'BMI_HIGH', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.BMI_HIGH.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.BMI_HIGH.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.BMI_HIGH.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.BMI_LOW.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="BMI_LOW"
                />
                <ListItemText>Low BMI</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>Less than</Typography>
                <TextField
                  disabled={!this.state.BMI_LOW.checked}
                  className={classes.textField}
                  name="BMI_LOW_primary_trigger"
                  value={this.state.BMI_LOW.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BMI_LOW', 'primary_trigger'); }}
                  type="number"
                  inputProps={{
                    step: 0.1,
                  }}
                  error={this.state.errors.BMI_LOW_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.BMI_LOW.flag && this.state.BMI_LOW.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'BMI_LOW', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.BMI_LOW.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.BMI_LOW.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.BMI_LOW.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.HR_HIGH.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="HR_HIGH"
                />
                <ListItemText>High HR</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  More than
                </Typography>
                <TextField
                  disabled={!this.state.HR_HIGH.checked}
                  className={classes.textField}
                  name="HR_HIGH_primary_trigger"
                  value={this.state.HR_HIGH.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'HR_HIGH', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.HR_HIGH_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.HR_HIGH.flag && this.state.HR_HIGH.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'HR_HIGH', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.HR_HIGH.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.HR_HIGH.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.HR_HIGH.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.HR_LOW.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="HR_LOW"
                />
                <ListItemText>Low HR</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>Less than</Typography>
                <TextField
                  disabled={!this.state.HR_LOW.checked}
                  className={classes.textField}
                  name="HR_LOW_primary_trigger"
                  value={this.state.HR_LOW.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'HR_LOW', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.HR_LOW_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.HR_LOW.flag && this.state.HR_LOW.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'HR_LOW', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.HR_LOW.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.HR_LOW.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.HR_LOW.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.BR_HIGH.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="BR_HIGH"
                />
                <ListItemText>High Breathing Rate</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>
                  More than
                </Typography>
                <TextField
                  disabled={!this.state.BR_HIGH.checked}
                  className={classes.textField}
                  name="BR_HIGH_primary_trigger"
                  value={this.state.BR_HIGH.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BR_HIGH', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.BR_HIGH_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.BR_HIGH.flag && this.state.BR_HIGH.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'BR_HIGH', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.BR_HIGH.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.BR_HIGH.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.BR_HIGH.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
            <ListItem
              className={classes.listItem}
              onClick={() => {}}
            >
              <div className={classes.checkboxWrapper}>
                <Checkbox
                  checked={this.state.BR_LOW.checked}
                  onChange={this.onChangeChecked}
                  classes={{ root: classes.checkbox }}
                  name="BR_LOW"
                />
                <ListItemText>Low Breathing Rate</ListItemText>
              </div>
              <div className={classes.filterDetailsRow}>
                <Typography component="span" className={classes.filterDetailsText}>Less than</Typography>
                <TextField
                  disabled={!this.state.BR_LOW.checked}
                  className={classes.textField}
                  name="BR_LOW_primary_trigger"
                  value={this.state.BR_LOW.primary_trigger}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'BR_LOW', 'primary_trigger'); }}
                  type="number"
                  error={this.state.errors.BR_LOW_primary_trigger}
                />
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={this.state.BR_LOW.flag && this.state.BR_LOW.flag.id}
                    onChange={(e) => {
                      // eslint-disable-next-line eqeqeq
                      const flagIndex = findIndex(this.state.flags, (flag) => { return flag.id == e.target.value; });
                      this.onChangeInput(this.state.flags[flagIndex], 'BR_LOW', 'flag');
                    }}
                    name=""
                    className={classes.dropdown}
                    disabled={!this.state.BR_LOW.checked}
                  >
                    <option value={null}>None Selected</option>
                    {this.state.flags.map((flag) => {
                      return (
                        <option key={flag.id} value={flag.id}>
                          {flag.description}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
                {this.state.BR_LOW.flag && <img
                  alt="icon"
                  height={30}
                  src={this.state.BR_LOW.flag.icon}
                  style={styles.img}
                  width={30}
                />}
              </div>
            </ListItem>
          </List>
          {Object.keys(this.state.errors).length ? (
            <div className={classes.errorMessage}>Please fill out all required fields</div>
          ) : null}
          <Button
            variant="contained"
            color="secondary"
            className={classes.addFiltersButton}
            onClick={this.handleClickAddFilters}
          >
            Add Filters
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.snackbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ snackbar: false  })}
        >
          <SnackbarContent
            classes={{ root: classes.root }}
            message="Filters saved"
            action={
              <IconButton
                size="small"
                aria-label="close"
                onClick={() => this.setState({ snackbar: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return { clinic, clinicId };
}

Features.propTypes = {
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { browseClinicsIfNecessary })(withStyles(styles)(Features));
