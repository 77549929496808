import { combineReducers } from 'redux';

import clinics from './clinics';
import conditions from './conditions';
import customers from './customers';
import emr from './emr';
import logs from './logs';
import providers from './providers';
import pqrs from './pqrs';
import releases from './releases';
import reports from './reports';
import self from './self';
import surgeries from './surgeries';
import symptoms from './symptoms';
import users from './users';

const reducers = combineReducers({
  clinics,
  conditions,
  customers,
  emr,
  logs,
  providers,
  pqrs,
  releases,
  reports,
  self,
  surgeries,
  symptoms,
  users,
});

export default reducers;
