import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PqrsComponent from '../components/pqrs';
import Header from '../components/header';
import Table from '../components/table';
import { browsePqrs, editPqrs, addPqrs, deletePqrs } from '../state/pqrs';

const baseStyles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  mainBody: {
    height: 'calc(100% - 120px)',
    maxHeight: '100%',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
};

export class Pqrs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddPqrs = this.handleAddPqrs.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    this.props.browsePqrs();
  }
  startAdding() {
    this.setState({
      adding: true,
    });
  }
  handleAddPqrs(details) {
    return this.props.addPqrs(details);
  }
  handleCloseAdd() {
    this.setState({ adding: false });
  }
  render() {
    const { pqrs, router } = this.props;
    let addNewSection = (
      <Button
        onClick={this.startAdding}
        variant="contained"
      >Add PQRS</Button>
    );

    const rows = map(pqrs.data, row => row);

    const cols = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'name', headerName: 'Name', width: 300 },
      { field: 'description', headerName: 'Description', width: 700 },
    ];

    if (this.state.adding) {
      addNewSection = (<PqrsComponent
        handleSubmit={this.handleAddPqrs}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
      />);
    }

    const handleClick = ({ id }) => {
      router.push(`/pqrs/${id}`);
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={baseStyles.mainBody}>
          <Box style={baseStyles.addBtn}>{addNewSection}</Box>
          <div style={{ marginTop: 10, height: `${this.state.adding ? 80 : 100}%` }}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { pqrs } = state;
  return {
    pqrs,
  };
}

const actionCreators = {
  addPqrs,
  editPqrs,
  browsePqrs,
  deletePqrs,
};

Pqrs.defaultProps = {
  pqrs: {},
};

Pqrs.propTypes = {
  addPqrs: PropTypes.func.isRequired,
  browsePqrs: PropTypes.func.isRequired,
  pqrs: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Pqrs);
