import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, omit } from 'lodash';

import Paper from 'material-ui/Paper';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import SubmitButton from '../components/submit-button';

const baseStyles = {
  btn: {
    margin: '5px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    margin: '20px auto',
    minWidth: '40%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
};

class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.provider,
      errors: {},
      dataChanged: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
    this.onUpdateAddress = this.onUpdateField.bind(this, 'address');
    this.onUpdateCity = this.onUpdateField.bind(this, 'city');
    this.onUpdateState = this.onUpdateField.bind(this, 'state');
    this.onUpdateZip = this.onUpdateField.bind(this, 'zip_code');
    this.onUpdatePhone = this.onUpdateField.bind(this, 'phone_number');
    this.onUpdateFax = this.onUpdateField.bind(this, 'fax_number');
    this.onUpdateNpi = this.onUpdateField.bind(this, 'npi_number');
    this.onUpdateProviderSpecialty = this.onUpdateField.bind(this, 'provider_specialty');
    this.onUpdateUserId = this.onUpdateField.bind(this, 'user_id');
    this.onUpdateCustomerId = this.onUpdateField.bind(this, 'customer_id');
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.provider !== this.props.provider) {
      this.setState({ ...nextProps.provider });
    }
  }
  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      name: '',
      dataChanged: false,
      provider_specialty: '',
      user_id: '',
      error: '',
    });
  }
  onUpdateField(fieldName, e, value, fieldValue) {
    if (fieldName === 'provider_specialty' || fieldName === 'customer_id') {
      value = fieldValue;
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [fieldName]: null,
      },
      [fieldName]: value,
      dataChanged: true,
    });
  }
  onSubmit() {
    const payload = omit(this.state, ['error', 'errors', 'dataChanged']);

    if (!payload.provider_specialty) {
      return this.setState({
        errors: {
          ...this.state.errors,
          provider_specialty: 'Provider Specialty is Required!',
        },
      });
    }
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          dataChanged: false,
          error: '',
          ...this.props.provider,
        });
        this.props.handleCloseAdd();
      })
      .catch(() => {
        this.setState({
          error: 'An error has occured',
        });
      });
  }
  render() {
    const { customers, style } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, style);
    const customerOptions = customers.map(c => <MenuItem value={c.id} primaryText={c.name} />);

    return (
      <Paper style={containerStyle}>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Provider Name"
            hintText="Dr. John Smith"
            onChange={this.onUpdateName}
            value={this.state.name}
          />
          <SelectField
            autoComplete="off"
            errorText={this.state.errors.provider_specialty}
            floatingLabelText="Provider Specialty"
            value={this.state.provider_specialty}
            onChange={this.onUpdateProviderSpecialty}
          >
            <MenuItem value="CHIROPRACTOR" primaryText="CHIROPRACTOR" />
            <MenuItem value="DOCTOR" primaryText="DOCTOR" />
            <MenuItem value="MEDICAL_ASSISTANT" primaryText="MEDICAL_ASSISTANT" />
            <MenuItem value="NATUROPATH" primaryText="NATUROPATH" />
            <MenuItem value="NURSE_PRACTICIONER" primaryText="NURSE_PRACTICIONER" />
            <MenuItem value="OTHER" primaryText="OTHER" />
            <MenuItem value="PHYSICAL_THERAPIST" primaryText="PHYSICAL_THERAPIST" />
            <MenuItem value="PHYSICIAN_ASSISTANT" primaryText="PHYSICIAN_ASSISTANT" />
          </SelectField>
        </div>
        <div style={baseStyles.fieldRow}>
          <SelectField
            floatingLabelText="Customer"
            value={this.state.customer_id}
            onChange={this.onUpdateCustomerId}
          >
            <MenuItem value={null} primaryText="" />
            {customerOptions}
          </SelectField>
          <TextField
            autoComplete="off"
            floatingLabelText="NPI Number"
            hintText="Dr. John Smith"
            onChange={this.onUpdateNpi}
            value={this.state.npi_number}
          />
        </div>
        <div>
          <TextField
            autoComplete="off"
            floatingLabelText="Address"
            hintText="1450 SW Roadside St"
            onChange={this.onUpdateAddress}
            value={this.state.address || ''}
          />
        </div>

        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="City"
            hintText="Phoenix"
            onChange={this.onUpdateCity}
            value={this.state.city || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="State"
            hintText="AZ"
            onChange={this.onUpdateState}
            value={this.state.state || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Postal Code"
            hintText="85281"
            onChange={this.onUpdateZip}
            value={this.state.zip_code || ''}
          />
        </div>

        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Phone"
            hintText="480 (867)-5309"
            onChange={this.onUpdatePhone}
            value={this.state.phone_number || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Fax"
            hintText="480 (867)-5309"
            onChange={this.onUpdateFax}
            value={this.state.fax_number || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="NPI Number"
            hintText="8012345678"
            onChange={this.onUpdateNpi}
            value={this.state.npi_number || ''}
          />
        </div>
        <div style={baseStyles.fieldRow}>
          <div style={baseStyles.buttons}>
            { this.props.showClose ? (
              <Button
                onClick={this.onCancelAdd}
                style={baseStyles.btn}
                variant="contained"
              >Cancel
              </Button>
             ) : null }
            <SubmitButton
              btnStyle={baseStyles.btn}
              disabled={!this.state.dataChanged}
              label={this.props.submitText}
              onSubmit={this.onSubmit}
              text={this.props.submitText}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

Provider.defaultProps = {
  customers: [],
  handleCloseAdd: noop,
  provider: {
    customer_id: '',
    user_id: '',
    name: '',
    provider_specialty: '',
  },
  showClose: true,
  style: {},
  submitText: 'Create Provider',
};

Provider.propTypes = {
  customers: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  provider: PropTypes.object,
  showClose: PropTypes.bool,
  style: PropTypes.object,
  submitText: PropTypes.string,
};

export default Provider;
