import { promiseHandler } from 'cooldux';
import { isEmpty, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const handlerOptions = { namespace: 'providers', throwErrors: true };
const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'providers');
const { editEnd, editHandler } = promiseHandler('edit', 'providers');
const { addEnd, addError, addHandler } = promiseHandler('add', handlerOptions);
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'providers');

export function browseProviders() {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/providers');
    return browseHandler(promise, dispatch);
  };
}

export function editProvider(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isEmpty),
    };
    const promise = apiFetch(`/providers/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addProvider(newProvider) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newProvider, isEmpty),
    };
    const promise = apiFetch('/providers', options);
    return addHandler(promise, dispatch);
  };
}

export function deleteProvider(providerId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/providers/${providerId}`, options)
      .then(() => providerId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, providers) {
  const data = {};
  providers.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, provider) {
  const data = { ...state.data, [provider.id]: provider };
  return { ...state, error: null, data };
}

function finishAdd(state, provider) {
  const data = { ...state.data, [provider.id]: provider };
  return { ...state, error: null, data };
}

function finishDelete(state, providerId) {
  return { ...state, data: omit(state.data, providerId) };
}

function provider(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    default:
      return state;
  }
}

export default provider;
