import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import Header from '../components/header';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import { isPasswordValid } from '../lib/valildators';

const styles = {
  formContainer: {
    margin: 'auto',
    padding: '35px 50px 50px',
    maxWidth: '500px',
  },
  btnLabel: {
    fontSize: '18px',
    lineHeight: '2.9',
  },
  btnStyle: {
    height: '50px',
    margin: '10px',
    marginTop: '50px',
    width: '100%',
  },
  errorText: {
    color: colors.errorRed,
    height: '18px',
    paddingTop: '18px',
    marginBottom: '30px',
  },
  successText: {
    color: colors.successGreen,
    height: '18px',
    paddingTop: '18px',
    marginBottom: '10px',
  },
  field: {
    borderRadius: '10px',
    display: 'block',
    height: '70px',
    padding: '10px',
    width: '100%',
  },
  errorTextSmall: {
    color: colors.errorRed,
    fontSize: '10px',
    textAlign: 'right',
  },
  textField: {
    marginBottom: '20px',
  }
};

const initialState = {
  response: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

function PasswordReset(props) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState(initialState);
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleUpdatePassword = () => {
    setResetSuccess(false);
    const err = isPasswordValid(oldPassword, newPassword, confirmPassword);
    if (Object.keys(err).length > 0) {
      return setErrors(err);
    }
    const requestOptions = {
      body: {
        old_password: oldPassword,
        new_password: newPassword,
        verify_password: confirmPassword
      },
      method: 'POST',
    };
    apiFetch(`/users/${props.self.id}/reset`, requestOptions)
      .then(() => {
        setResetSuccess(true);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrors(initialState);
      })
      .catch((error) => {
        let msg = 'Error updating password';

        if (error.content && error.content.message) {
          const { content: { message } } = error;
          msg = message;
        }
        return setErrors({ ...errors, response: msg.replace('Error: ', '').replace('_', ' ') });
      });
  };

  return (
    <>
      <Header />
      <Box padding={1.25} style={{ maxHeight: '100%', height: '100%' }}>
        <div style={{ marginTop: 10, height: '100%' }}>
          <h3>
            Reset your password
          </h3>
          <div style={styles.formContainer}>
            <div style={{ ...styles.errorText, display: errors.response ? 'block' : 'none' }}>
              {errors.response}
            </div>
            <div style={{ ...styles.successText, display: resetSuccess ? 'block' : 'none' }}>
              Password successfully updated
            </div>
            <form noValidate autoComplete="off">
              <TextField
                fullWidth
                error={errors.oldPassword}
                helperText={errors.oldPassword}
                label="Old Password"
                type="password"
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
                style={styles.textField}
              />
              <TextField
                fullWidth
                error={errors.newPassword}
                helperText={errors.newPassword}
                label="New Password"
                type="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                style={styles.textField}
              />
              <TextField
                fullWidth
                error={errors.confirmPassword}
                helperText={errors.confirmPassword}
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                style={styles.textField}
              />
              <Button
                onClick={handleUpdatePassword}
                style={styles.btnStyle}
                type="button"
                variant="contained"
                color="primary"
              >
                <span style={styles.btnLabel}>Update Password</span>
              </Button>
            </form>
          </div>
        </div>
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  const {
    self,
  } = state;
  return {
    self,
  };
}

PasswordReset.propTypes = {
  self: PropTypes.object.isRequired,
};


export default connect(mapStateToProps)(withStyles(styles)(PasswordReset));
