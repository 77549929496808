import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { noop } from 'lodash';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import FlatButton from 'material-ui/FlatButton';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import { logout } from '../state/self';
import { colors } from '../lib/styles';

const baseStyles = {
  btn: {
    width: '175px',
  },
  header: {
    backgroundColor: colors.darkAccent,
    padding: '25px',
  },
  headerText: {
    color: colors.highlightBright,
    fontWeight: 100,
    paddingLeft: '10px',
  },
  icon: {
    color: colors.white,
  },
  logo: {
    height: '40px',
    padding: '5px 5px 5px 10px',
    marginRight: '50px',
  },
  menuLink: {
    textDecoration: 'none',
  },
  settingsMenu: {
    margin: 0,
  },
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOpenReports = this.handleOpenMenu.bind(this, 'showReportsMenu');
    this.handleCloseReports = this.handleCloseMenu.bind(this, 'showReportsMenu');
    this.handleOpenEmrSettings = this.handleOpenMenu.bind(this, 'showEmrSettings');
    this.handleCloseEmrSettings = this.handleCloseMenu.bind(this, 'showEmrSettings');
    this.handleOpenSettings = this.handleOpenMenu.bind(this, 'showSettingsMenu');
    this.handleCloseSettings = this.handleCloseMenu.bind(this, 'showSettingsMenu');
  }
  handleOpenMenu(menu, e) {
    e.preventDefault();
    this.setState({
      [menu]: true,
      anchorEl: e.currentTarget,
    });
  }
  handleCloseMenu(menu) {
    this.setState({
      [menu]: false,
    });
  }

  render() {
    const settingsMenu = (
      <Popover
        open={this.state.showSettingsMenu}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={this.handleCloseSettings}
      >
        <Menu>
          <Link style={baseStyles.menuLink} to="/pqrs">
            <MenuItem onClick={this.handleCloseSettings} primaryText="PQRS" />
          </Link>
          <Link style={baseStyles.menuLink} to="/conditions">
            <MenuItem onClick={this.handleCloseSettings} primaryText="Conditions" />
          </Link>
          <Link style={baseStyles.menuLink} to="/symptoms">
            <MenuItem onClick={this.handleCloseSettings} primaryText="Symptoms" />
          </Link>
          <Link style={baseStyles.menuLink} to="/access-logs">
            <MenuItem onClick={this.handleCloseSettings} primaryText="Access Logs" />
          </Link>
          <Link style={baseStyles.menuLink} to="/surgeries">
            <MenuItem onClick={this.handleCloseSettings} primaryText="Surgeries" />
          </Link>
          <Link style={baseStyles.menuLink} to="/delete-user">
            <MenuItem onClick={this.handleCloseSettings} primaryText="Delete User" />
          </Link>
        </Menu>
      </Popover>

    );

    const emrMenu = (
      <Popover
        open={this.state.showEmrSettings}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={this.handleCloseEmrSettings}
      >
        <Menu>
          <Link style={baseStyles.menuLink} to="/emr/instances">
            <MenuItem onClick={this.handleCloseReports} primaryText="Instances" />
          </Link>
          <Link style={baseStyles.menuLink} to="/emr/types">
            <MenuItem onClick={this.handleCloseReports} primaryText="Types" />
          </Link>
        </Menu>
      </Popover>
    );

    const reportsMenu = (
      <Popover
        open={this.state.showReportsMenu}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={this.handleCloseReports}
      >
        <Menu>
          <Link style={baseStyles.menuLink} to="/reports/activity">
            <MenuItem onClick={this.handleCloseReports} primaryText="Activity" />
          </Link>
          <Link style={baseStyles.menuLink} to="/reports/body-pain">
            <MenuItem onClick={this.handleCloseReports} primaryText="Body Pain" />
          </Link>
          <Link style={baseStyles.menuLink} to="/reports/clinical-headache">
            <MenuItem onClick={this.handleCloseReports} primaryText="Clinical Headache" />
          </Link>
          <Link style={baseStyles.menuLink} to="/reports/clinical-headache-medications">
            <MenuItem onClick={this.handleCloseReports} primaryText="Clinical Headache Medications" />
          </Link>
          <Link style={baseStyles.menuLink} to="/reports/demographic">
            <MenuItem onClick={this.handleCloseReports} primaryText="Demographic" />
          </Link>
        </Menu>
      </Popover>
    );
    return (
      <Toolbar style={baseStyles.header}>
        {settingsMenu}
        {reportsMenu}
        {emrMenu}
        <ToolbarGroup firstChild={true}>
          <img
            alt="Mindset Logo"
            src="/img/informed.svg"
            style={baseStyles.logo}
          />
          <Link style={baseStyles.menuLink} to="/customers">
            <FlatButton label="Customers" primary={true} />
          </Link>
          <Link to="/users">
            <FlatButton label="Users" primary={true} />
          </Link>
          <Link to="/providers">
            <FlatButton label="Providers" primary={true} />
          </Link>
          <Link to="/clinics">
            <FlatButton label="Clinics" primary={true} />
          </Link>
          <FlatButton
            label="EMR Settings"
            onClick={this.handleOpenEmrSettings}
            primary={true}
            style={baseStyles.settingsMenu}
          />
          <FlatButton
            label="Reports"
            onClick={this.handleOpenReports}
            primary={true}
            style={baseStyles.settingsMenu}
          />
          <Link to="sms-messages">
            <FlatButton label="Messages" primary={true} />
          </Link>
          <Link to="kpi">
            <FlatButton label="KPI" primary={true} />
          </Link>
          <Link to="ivc">
            <FlatButton label="IVC" primary={true} />
          </Link>
          <FlatButton
            label="App Settings"
            onClick={this.handleOpenSettings}
            primary={true}
            style={baseStyles.settingsMenu}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          <IconMenu
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            targetOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            iconStyle={baseStyles.icon}
            iconButtonElement={<IconButton><SettingsIcon /></IconButton>}
          >
            <MenuItem onClick={this.props.logout} primaryText="Logout" />
            <Link to="password-reset" style={{ textDecoration: 'none' }}>
              <MenuItem primaryText="Reset Password" />
            </Link>
          </IconMenu>
        </ToolbarGroup>
      </Toolbar>
    );
  }
}

const actionCreators = { logout };
function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

Header.defaultProps = {
  appointments: [],
  dismissNotification: noop,
  logout: noop,
  notifications: [],
  openCheckInModal: noop,
  openDeviceModal: noop,
  openPatientModal: noop,
  router: {},
};

Header.propTypes = {
  logout: PropTypes.func,
};

export default connect(mapStateToProps, actionCreators)(Header);
