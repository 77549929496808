import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';

import Button from '@material-ui/core/Button';
import Table from '../base/table';
import TableBody from '../base/table-body';
import TableHead from '../base/table-head';
import TableRow from '../base/table-row';
import TableCell from '../base/table-cell';

import Header from '../components/header';
import AddType from '../components/add-type';
import { addType, browseTypes } from '../state/emr';

const baseStyles = {
  addBtn: {
    backgroundColor: '#fff',
  },
  addDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  container: {
    width: '100%',
  },
  mainBody: {
    margin: '10px',
  },
  row: {
    cursor: 'arrow',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '950px',
  },
};

export class EmrTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
    };

    this.startAdding = this.startAdding.bind(this);
    this.handleAddType = this.handleAddType.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }

  componentDidMount() {
    this.props.browseTypes();
  }

  startAdding() {
    this.setState({
      adding: true,
    });
  }

  handleAddType(details) {
    return this.props.addType(details);
  }

  handleCloseAdd() {
    this.setState({ adding: false });
  }

  render() {
    const { emr } = this.props;
    let addNewSection = (
      <div style={baseStyles.addDiv}>
        <Button
          onClick={this.startAdding}
          style={baseStyles.addBtn}
          variant="contained"
        >Add Type
        </Button>
      </div>
    );

    const rows = map(emr.typeData, row => (
      <TableRow
        hover
        key={row.id}
        style={baseStyles.row}
      >
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
      </TableRow>
    ));

    if (this.state.adding) {
      addNewSection = (<AddType
        handleSubmit={this.handleAddType}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
      />);
    }

    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          {addNewSection}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="3" style={baseStyles.tableHeader}>
                  Providers
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell tooltip="ID">ID</TableCell>
                <TableCell tooltip="Name">Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { emr } = state;
  return {
    emr,
  };
}

const actionCreators = {
  addType,
  browseTypes,
};

EmrTypes.propTypes = {
  emr: PropTypes.object.isRequired,
  addType: PropTypes.func.isRequired,
  browseTypes: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(EmrTypes);

