import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Table from '../base/table';
import TableBody from '../base/table-body';
import TableHead from '../base/table-head';
import TableRow from '../base/table-row';
import TableCell from '../base/table-cell';
import Header from '../components/header';
import AddInstance from '../components/add-instance';
import { addInstance, browseInstances, browseTypes } from '../state/emr';

const baseStyles = {
  addBtn: {
    backgroundColor: '#fff',
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '65px',
  },
  container: {
    width: '100%',
  },
  tableHeader: {
    textAlign: 'center',
  },
  row: {
    cursor: 'pointer',
  },
  selectField: {
    width: '166px',
    marginLeft: '20px',
    position: 'absolute',
    left: '-10px',
    top: '65px',

  },
  topSection: {
    margin: '15px auto',
    width: '950px',
  },
};

export class EmrInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
      typeId: '',
    };

    this.startAdding = this.startAdding.bind(this);
    this.handleAddInstance = this.handleAddInstance.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
    this.onUpdateTypeId = this.onUpdateTypeId.bind(this);
  }

  componentDidMount() {
    this.props.browseTypes();
  }

  onUpdateTypeId(event) {
    const { value } = event.target;
    this.setState({ typeId: value });
    this.props.browseInstances(value);
  }

  startAdding() {
    this.setState({
      adding: true,
    });
  }

  handleAddInstance(details) {
    return this.props.addInstance(details);
  }

  handleCloseAdd() {
    this.setState({ adding: false });
  }

  render() {
    const { emr } = this.props;
    let addNewSection = (
      <Button
        variant="contained"
        onClick={this.startAdding}
        style={baseStyles.addBtn}
      >
        Add Instance
      </Button>
    );

    const rows = map(emr.instanceData, row => (
      <TableRow
        hover
        onClick={() => this.props.router.push(`/emr/types/${row.emr_types_id}/instances/${row.id}`)}
        style={baseStyles.row}
        key={row.id}
      >
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.redox_destination_id}</TableCell>
      </TableRow>
    ));

    if (this.state.adding) {
      addNewSection = (<AddInstance
        handleSubmit={this.handleAddInstance}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
        types={emr.typeData}
      />);
    }

    const typesMenuItems = map(emr.typeData, row => (
      <MenuItem key={row.id} value={row.id} >
        {row.name}
      </MenuItem>
    ));

    const typesMenu = (
      <Select
        style={baseStyles.selectField}
        autoComplete="off"
        label="Choose a Type"
        value={this.state.typeId}
        onChange={this.onUpdateTypeId}
      >
        {typesMenuItems}
      </Select>
    );

    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          {this.state.adding ? '' : typesMenu}
          {addNewSection}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="3" style={baseStyles.tableHeader}>
                  <h1>EMR Instances</h1>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell tooltip="ID">ID</TableCell>
                <TableCell tooltip="Name">Name</TableCell>
                <TableCell tooltip="Redox Id">Redox ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.typeId ? rows : ''}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { emr, clinics } = state;
  return { emr, clinics };
}

const actionCreators = {
  addInstance,
  browseInstances,
  browseTypes,
};

EmrInstance.propTypes = {
  emr: PropTypes.object.isRequired,
  addInstance: PropTypes.func.isRequired,
  browseInstances: PropTypes.func.isRequired,
  browseTypes: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(EmrInstance);

