// These env vars are set automatically as a result of
// npm run test, start, and build

// TODO: Remove this atrocity that exists
// solely because create-react-app doesnt let us set env vars
function getApiUrl() {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser && window.location.hostname.includes('mindsetmedical')) {
    const [subdomain, hostname, tld] = window.location.href.split('.');
    let api = 'api';

    if (subdomain.includes('staging')) {
      api = 'api-staging';
    }

    return `https://${api}.${hostname}.${tld.split('/')[0]}`;
  }
  return 'http://localhost:3001';
}
const env = {
  test: {
    IMAGE_URL: 'https://s3-us-west-2.amazonaws.com/smc1234',
    API_URL: process.env.REACT_APP_API_URL || 'https://api-staging.mindsetmedical.com',
    HELLOSIGN_CLIENT_ID: '5eec6229a541033e051fef5d491475d4',
    test: 'test',
  },
  development: {
    IMAGE_URL: 'https://s3-us-west-2.amazonaws.com/mm-staging123',
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    HELLOSIGN_CLIENT_ID: '5eec6229a541033e051fef5d491475d4',
    test: 'dev',
  },
  staging: {
    IMAGE_URL: 'https://s3-us-west-2.amazonaws.com/mm-staging123',
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    HELLOSIGN_CLIENT_ID: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
    test: 'stage',
  },
  demo: {
    IMAGE_URL: 'https://s3-us-west-2.amazonaws.com/mm-staging123',
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    HELLOSIGN_CLIENT_ID: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
    test: 'demo',
  },
  production: {
    IMAGE_URL: 'https://s3-us-west-1.amazonaws.com/mm-production123',
    API_URL: process.env.REACT_APP_API_URL || getApiUrl(),
    HELLOSIGN_CLIENT_ID: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
    test: 'prod',
  },
};

export default env[process.env.NODE_ENV];
