import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, omit } from 'lodash';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const baseStyles = {
  btn: {
    margin: '5px',
    backgroundColor: '#fff',
  },
  buttons: {
    marginTop: '20px',
    minWidth: '40%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
};

class AddType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.emr,
      dataChanged: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
  }

  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      name: '',
      dataChanged: false,
      error: '',
    });
  }
  onUpdateField(fieldName, e) {
    this.setState({
      [fieldName]: e.target.value,
      dataChanged: true,
    });
  }
  onSubmit() {
    const payload = omit(this.state, ['error', 'dataChanged']);
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          dataChanged: false,
          error: '',
          ...this.props.emr,
        });
        this.props.handleCloseAdd();
      })
      .catch(() => {
        this.setState({
          error: 'An error has occurred',
        });
      });
  }
  render() {
    const { style } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, style);

    return (
      <Paper style={containerStyle}>
        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            label="Name"
            placeholder="Type Name"
            onChange={this.onUpdateName}
            value={this.state.name}
          />
          <div style={baseStyles.buttons}>
            { this.props.showClose ? (
              <Button
                label="Cancel"
                onClick={this.onCancelAdd}
                style={baseStyles.btn}
                variant="contained"
              >Cancel
              </Button>
            ) : null }
            <Button
              disabled={!this.state.dataChanged}
              onClick={this.onSubmit}
              style={baseStyles.btn}
              variant="contained"
            >{this.props.submitText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

AddType.defaultProps = {
  handleCloseAdd: noop,
  emr: {
    name: '',
  },
  showClose: true,
  style: {},
  submitText: 'Create Type',
};

AddType.propTypes = {
  emr: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  showClose: PropTypes.bool,
  style: PropTypes.object,
  submitText: PropTypes.string,
};

export default AddType;
