import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import CropModal from './crop-modal';

const baseStyles = {
  container: {
    height: '200px',
    margin: '10px',
    width: '180px',
  },
  fileInputStyle: {
    display: 'none',
  },
  img: {
    marginLeft: '10px',
  },
};

/* global FileReader */
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      cropModalOpen: false,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  onFileChange(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        cropModalOpen: true,
        file: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  }
  handleModalClose() {
    this.setState({
      cropModalOpen: false,
    });
  }
  render() {
    const {
      alt, displayImage, imageHeight, imageWidth, uploadText,
    } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, this.props.containerStyle);
    return (
      <div style={containerStyle}>
        <CropModal
          aspectRatio={this.props.aspectRatio}
          handleModalClose={this.handleModalClose}
          handleUpload={this.props.handleUpload}
          open={this.state.cropModalOpen}
          imageTypeName={this.props.imageTypeName}
          src={this.state.file}
        />
        <img
          alt={alt}
          height={imageHeight}
          src={displayImage}
          style={baseStyles.img}
          width={imageWidth}
        />
        <input
          accept="image/*"
          type="file"
          onChange={this.onFileChange}
          id="file-upload-button"
          style={baseStyles.fileInputStyle}
        />
        <label htmlFor="file-upload-button">
          <Button variant="contained" component="span" onClick={this.state.cropModalOpen}>
            {uploadText}
          </Button>
        </label>
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  alt: 'An Image',
  aspectRatio: 1, // E.g. 16 / 9
  containerStyle: {},
  imageHeight: '150px',
  imageWidth: '150px',
  imageTypeName: 'Logo',
  uploadText: 'Upload',

};

ImageUpload.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  containerStyle: PropTypes.object,
  displayImage: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  imageHeight: PropTypes.string,
  imageTypeName: PropTypes.string,
  imageWidth: PropTypes.string,
  uploadText: PropTypes.string,
};

export default ImageUpload;
