import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';

import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const formattedCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const styles = {
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  dateText: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 24,
    justifyContent: 'space-around',
    minWidth: 130,
  },
  errorText: {
    color: colors.errorRed,
    marginTop: 10,
  },
  exportButton: {
    backgroundColor: colors.primaryColor,
    border: `1px solid ${colors.primaryColor}`,
    color: colors.white,
    marginTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  formGroup: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  greyText: {
    color: '#959595',
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1000,
    width: 'max-content',
  },
  timeValue: {
    fontSize: '2rem',
  },
  totalTime: {
    background: 'black',
    borderRadius: 4,
    color: 'white',
  },
  totalsCard: {
    borderRadius: 4,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    width: 250,
  },
  totalsSection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
};

const exportCsv = (report, month, year) => {
  const dateStr = moment({ month, year }).format('YYYY-MM');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
  downloadTag.setAttribute('download', `invoice_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

const exportPerEventInvoice = (invoice, month, year) => {
  const { invoiceData, totals } = invoice;

  const aggTitle = 'Totals';
  const aggHeaders = 'Appointments,Pro Submissions,Vitals,Total Events,Cost';
  const aggRow = `${totals.appointments},${totals.proSubmissions},${totals.vitals},${totals.events},${formattedCurrency.format(totals.cost)}`; // eslint-disable-line max-len
  const aggReport = [aggTitle, aggHeaders, aggRow].join('\n');

  const appointmentsTitle = 'Appointments';
  const appointmentsHeaders = 'Patient,Recorded At';
  const appointmentsRows = [];
  invoiceData.appointments.forEach((appointment) => {
    appointmentsRows.push(`${appointment.cell_phone},${appointment.recorded_at}`);
  });
  const appointmentsReport = [appointmentsTitle, appointmentsHeaders, ...appointmentsRows].join('\n');

  const vitalsTitle = 'Vitals';
  const vitalsHeaders = 'Patient,Recorded At';
  const vitalsRows = [];
  invoiceData.vitals.forEach((vital) => {
    vitalsRows.push(`${vital.cell_phone},${vital.recorded_at}`);
  });
  const vitalsReport = [vitalsTitle, vitalsHeaders, ...vitalsRows].join('\n');

  const proTitle = 'Pro Submissions';
  const proHeaders = 'Type,Patient,Recorded At';
  const proRows = [];
  Object.entries(invoiceData.proSubmissions).forEach(([pro_type, proSubmissions]) => {
    proSubmissions.forEach((proSubmission) => {
      proRows.push(`${pro_type},${proSubmission.cell_phone},${proSubmission.recorded_at}`);
    });
  });
  const proReport = [proTitle, proHeaders, ...proRows].join('\n');

  const perEventInvoiceReport = [aggReport, appointmentsReport, vitalsReport, proReport].join('\n,\n');

  return exportCsv(perEventInvoiceReport, month, year);
};

const exportPerPatientInvoice = (invoice, month, year) => {
  const { invoiceData, totals } = invoice;

  const aggTitle = 'Totals';
  const aggHeaders = 'Patients,Cost';
  const aggRow = `${totals.patients},${formattedCurrency.format(totals.cost)}`;
  const aggReport = [aggTitle, aggHeaders, aggRow].join('\n');

  const patientTitle = 'Patients';
  const patientHeaders = 'Patient,Appointments,Pro Submissions,Vitals';
  const patientRows = [];
  Object.entries(invoiceData).forEach(([patientId, patientData]) => {
    patientRows.push(`${patientId},${patientData.appointments},${patientData.proSubmissions},${patientData.vitals}`);
  });
  const patientReport = [patientTitle, patientHeaders, ...patientRows].join('\n');

  const perPatientInvoiceReport = [aggReport, patientReport].join('\n,\n');

  return exportCsv(perPatientInvoiceReport, month, year);
};

class ClinicInvoicing extends Component {
  constructor(props) {
    super(props);

    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();

    this.state = {
      billing: null,
      enabled: false,
      invoice: null,
      invoiceNotFound: false,
      month,
      year,
    };

    const options = {
      params: {
        month,
        year,
      },
    };

    apiFetch(`/clinics/${props.clinicId}/invoice`, options)
      .then((response) => {
        this.setState({
          invoice: response,
          invoiceNotFound: false,
        });
      })
      .catch((e) => {
        if (e.status === 404) this.setState({ invoiceNotFound: true });
      });

    const billingOpts = {
      method: 'GET',
    };

    apiFetch(`/clinics/${props.clinicId}/billing`, billingOpts)
      .then((res) => {
        this.setState({
          billing: res,
          enabled: res.invoicing_enabled,
        });
      });
  }

  getInvoice = () => {
    const { clinicId } = this.props;
    const { month, year } = this.state;

    const options = {
      params: {
        month,
        year,
      },
    };

    this.setState({
      invoice: null,
      invoiceNotFound: false,
    });

    apiFetch(`/clinics/${clinicId}/invoice`, options)
      .then((response) => {
        this.setState({
          invoice: response,
          invoiceNotFound: false,
        });
      })
      .catch((e) => {
        if (e.status === 404) this.setState({ invoiceNotFound: true });
      });
  }

  handleClickPrevMonth = () => {
    const { month, year } = this.state;
    let prevMonth = month - 1;
    let newyear = year;

    if (prevMonth < 0) {
      prevMonth = 11;
      newyear -= 1;
    }

    this.setState({
      month: prevMonth,
      year: newyear,
    }, () => { this.getInvoice(); });
  }

  handleClickNextMonth = () => {
    const { month, year } = this.state;
    let nextMonth = month + 1;
    let newyear = year;

    if (nextMonth > 11) {
      nextMonth = 0;
      newyear += 1;
    }

    this.setState({
      month: nextMonth,
      year: newyear,
    }, () => { this.getInvoice(); });
  }

  handleToggleInvoicing = (e) => {
    const { checked } = e.target;
    const { clinicId } = this.props;
    const { billing } = this.state;

    const options = {
      method: 'PUT',
      body: {
        ...billing,
        invoicing_enabled: checked,
      },
    };

    apiFetch(`/clinics/${clinicId}/billing`, options)
      .then(() => {
        this.setState({ enabled: checked });
      })
      .catch(this.handleError);
  }

  render() {
    const { classes, clinic } = this.props;
    const {
      enabled,
      invoice,
      invoiceNotFound,
      month,
      year,
    } = this.state;

    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="invoicing" clinic={clinic} />
          <div className={classes.pageContent}>
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={<Switch checked={enabled} onChange={this.handleToggleInvoicing} name="invoicingEnabled" />}
                label={enabled ? 'Disable Invoicing' : 'Enable Invoicing'}
              />
            </FormGroup>
            {
              enabled && (
                <>
                  <div className={classes.datePickerContainer}>
                    <Box display="flex" alignItems="center">
                      <ArrowBackIosIcon onClick={this.handleClickPrevMonth} />
                      <div className={classes.dateText}>
                        {`${months[month]} ${year}`}
                      </div>
                      <ArrowForwardIosIcon onClick={this.handleClickNextMonth} />
                    </Box>
                  </div>
                  {invoiceNotFound ? (
                    <div className={classes.errorText}>Invoice data not found</div>
                  ) : null}
                  {invoice ? (
                    <Box display="flex" flexDirection="column" flexGrow={0}>
                      <div className={classes.totalsCard}>
                        <div className={`${classes.totalTime} ${classes.totalsSection}`}>
                          <div className={classes.greyText}>TOTAL</div>
                          <div className={classes.timeValue}>
                            {formattedCurrency.format(invoice.totals.cost)}
                          </div>
                        </div>
                        {invoice.type === 'perEvent' ? (
                          <>
                            <div className={classes.totalsSection}>
                              <div>Appointments</div>
                              <div className={classes.timeValue}>
                                {invoice.totals.appointments}
                              </div>
                            </div>
                            <div className={classes.totalsSection}>
                              <div>Pro Submissions</div>
                              <div className={classes.timeValue}>
                                {invoice.totals.proSubmissions}
                              </div>
                            </div>
                            <div className={classes.totalsSection}>
                              <div>Vitals</div>
                              <div className={classes.timeValue}>
                                {invoice.totals.vitals}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className={classes.totalsSection}>
                            <div>Patients</div>
                            <div className={classes.timeValue}>
                              {invoice.totals.patients}
                            </div>
                          </div>
                        )}
                      </div>
                      {invoice.type === 'perEvent' ? (
                        <Button
                          className={classes.exportButton}
                          onClick={() => exportPerEventInvoice(invoice, month, year)}
                          variant="contained"
                        >
                          Export Invoice
                        </Button>
                      ) : (
                        <Button
                          className={classes.exportButton}
                          onClick={() => exportPerPatientInvoice(invoice, month, year)}
                          variant="contained"
                        >
                          Export Invoice
                        </Button>
                      )}
                    </Box>
                  ) : null}
                </>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

ClinicInvoicing.propTypes = {
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { clinics } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ClinicInvoicing));
