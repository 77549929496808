import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Surgery from '../components/surgery';
import Header from '../components/header';
import Table from '../components/table';
import { browseSurgeries, editSurgery, addSurgery, deleteSurgery } from '../state/surgeries';

const baseStyles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  mainBody: {
    height: 'calc(100% - 120px)',
    maxHeight: '100%',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};

export class Surgeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddSurgery = this.handleAddSurgery.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    this.props.browseSurgeries();
  }
  startAdding() {
    this.setState({
      adding: true,
    });
  }
  handleAddSurgery(details) {
    return this.props.addSurgery(details);
  }

  handleCloseAdd() {
    this.setState({ adding: false });
  }
  render() {
    const { surgeries, router } = this.props;
    let addNewSection = (
      <Button
        onClick={this.startAdding}
        style={baseStyles.addBtn}
        variant="contained"
      >Add Surgery
      </Button>
    );

    const rows = map(surgeries.data, row => row);

    const cols = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'name', headerName: 'Name', width: 300 },
      { field: 'surgery_categories', headerName: 'Category', width: 300 },
      { field: 'snomed_code', headerName: 'Snomed Code', width: 300 },
      { field: 'icd_10_code', headerName: 'ICD 10 Code', width: 300 },
    ];

    if (this.state.adding) {
      addNewSection = (<Surgery
        handleSubmit={this.handleAddSurgery}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
      />);
    }

    const handleClick = ({ id }) => {
      router.push(`/surgeries/${id}`);
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={baseStyles.mainBody}>
          <Box style={baseStyles.addBtn}>{addNewSection}</Box>
          <div style={{ marginTop: 10, height: `${this.state.adding ? 80 : 100}%` }}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { surgeries } = state;
  return {
    surgeries,
  };
}

const actionCreators = {
  addSurgery,
  editSurgery,
  browseSurgeries,
  deleteSurgery,
};

Surgeries.defaultProps = {
  surgeries: {},
};

Surgeries.propTypes = {
  addSurgery: PropTypes.func.isRequired,
  browseSurgeries: PropTypes.func.isRequired,
  surgeries: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Surgeries);
