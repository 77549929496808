import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { apiFetch } from '../lib/fetch';

import Clinic from '../components/clinic';
import Header from '../components/header';
import Table from '../components/table';
import { browseCustomers } from '../state/customers';
import { browseClinics, addClinic } from '../state/clinics';

const baseStyles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  topSection: {
    margin: '15px auto',
    width: '950px',
  },
  mainBody: {
    height: 'calc(100% - 80px)',
    maxHeight: '100%',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
};

export class Clinics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddClinic = this.handleAddClinic.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    const { customers } = this.props;
    this.props.browseClinics();
    apiFetch('/sources').then((res) => {
      this.setState({ source_id: res });
    });
    if (!customers || !customers.length) {
      this.props.browseCustomers();
    }
  }
  startAdding() {
    this.setState({
      adding: true,
    });
  }
  handleAddClinic(details) {
    return this.props.addClinic(details);
  }
  handleCloseAdd() {
    this.setState({ adding: false });
  }
  render() {
    const { clinics, router } = this.props;
    let addNewSection = (
      <Button
        onClick={this.startAdding}
        variant="contained"
      >Add Clinic
      </Button>
    );

    if (this.state.adding) {
      addNewSection = (<Clinic
        customers={this.props.customers}
        source_id={this.state.source_id}
        handleSubmit={this.handleAddClinic}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
      />);
    }

    const rows = map(clinics.data, row => row);

    const cols = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'name', headerName: 'Name', width: 300 },
      { field: 'phone', headerName: 'Phone', width: 150 },
      { field: 'address', headerName: 'Address', width: 150 },
      { field: 'city', headerName: 'City', width: 150 },
      { field: 'state', headerName: 'State', width: 150 },
      { field: 'zip_code', headerName: 'Postal Code', width: 150 },
      { field: 'customer_name', headerName: 'Customer Name', width: 200 },
    ];

    const handleClick = ({ id }) => {
      router.push(`/clinics/${id}`);
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={{ maxHeight: '100%', height: `calc(100% - ${this.state.adding ? 100 : 120}px)` }}>
          <Box style={baseStyles.addBtn}>{addNewSection}</Box>
          <div style={{ marginTop: 10, height: `${this.state.adding ? 55 : 100}%` }}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { clinics, customers } = state;
  return {
    clinics,
    customers: map(customers.data || [], c => c),
  };
}

const actionCreators = {
  addClinic,
  browseClinics,
  browseCustomers,
};

Clinics.defaultProps = {
  clinics: {},
  customers: [],
};

Clinics.propTypes = {
  addClinic: PropTypes.func.isRequired,
  browseClinics: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  clinics: PropTypes.object,
  customers: PropTypes.array,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Clinics);
