import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, map } from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '../base/checkbox';
import HelloSign from '../lib/hellosign';
import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { browseClinicsIfNecessary } from '../state/clinics';
import {
  addReleaseFromAccount,
  browseReleases,
  removeRelease,
} from '../state/releases';
import { apiFetch } from '../lib/fetch';

const baseStyles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '125px',
  },
  checkbox: {
    top: '0px !important',
  },
  container: {
    width: '100%',
  },
  headerStyle: {
  },
  row: {
    cursor: 'pointer',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};


export class EsignReleases extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  UNSAFE_componentWillMount() {
    const { clinicId } = this.props;
    this.props.browseReleases(clinicId);
    this.props.browseClinicsIfNecessary();
    this.setState({ loading: true });
    apiFetch('/esign_templates')
      .then(({ data }) => {
        this.setState({ loading: false, releases: data });
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
    HelloSign.close();
  }
  handleToggleReleaseState = ({ title, template_id }, clinicRelease) => {
    if (clinicRelease) {
      return this.props.removeRelease(this.props.clinicId, clinicRelease.id);
    }
    const newAdditon = { name: title, hellosign_template_id: template_id };
    this.props.addReleaseFromAccount(this.props.clinicId, newAdditon);
  }
  render() {
    const { clinic, clinicReleases } = this.props;
    const { releases } = this.state;

    const rows = map(releases, (row) => {
      const clinicRelease = find(clinicReleases, { hellosign_template_id: row.template_id });
      return (
        <ListItem
          onClick={() => this.handleToggleReleaseState(row, clinicRelease)}
          style={{ cursor: 'pointer' }}
          key={row.template_id}
        >
          <Checkbox
            checked={Boolean(clinicRelease)}
          />
          <ListItemText>{row.title}</ListItemText>
        </ListItem>
      );
    });


    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          <ClinicSubheader currentPage="releases" clinic={clinic} />
          {this.state.loading && (
            <CircularProgress size={100} thickness={7} style={{ margin: '50px' }} />
          )}
          <List>
            {rows}
          </List>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics, releases } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId];

  return {
    clinic,
    clinicId, // in case clinic isn't loaded we can still fetch releases
    clinicReleases: releases.data,
  };
}

const actionCreators = {
  addReleaseFromAccount,
  browseClinicsIfNecessary,
  browseReleases,
  removeRelease,
};

EsignReleases.defaultProps = {
  clinic: {},
  clinicReleases: [],
};

EsignReleases.propTypes = {
  addReleaseFromAccount: PropTypes.func.isRequired,
  browseReleases: PropTypes.func.isRequired,
  browseClinicsIfNecessary: PropTypes.func.isRequired,
  clinic: PropTypes.object,
  clinicId: PropTypes.string.isRequired,
  clinicReleases: PropTypes.array,
  removeRelease: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(EsignReleases);
