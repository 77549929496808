import { promiseHandler } from 'cooldux';
import { difference, get, isEmpty, omit, omitBy, without } from 'lodash';
import queryString from 'query-string';

import { apiFetch } from '../lib/fetch';

const {
  browseStart, browseEnd, browseError, browseHandler,
} = promiseHandler('browse', 'users');
const { readStart, readEnd, readHandler } = promiseHandler('read', { namespace: 'users', throwErrors: true });
const { editEnd, editHandler } = promiseHandler('edit', { namespace: 'users', throwErrors: true });
const { addEnd, addError, addHandler } = promiseHandler('add', { namespace: 'users', throwErrors: true });
const { deleteEnd, deleteHandler } = promiseHandler('delete', { namespace: 'users', throwErrors: true });

export function browseUsers(queryObj) {
  return function dispatcher(dispatch) {
    const query = `&${queryString.stringify(queryObj)}`;
    const promise = apiFetch(`/users?exclude_patients=true${queryObj ? query : ''}`);
    return browseHandler(promise, dispatch);
  };
}

export function readUser(userId) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/users/${userId}`);
    return readHandler(promise, dispatch);
  };
}

export function editUser(update) {
  return function dispatcher(dispatch, getState) {
    const body = omit(update, ['id', 'roles']);
    const options = {
      method: 'PUT',
      body: omitBy(body, isEmpty),
    };

    const promise = apiFetch(`/users/${update.id}`, options)
      .then((userData) => {
        const currentRoles = get(getState(), ['users', 'data', update.id, 'roles']); // default to something?
        const newRoles = update.roles;
        const rolesToRemove = difference(currentRoles, newRoles);
        const rolesToAdd = difference(newRoles, currentRoles);

        const updates = [];

        rolesToRemove.forEach((role) => {
          const options = {
            method: 'DELETE',
            body: { role },
          };
          const promise = apiFetch(`/users/${update.id}/roles`, options)
            .catch(() => new Error('Error Updating Roles'));
          updates.push(promise);
        });

        rolesToAdd.forEach((role) => {
          const options = {
            method: 'POST',
            body: { role },
          };
          const promise = apiFetch(`/users/${update.id}/roles`, options)
            .catch(() => new Error('Error Updating Roles'));
          updates.push(promise);
        });

        return Promise.all(updates)
          .then(() => {
            userData.roles = newRoles;
            return userData;
          });
      });
    return editHandler(promise, dispatch);
  };
}


// Currently the roles are added after a user is created here
// Errors on adding roles are eaten and the role simply isn't
// added to the user.  Longterm we might want to rearchitect this
// To better report errors adding roles back to the admin
export function addUser(newUser) {
  return function dispatcher(dispatch) {
    const newUserData = omit(newUser, 'roles');
    const options = {
      method: 'POST',
      body: omitBy(newUserData, isEmpty),
    };
    let createdUser;
    const createdRoles = ['PATIENT']; // PATIENT is automatically added when creating a user
    const promise = apiFetch('/users', options)
      .then((res) => {
        createdUser = res;
        const additionalRoles = without(newUser.roles, 'PATIENT');

        const roleAdds = additionalRoles.map((role) => {
          const options = {
            method: 'POST',
            body: { role },
          };
          return apiFetch(`/users/${createdUser.id}/roles`, options)
            .then(({ role }) => {
              createdRoles.push(role);
            })
            .catch((e) => {
              // Worst case here is a role doesnt get added but the user does
              console.log('Error creating role', e);
            });
        });

        return Promise.all(roleAdds);
      })
      .then(() => {
        createdUser.roles = createdRoles;
        return createdUser;
      });
    return addHandler(promise, dispatch);
  };
}

export function deleteUser(userId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${userId}`, options)
      .then(() => userId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, users) {
  const data = {};
  users.forEach((u) => {
    data[u.id] = u;
  });
  return {
    ...state, data, isFetching: false, error: null,
  };
}

function finishRead(state, user) {
  const data = { ...state.data };
  data[user.id] = user;

  return {
    ...state, data, isFetching: false, error: null,
  };
}

function finishEdit(state, user) {
  const data = {
    ...state.data,
    [user.id]: Object.assign({}, state.data[user.id], user),
  };
  return { ...state, error: null, data };
}

function finishAdd(state, user) {
  const data = { ...state.data, [user.id]: user };
  return { ...state, error: null, data };
}

function finishDelete(state, userId) {
  return { ...state, data: omit(state.data, userId) };
}

function user(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    case readStart.type:
      return { ...state, isFetching: true };
    case readEnd.type:
      return finishRead(state, action.payload);
    default:
      return state;
  }
}

export default user;
