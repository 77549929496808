import { promiseHandler } from 'cooldux';
import { isEmpty, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'symptoms');
const { editEnd, editHandler } = promiseHandler('edit', 'symptoms');
const { addEnd, addError, addHandler } = promiseHandler('add', 'symptoms');
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'symptoms');

export function browseSymptoms() {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/symptoms');
    return browseHandler(promise, dispatch);
  };
}

export function editSymptom(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isEmpty),
    };
    const promise = apiFetch(`/symptoms/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addSymptom(newSymptom) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newSymptom, isEmpty),
    };
    const promise = apiFetch('/symptoms', options);
    return addHandler(promise, dispatch);
  };
}

export function deleteSymptom(symptomId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/symptoms/${symptomId}`, options)
      .then(() => symptomId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, symptoms) {
  const data = {};
  symptoms.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, symptom) {
  const data = { ...state.data, [symptom.id]: symptom };
  return { ...state, error: null, data };
}

function finishAdd(state, symptom) {
  const data = { ...state.data, [symptom.id]: symptom };
  return { ...state, error: null, data };
}

function finishDelete(state, symptomId) {
  return { ...state, data: omit(state.data, symptomId) };
}

function symptoms(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    default:
      return state;
  }
}

export default symptoms;
