import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, map } from 'lodash';
import { DatePicker, MenuItem, RaisedButton, SelectField } from 'material-ui';

const baseStyles = {
  container: {
    display: 'flex',
    padding: '25px',
    justifyContent: 'center',
  },
  searchBtn: {
    margin: '25px 10px 10px 10px',
  },
};

class ReportSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      now: new Date(),
    };
  }
  render() {
    const clinicOpts = map(this.props.clinics, c => <MenuItem key={`${c.id}-clinic`} value={c.id} primaryText={c.name} />);
    return (
      <div style={baseStyles.container}>
        <SelectField
          floatingLabelText="Clinic"
          floatingLabelFixed={true}
          onChange={this.props.onUpdateClinicId}
          value={this.props.clinic_id}
        >
          <MenuItem value={null} primaryText="All Clinics" />
          {clinicOpts}
        </SelectField>
        <DatePicker
          autoOk={true}
          hintText="Start Date"
          container="inline"
          mode="landscape"
          maxDate={this.props.end_date}
          onChange={this.props.onChangeStart}
          style={{ height: 50, marginTop: 25 }}
        />
        <DatePicker
          autoOk={true}
          hintText="End Date"
          container="inline"
          defaultDate={this.state.now}
          mode="landscape"
          minDate={this.props.start_date}
          maxDate={this.state.now}
          onChange={this.props.onChangeEnd}
          style={{ height: 50, marginTop: 25 }}
        />
        <RaisedButton
          label="Search"
          onClick={this.props.onSearch}
          style={baseStyles.searchBtn}
        />
      </div>
    );
  }
}

ReportSearchBar.defaultProps = {
  end_date: null,
  start_date: null,
  clinic_id: null,
  clinics: {},
  onChangeEnd: noop,
  onChangeStart: noop,
  onUpdateClinicId: noop,
  onSearch: noop,
};

ReportSearchBar.propTypes = {
  end_date: PropTypes.object,
  start_date: PropTypes.object,
  clinic_id: PropTypes.number,
  clinics: PropTypes.object,
  onChangeEnd: PropTypes.func,
  onChangeStart: PropTypes.func,
  onUpdateClinicId: PropTypes.func,
  onSearch: PropTypes.func,
};

export default ReportSearchBar
