import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, capitalize } from 'lodash';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Customer from '../components/customer';
import Header from '../components/header';
import Table from '../components/table';
import { browseCustomers, editCustomer, addCustomer, deleteCustomer } from '../state/customers';

const baseStyles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  topSection: {
    margin: '15px auto',
    width: '950px',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
};

export class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddCustomer = this.handleAddCustomer.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    this.props.browseCustomers();
  }
  startAdding() {
    this.setState({
      adding: true,
    });
  }
  handleAddCustomer(details) {
    return this.props.addCustomer(details);
  }
  handleCloseAdd() {
    this.setState({ adding: false });
  }
  render() {
    const { customers, router } = this.props;
    let addNewSection = (
      <Button
        onClick={this.startAdding}
        variant="contained"
      >Add Customer
      </Button>
    );
    const rows = map(customers.data, row => {
      return {...row, active: row.active ? 'Yes' : 'No'};
    });

    const cols = [
      { field: 'id', headerName: 'ID', width: 300 },
      { field: 'name', headerName: 'Name', width: 150 },
      { field: 'state', headerName: 'State', width: 150 },
      { field: 'zip_code', headerName: 'Zip Code', width: 150 },
      { field: 'phone', headerName: 'Phone', width: 150 },
      { field: 'active', headerName: 'Active', width: 150 },
    ];

    if (this.state.adding) {
      addNewSection = (<Customer
        handleSubmit={this.handleAddCustomer}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
      />);
    }

    const handleClick = ({ id }) => {
      router.push(`/customers/${id}`);
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={{ maxHeight: '100%', height: `calc(100% - ${this.state.adding ? 100 : 120}px)` }}>
          <Box style={baseStyles.addBtn}>{addNewSection}</Box>
          <div style={{ marginTop: 10, height: `${this.state.adding ? 70 : 100}%` }}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { customers } = state;
  return {
    customers,
  };
}

const actionCreators = {
  addCustomer,
  editCustomer,
  browseCustomers,
  deleteCustomer,
};

Customers.defaultProps = {
  customers: {},
};

Customers.propTypes = {
  addCustomer: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  customers: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Customers);
