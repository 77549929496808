import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePicker from 'material-ui/DatePicker';
import { SortableTable } from 'common-ui';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { apiFetch } from '../lib/fetch';
import { getFormattedDateFromTimestamp } from '../lib/dates';
import Header from '../components/header';

const styles = {
  fetchingIndicator: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tableTitle: {
    marginLeft: 10,
    marginTop: 10,
  },
  title: {
    fontWeight: 600,
    fontSize: '.75rem',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  searchBtn: {
    margin: '10px',
  },
  searchFilter: {
    display: 'flex',
    padding: '25px',
    justifyContent: 'center',
  },
  exportButtonWrapper: {
    display: 'flex',
    marginLeft: 50,
  },
};

const inboundMessagesColumns = [
  {
    id: 'dateSent',
    label: 'Date Sent',
  },
  {
    id: 'message',
    label: 'Message',
  },
  {
    id: 'from',
    label: 'Number',
  },
];

const erroredMessagesColumns = [
  {
    id: 'dateSent',
    label: 'Date Sent',
  },
  {
    id: 'errorCode',
    label: 'Error Code',
  },
  {
    id: 'errorMessage',
    label: 'Error Message',
  },
  {
    id: 'to',
    label: 'Number',
  },
];

const stopMessagesColumns = [
  {
    id: 'dateSent',
    label: 'Date Sent',
  },
  {
    id: 'from',
    label: 'Number',
  },
];

const buildResponseMessagesReport = (data) => {
  const title = 'Response messages';
  const header = 'Date,Phone,Email,Clinic,Message';
  const rows = [];

  data.forEach((row) => {
    const {
      dateSent,
      from,
      message,
    } = row;
    let { clinic, email } = row;
    clinic = clinic || '';
    email = email || '';

    rows.push(`${dateSent},${from},${email},${clinic},${message}`);
  });

  return [title, header, ...rows].join('\n');
};

const buildMessagesWithErrorsReport = (data) => {
  const title = 'Messages with errors';
  const header = 'Date,Phone,Email,Clinic,Error Code,Error Message,Landline';
  const rows = [];

  data.forEach((row) => {
    const {
      dateSent,
      errorCode,
      errorMessage,
      to,
    } = row;
    let { clinic, email, isLandline } = row;
    clinic = clinic || '';
    email = email || '';
    isLandline = isLandline ? 'yes' : 'no';

    rows.push(`${dateSent},${to},${email},${clinic},${errorCode},${errorMessage},${isLandline}`);
  });

  return [title, header, ...rows].join('\n');
};

const buildStopResponsesReport = (data) => {
  const title = 'STOP responses';
  const header = 'Date,Phone,Email,Clinic';
  const rows = [];

  data.forEach((row) => {
    const {
      dateSent,
      from,
    } = row;
    let { clinic, email } = row;
    clinic = clinic || '';
    email = email || '';

    rows.push(`${dateSent},${from},${email},${clinic}`);
  });

  return [title, header, ...rows].join('\n');
};

const buildCsvReport = (inboundMessages, erroredMessages, stopMessages) => {
  const responseMessagesReport = buildResponseMessagesReport(inboundMessages);
  const messagesWithErrorsReport = buildMessagesWithErrorsReport(erroredMessages);
  const stopResponses = buildStopResponsesReport(stopMessages);

  return [responseMessagesReport, messagesWithErrorsReport, stopResponses].join('\n\n');
};

const handleDownloadCsvReport = (inboundMessages, erroredMessages, stopMessages, dateBefore, dateAfter) => {
  const report = buildCsvReport(inboundMessages, erroredMessages, stopMessages);
  const formattedDateBefore = moment(dateBefore).format('YYYY-MM-DD');
  const formattedDateAfter = moment(dateAfter).format('YYYY-MM-DD');

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
  downloadTag.setAttribute('download', `messages_${formattedDateAfter}_${formattedDateBefore}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

class SmsMessages extends React.Component {
  constructor() {
    super();

    const dateBefore = new Date();
    const dateAfter = new Date(dateBefore.getTime());
    dateAfter.setDate(dateAfter.getDate() - 6);
    const today = new Date();

    this.state = {
      dateBefore,
      dateAfter,
      fetchingMessages: true,
      today,
      inboundMessages: [],
      erroredMessages: [],
      stopMessages: [],
    };

    this.getSmsMessages = this.getSmsMessages.bind(this);
    this.handleChangeDateAfter = this.handleChangeDateAfter.bind(this);
    this.handleChangeDateBefore = this.handleChangeDateBefore.bind(this);
    this.getSmsMessages();
  }

  getSmsMessages() {
    const { dateAfter, dateBefore } = this.state;

    // add a day to dateBefore to get messages sent on dateBefore
    const updatedDateBefore = new Date(dateBefore.getTime());
    updatedDateBefore.setDate(updatedDateBefore.getDate() + 1);

    const requestOptions = {
      params: {
        dateAfter: dateAfter.toISOString(),
        dateBefore: updatedDateBefore.toISOString(),
      },
    };

    this.setState({
      fetchingMessages: true,
      inboundMessages: [],
      erroredMessages: [],
      stopMessages: [],
    });

    apiFetch('/sms_messages', requestOptions)
      .then((data) => {
        const inboundMessagesFormatted = data.inboundMessages.map((message) => {
          return {
            ...message,
            dateSent: getFormattedDateFromTimestamp(message.dateSent),
          };
        });

        const erroredMessagesFormatted = data.erroredMessages.map((message) => {
          return {
            ...message,
            dateSent: getFormattedDateFromTimestamp(message.dateSent),
          };
        });

        const stopMessagesFormatted = data.stopMessages.map((message) => {
          return {
            ...message,
            dateSent: getFormattedDateFromTimestamp(message.dateSent),
          };
        });

        this.setState({
          fetchingMessages: false,
          inboundMessages: inboundMessagesFormatted,
          erroredMessages: erroredMessagesFormatted,
          stopMessages: stopMessagesFormatted,
        });
      })
      .catch(() => {
        this.setState({ fetchingMessages: false });
      });
  }

  handleChangeDateAfter(e, date) {
    this.setState({ dateAfter: date });
  }

  handleChangeDateBefore(e, date) {
    this.setState({ dateBefore: date });
  }

  render() {
    const { classes } = this.props;
    const {
      dateAfter,
      dateBefore,
      fetchingMessages,
      today,
      inboundMessages,
      erroredMessages,
      stopMessages,
    } = this.state;

    const exportDisabled = !inboundMessages.length && !erroredMessages.length && !stopMessages.length;

    return (
      <div>
        <Header />
        <div>
          <div className={classes.title}>Messages</div>
          <div className={classes.searchFilter}>
            <DatePicker
              autoOk={true}
              hintText="Start Date"
              container="inline"
              mode="landscape"
              maxDate={dateBefore}
              value={dateAfter}
              onChange={this.handleChangeDateAfter}
            />
            <DatePicker
              autoOk={true}
              hintText="End Date"
              container="inline"
              defaultDate={this.state.now}
              mode="landscape"
              minDate={dateAfter}
              maxDate={today}
              value={dateBefore}
              onChange={this.handleChangeDateBefore}
            />
            <Button
              disabled={fetchingMessages}
              onClick={this.getSmsMessages}
              className={classes.searchBtn}
              variant="contained"
            >
              Get Messages
            </Button>
            <div className={classes.exportButtonWrapper}>
              <Button
                disabled={exportDisabled}
                onClick={() => {
                  handleDownloadCsvReport(inboundMessages, erroredMessages, stopMessages, dateBefore, dateAfter);
                }}
                color="primary"
                variant="contained"
                className={classes.searchBtn}
              >
                Export
              </Button>
            </div>
          </div>
          {fetchingMessages ? (
            <CircularProgress className={classes.fetchingIndicator} />
          ) : (
            <>
              <div className={classes.tableTitle}>Response messages</div>
              <SortableTable
                columnData={inboundMessagesColumns}
                emptyMessage="There are no messages."
                rows={inboundMessages}
                handleClick={(row) => {
                  const number = encodeURIComponent(row.from);
                  this.props.router.push({
                    pathname: '/users-associated-with-number',
                    search: `?number=${number}`,
                  });
                }}
                hover={true}
              />
              <div className={classes.tableTitle}>Messages with errors</div>
              <SortableTable
                columnData={erroredMessagesColumns}
                emptyMessage="There are no messages."
                rows={erroredMessages}
                handleClick={(row) => {
                  const number = encodeURIComponent(row.to);
                  this.props.router.push({
                    pathname: '/users-associated-with-number',
                    search: `?number=${number}`,
                  });
                }}
                hover={true}
              />
              <div className={classes.tableTitle}>STOP responses</div>
              <SortableTable
                columnData={stopMessagesColumns}
                emptyMessage="There are no messages."
                rows={stopMessages}
                handleClick={(row) => {
                  const number = encodeURIComponent(row.from);
                  this.props.router.push({
                    pathname: '/users-associated-with-number',
                    search: `?number=${number}`,
                  });
                }}
                hover={true}
              />
            </>
          )}
        </div>
      </div >
    );
  }
}

SmsMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withStyles(styles)(SmsMessages);
