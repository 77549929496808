import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import { PainReportBarChart } from 'common-ui';

import { apiFetch } from '../lib/fetch';
import Header from '../components/header';
import Table from '../components/table';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  clinicsDropdown: {
    marginLeft: 10,
  },
  dataGrid: {
    height: '100%',
  },
  datePickerContainer: {
    display: 'flex',
  },
  dateText: {
    display: 'flex',
    fontWeight: 'bold',
    fontSize: 24,
    justifyContent: 'space-around',
    minWidth: 130,
  },
  pageContentContainer: {
    padding: 10,
    height: 'calc(100% - 110px)',
  },
  painReportsHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 20,
  },
  patientParticipationHeaderContainer: {
    marginBottom: 20,
  },
  patientParticipationSection: {
    marginTop: 30,
    height: 'calc(100% - 110px)',
  },
  patientParticipationText: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: 30,
  },
  tab: {
    minWidth: 130,
    padding: 0,
  },
  tabs: {
    display: 'inline-flex',
  },
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const shareHeaders = [
  {
    field: 'name', headerName: 'Name', width: 300,
  },
  {
    field: 'totalPatients', headerName: 'Total Patients', width: 150,
  },
];

const overviewColumns = [
  ...shareHeaders,
  {
    field: 'patientParticipationRate',
    headerName: 'Patient Participation Rate',
    width: 220,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const rateOne = cellParams1.api.getRow(cellParams1.id).patientParticipationRatePercent;
      const rateTwo = cellParams2.api.getRow(cellParams2.id).patientParticipationRatePercent;
      return rateOne - rateTwo;
    },
  },
  {
    field: 'prosResponseRate',
    headerName: 'PROs Response Rate',
    width: 200,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const rateOne = cellParams1.api.getRow(cellParams1.id).prosResponseRatePercent;
      const rateTwo = cellParams2.api.getRow(cellParams2.id).prosResponseRatePercent;
      return rateOne - rateTwo;
    },
  },
  {
    field: 'virtualVisitsRate',
    headerName: 'Virtual Visits',
    width: 200,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const rateOne = cellParams1.api.getRow(cellParams1.id).virtualVisitsRatePercent;
      const rateTwo = cellParams2.api.getRow(cellParams2.id).virtualVisitsRatePercent;
      return rateOne - rateTwo;
    },
  },
  {
    field: 'vitalsCount',
    headerName: 'Vitals Submissions',
    width: 200,
  },
];

const participationColumns = [
  ...shareHeaders,
  {
    field: 'patientParticipationRate',
    headerName: 'Patient Participation Rate',
    width: 220,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const rateOne = cellParams1.api.getRow(cellParams1.id).patientParticipationRatePercent;
      const rateTwo = cellParams2.api.getRow(cellParams2.id).patientParticipationRatePercent;
      return rateOne - rateTwo;
    },
  },
  {
    field: 'stop',
    headerName: 'Stopped Requests',
    width: 200,
    valueFormatter: (params) => {
      return params.value !== undefined ? params.value : '-';
    },
    sortable: false,
  },
  {
    field: 'start',
    headerName: 'Started Requests',
    width: 200,
    valueFormatter: (params) => {
      return params.value !== undefined ? params.value : '-';
    },
    sortable: false,
  },
];

const virtualVisitColumns = [
  ...shareHeaders,
  {
    field: 'virtualVisitsRate',
    headerName: 'Virtual Visits',
    width: 200,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      const rateOne = cellParams1.api.getRow(cellParams1.id).virtualVisitsRatePercent;
      const rateTwo = cellParams2.api.getRow(cellParams2.id).virtualVisitsRatePercent;
      return rateOne - rateTwo;
    },
  },
  {
    field: 'virtualVisitsCompleted', headerName: 'Completed', width: 150, sortable: false,
  },
  {
    field: 'virtualVisitsCancelled', headerName: 'Cancelled', width: 150, sortable: false,
  },
  {
    field: 'virtualVisitsMissed', headerName: 'Missed', width: 150, sortable: false,
  },
];

const getProCompletionRateColumns = (clinicOptions) => {
  const columns = clinicOptions.map((clinicOption) => {
    return { field: clinicOption.label, headerName: clinicOption.label, width: 250 };
  });
  columns.unshift({ field: 'rowType', headerName: ' ', width: 250 });
  return columns;
};

const getProCompletionRateRows = (proCompletionRateData) => {
  const rows = [];

  Object.keys(proCompletionRateData).forEach((proType) => {
    if (Object.keys(proCompletionRateData[proType]).length) {
      const currRow = { id: proType, rowType: proType };
      Object.keys(proCompletionRateData[proType]).forEach((clinic) => {
        const { completed, completion_rate, sent } = proCompletionRateData[proType][clinic];
        const completionRateStr = `${completion_rate}% | ${completed} of ${sent}`;
        const completionRatePercent = completion_rate;

        currRow[clinic] = completionRateStr;
        currRow[`${clinic}-percent`] = completionRatePercent;
      });

      rows.push(currRow);
    }
  });

  return rows;
};

const buildTableRows = (clinicData) => {
  const rows = [];

  Object.keys(clinicData).forEach((clinicId) => {
    const clinic = clinicData[clinicId];
    const participationRateStr = `${clinic.patient_participation_rate}% | ${clinic.patients_responded_to_pros} of ${clinic.patients_sent_pros}`; // eslint-disable-line max-len
    const proRateStr = `${clinic.pro_data.pro_response_rate}% | ${clinic.pro_data.completed} of ${clinic.pro_data.sent}`; // eslint-disable-line max-len
    const virtualVisitsRateStr = `${clinic.appointment_data.completion_rate}% | ${clinic.appointment_data.completed} of ${clinic.appointment_data.total}`; // eslint-disable-line max-len

    rows.push({
      name: clinic.name,
      totalPatients: clinic.num_of_patients,
      patientParticipationRatePercent: clinic.patient_participation_rate,
      patientParticipationRate: participationRateStr,
      prosResponseRate: proRateStr,
      prosResponseRatePercent: clinic.pro_data.pro_response_rate,
      painReports: clinic.pro_data.pain_reports,
      headacheReports: clinic.pro_data.headache_reports,
      id: clinicId,
      start: clinic.start,
      stop: clinic.stop,
      virtualVisitsRate: virtualVisitsRateStr,
      virtualVisitsRatePercent: clinic.appointment_data.completion_rate,
      virtualVisitsCompleted: clinic.appointment_data.completed,
      virtualVisitsCancelled: clinic.appointment_data.cancelled,
      virtualVisitsMissed: clinic.appointment_data.missed,
      vitalsCount: clinic.vitals_count,
    });
  });

  return rows;
};

const buildReportRows = (data, headerFields) => {
  return data.map((row) => {
    const rowArr = [];
    headerFields.forEach((header) => {
      rowArr.push(row[header]);
    });
    return rowArr.join(',');
  });
};

const buildOverviewReport = (data) => {
  const headerFields = overviewColumns.map(column => column.field);
  let headerNames = overviewColumns.map(column => column.headerName);
  const rows = buildReportRows(data, headerFields);
  headerNames = headerNames.join(',');
  return ['Overview', headerNames, ...rows].join('\n');
};

const buildParticipationReport = (data) => {
  const headerFields = participationColumns.map(column => column.field);
  let headerNames = participationColumns.map(column => column.headerName);
  const rows = buildReportRows(data, headerFields);
  headerNames = headerNames.join(',');
  return ['Participation', headerNames, ...rows].join('\n');
};

const buildVirtualVisitsReport = (data) => {
  const headerFields = virtualVisitColumns.map(column => column.field);
  let headerNames = virtualVisitColumns.map(column => column.headerName);
  const rows = buildReportRows(data, headerFields);
  headerNames = headerNames.join(',');
  return ['Virtual Visits', headerNames, ...rows].join('\n');
};

const buildProsReport = (proCompletionRateData, clinicOptions) => {
  const tableColumns = getProCompletionRateColumns(clinicOptions);
  const tableRows = getProCompletionRateRows(proCompletionRateData);
  const headerFields = tableColumns.map(column => column.field);
  let headerNames = tableColumns.map(column => column.headerName);
  const rows = buildReportRows(tableRows, headerFields);
  headerNames = headerNames.join(',');
  return ['PROs', headerNames, ...rows].join('\n');
};

const buildPainReportsReport = (painReportData) => {
  const headers = 'Clinic, Total Body, Total Headache, Body 0, Body 1, Body 2, Body 3, Body 4, Body 5, Body 6, Body 7, Body 8, Body 9, Body 10, Headache 0, Headache 1, Headache 2, Headache 3, Headache 4, Headache 5, Headache 6, Headache 7, Headache 8, Headache 9, Headache 10'; // eslint-disable-line max-len
  const rows = [];
  Object.keys(painReportData).sort().forEach((clinicName) => {
    const clinicData = painReportData[clinicName];
    const row = [clinicName, clinicData.totalBodyPain, clinicData.totalHeadache];
    clinicData.painReportData.forEach((painLevelData) => {
      row.push(painLevelData.bodyPainPercent);
    });
    clinicData.painReportData.forEach((painLevelData) => {
      row.push(painLevelData.headachePercent);
    });
    rows.push(row.join(','));
  });
  return ['Pain Reports', headers, ...rows].join('\n');
};

const buildCsvReport = (clinicKpisRows, proCompletionRateData, clinicOptions, painReportData) => {
  const overviewReport = buildOverviewReport(clinicKpisRows);
  const participationReport = buildParticipationReport(clinicKpisRows);
  const virtualVisitsReport = buildVirtualVisitsReport(clinicKpisRows);
  const prosReport = buildProsReport(proCompletionRateData, clinicOptions);
  const painReportsReport = buildPainReportsReport(painReportData);
  return [overviewReport, participationReport, virtualVisitsReport, prosReport, painReportsReport].join('\n,\n');
};

class Kpi extends React.Component {
  constructor(props) {
    super(props);

    const curDate = new Date();
    const startDate = moment(curDate).startOf('month').toDate();
    const endDate = moment(curDate).endOf('month').toDate();

    const curMonth = curDate.getMonth();
    const curYear = curDate.getFullYear();

    this.state = {
      curMonth,
      curYear,
      currentTab: 0,
      startDate,
      endDate,
      clinicOptions: [
        {
          value: 'All Clinics',
          label: 'All Clinics',
        },
      ],
      clinicOptionSelected: 'All Clinics',
      clinicKpisRows: [],
      clinicsDataObj: {},
      proCompletionRateData: null,
      painReportData: null,
      selectedPainReportData: null,
    };

    this.getPainReport();
    this.getClinics();
    this.getClinicKpis();
    this.getTwilioKpis();
  }

  getTwilioKpis = () => {
    const { startDate, endDate } = this.state;
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    apiFetch(`/twilio_kpi_rollup?start_time=${startDateString}&end_time=${endDateString}`)
      .then((twilioData) => {
        this.addTwilioKpis(twilioData);
      });
  }

  getClinicKpis = () => {
    const {
      startDate,
      endDate,
    } = this.state;

    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    apiFetch(`/kpi_rollup?start_time=${startDateString}&end_time=${endDateString}`)
      .then(({ clinicsData, proCompletionRateData }) => {
        this.setState({ proCompletionRateData });
        this.addClinicKpis(clinicsData);
      });
  }

  getClinics = () => {
    apiFetch('/clinics')
      .then((data) => {
        const clinicData = data.map(clinic => ({
          value: clinic.name,
          label: clinic.name,
        }));
        this.setState({ clinicOptions: [...this.state.clinicOptions, ...clinicData] });
      });
  }

  getPainReport = () => {
    const {
      startDate,
      endDate,
      clinicOptionSelected,
    } = this.state;
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    apiFetch(`/kpi_rollup_pain_report?start_time=${startDateString}&end_time=${endDateString}`)
      .then((data) => {
        this.setState({
          painReportData: data,
          selectedPainReportData: data[clinicOptionSelected],
        });
      });
  }

  addClinicKpis = (clinicsData) => {
    const { clinicsDataObj } = this.state;
    const newClinicKpisObj = {};

    Object.keys(clinicsDataObj).forEach((clinicId) => {
      newClinicKpisObj[clinicId] = clinicsDataObj[clinicId];
    });

    Object.keys(clinicsData).forEach((clinicId) => {
      if (!newClinicKpisObj[clinicId]) {
        newClinicKpisObj[clinicId] = {};
      }

      newClinicKpisObj[clinicId].name = clinicsData[clinicId].name;
      newClinicKpisObj[clinicId].num_of_patients = clinicsData[clinicId].num_of_patients;
      newClinicKpisObj[clinicId].patient_participation_rate = clinicsData[clinicId].patient_participation_rate;
      newClinicKpisObj[clinicId].patients_responded_to_pros = clinicsData[clinicId].patients_responded_to_pros;
      newClinicKpisObj[clinicId].patients_sent_pros = clinicsData[clinicId].patients_sent_pros;
      newClinicKpisObj[clinicId].pro_data = clinicsData[clinicId].pro_data;
      newClinicKpisObj[clinicId].appointment_data = clinicsData[clinicId].appointment_data;
      newClinicKpisObj[clinicId].vitals_count = clinicsData[clinicId].vitals_count;
    });

    const clinicKpisRows = buildTableRows(newClinicKpisObj);
    this.setState({ clinicsDataObj: newClinicKpisObj, clinicKpisRows });
  }

  addTwilioKpis = (twilioData) => {
    const { clinicsDataObj } = this.state;
    const newClinicKpisObj = {};

    Object.keys(clinicsDataObj).forEach((clinicId) => {
      newClinicKpisObj[clinicId] = clinicsDataObj[clinicId];
    });

    Object.keys(twilioData).forEach((clinicId) => {
      if (!newClinicKpisObj[clinicId]) {
        newClinicKpisObj[clinicId] = {};
      }

      newClinicKpisObj[clinicId].start = twilioData[clinicId].start;
      newClinicKpisObj[clinicId].stop = twilioData[clinicId].stop;
    });

    const clinicKpisRows = buildTableRows(newClinicKpisObj);
    this.setState({ clinicsDataObj: newClinicKpisObj, clinicKpisRows });
  }

  handleChangeCurrentTab = (event, value) => {
    this.setState({ currentTab: value });
  }

  handleChangeStartDate = (e, date) => {
    this.setState({
      startDate: date,
      clinicKpisRows: [],
      clinicsDataObj: {},
    }, () => {
      this.getPainReport();
      this.getClinicKpis();
      this.getTwilioKpis();
    });
  };

  handleChangeEndDate = (e, date) => {
    this.setState({
      endDate: date,
      clinicKpisRows: [],
      clinicsDataObj: {},
    }, () => {
      this.getPainReport();
      this.getClinicKpis();
      this.getTwilioKpis();
    });
  };

  handleChangeClinicOption = (e) => {
    const { painReportData } = this.state;
    let selectedPainReportData = painReportData[e.target.value];
    const clinicOptionSelected = e.target.value;
    if (!selectedPainReportData) {
      selectedPainReportData = {
        painReportData: [],
        totalBodyPain: 0,
        totalHeadache: 0,
      };
    }
    this.setState({ clinicOptionSelected, selectedPainReportData });
  }

  handleClickPrevMonth = () => {
    const { curMonth, curYear } = this.state;
    let prevMonth = curMonth - 1;
    let newCurYear = curYear;

    if (prevMonth < 0) {
      prevMonth = 11;
      newCurYear -= 1;
    }

    const curDate = moment({ year: newCurYear, month: prevMonth });
    const endDate = curDate.endOf('month').toDate();
    const startDate = curDate.startOf('month').toDate();

    this.setState({
      endDate,
      startDate,
      curMonth: prevMonth,
      curYear: newCurYear,
      clinicKpisRows: [],
      clinicsDataObj: {},
    }, () => {
      this.getPainReport();
      this.getClinicKpis();
      this.getTwilioKpis();
    });

    this.setState({ curMonth: prevMonth, curYear: newCurYear });
  }

  handleClickNextMonth = () => {
    const { curMonth, curYear } = this.state;
    let nextMonth = curMonth + 1;
    let newCurYear = curYear;

    if (nextMonth > 11) {
      nextMonth = 0;
      newCurYear += 1;
    }

    const curDate = moment({ year: newCurYear, month: nextMonth });
    const endDate = curDate.endOf('month').toDate();
    const startDate = curDate.startOf('month').toDate();

    this.setState({
      endDate,
      startDate,
      curMonth: nextMonth,
      curYear: newCurYear,
      clinicKpisRows: [],
      clinicsDataObj: {},
    }, () => {
      this.getPainReport();
      this.getClinicKpis();
      this.getTwilioKpis();
    });
  }

  handleDownloadReport = () => {
    const {
      clinicKpisRows,
      painReportData,
      proCompletionRateData,
      clinicOptions,
      curMonth,
      curYear,
    } = this.state;

    const report = buildCsvReport(clinicKpisRows, proCompletionRateData, clinicOptions, painReportData);
    let month = curMonth + 1;
    if (month < 10) month = '0' + month;
    const dateStr = `${curYear}-${month}`;

    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(report));
    downloadTag.setAttribute('download', `kpi_report_${dateStr}.csv`);
    downloadTag.style.display = 'none';

    document.body.appendChild(downloadTag);

    downloadTag.click();

    document.body.removeChild(downloadTag);
  }

  render() {
    const { classes } = this.props;
    const {
      curMonth,
      curYear,
      currentTab,
      clinicOptions,
      clinicOptionSelected,
      clinicKpisRows,
      proCompletionRateData,
      selectedPainReportData,
      painReportData,
    } = this.state;

    let tableColumns;
    let tableRows;
    if (currentTab === 0) {
      tableColumns = overviewColumns;
      tableRows = clinicKpisRows;
    } else if (currentTab === 1) {
      tableColumns = participationColumns;
      tableRows = clinicKpisRows;
    } else if (currentTab === 2) {
      if (clinicOptions && proCompletionRateData) {
        tableColumns = getProCompletionRateColumns(clinicOptions);
        tableRows = getProCompletionRateRows(proCompletionRateData);
      } else {
        tableColumns = [];
        tableRows = [];
      }
    } else {
      tableColumns = virtualVisitColumns;
      tableRows = clinicKpisRows;
    }

    const showDownloadReportButton = !!clinicKpisRows.length && !!painReportData && !!proCompletionRateData;

    return (
      <>
        <Header />
        <div className={classes.pageContentContainer}>
          <Box display="flex" justifyContent="space-between">
            <div className={classes.datePickerContainer}>
              <Box display="flex" alignItems="center">
                <ArrowBackIosIcon onClick={this.handleClickPrevMonth} />
                <div className={classes.dateText}>
                  {`${months[curMonth]} ${curYear}`}
                </div>
                <ArrowForwardIosIcon onClick={this.handleClickNextMonth} />
              </Box>
            </div>
            {showDownloadReportButton ? (
              <Button
                onClick={this.handleDownloadReport}
                startIcon={<SaveAltIcon />}
              >
                Download Report
              </Button>
            ) : null}
          </Box>
          <section className={classes.patientParticipationSection}>
            <div className={classes.patientParticipationHeaderContainer}>
              <Typography className={classes.patientParticipationText} component="h2">
                Patient Participation
              </Typography>
              <Tabs
                className={classes.tabs}
                value={currentTab}
                onChange={this.handleChangeCurrentTab}
              >
                <Tab label="Overview" className={classes.tab} />
                <Tab label="Participation" className={classes.tab} />
                <Tab label="PROs" className={classes.tab} />
                <Tab label="Virtual Visits" className={classes.tab} />
              </Tabs>
            </div>
            <div className={classes.dataGrid}>
              <Table rows={tableRows} cols={tableColumns} />
            </div>
          </section>
          {currentTab === 2 ? (
            <section>
              <div className={classes.painReportsHeaderContainer}>
                <Typography className={classes.bold} component="h2">Pain Reports</Typography>
                <FormControl
                  className={classes.clinicsDropdown}
                  variant="outlined"
                  margin="dense"
                >
                  <Select
                    value={clinicOptionSelected}
                    onChange={this.handleChangeClinicOption}
                  >
                    {clinicOptions.map(clinicOption => (
                      <MenuItem value={clinicOption.value} key={clinicOption.value}>
                        {clinicOption.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {selectedPainReportData ? (
                <PainReportBarChart
                  data={selectedPainReportData.painReportData}
                  totalBodyPain={selectedPainReportData.totalBodyPain}
                  totalHeadache={selectedPainReportData.totalHeadache}
                />
              ) : null}
            </section>
          ) : null}
        </div>
      </>
    );
  }
}

Kpi.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Kpi);
