import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { capitalize, find, reduce, reject } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import compareAlphabetically from '../lib/compare-alphabetically';
import Checkbox from '../base/checkbox';
import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { browseClinicsIfNecessary } from '../state/clinics';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '125px',
  },
  checkbox: {
    top: '0px !important',
  },
  container: {
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
  },
  error: {
    color: colors.errorRed,
  },
  row: {
    cursor: 'pointer',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};


export class Surgeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appSurgeries: [],
      availableSurgeries: [],
      availableSurgeriesLoading: false,
      clinicSurgeriesLoading: false,
      error: '',
    };
  }
  componentWillMount() {
    const { clinicId } = this.props;
    this.props.browseClinicsIfNecessary();
    this.setState({ availableSurgeriesLoading: true, clinicSurgeriesLoading: true });

    apiFetch('/surgery')
      .then((availableSurgeries) => {
        this.setState({
          availableSurgeriesLoading: false,
          availableSurgeries: availableSurgeries.sort((a, b) => compareAlphabetically(a.name, b.name)),
        });
      })
      .catch((e) => {
        this.setState({
          availableSurgeriesLoading: false,
          error: 'An Error has occured fetching available features',
        });
      });
    apiFetch(`/clinics/${clinicId}/surgeries`)
      .then((appSurgeries) => {
        this.setState({
          appSurgeries,
          clinicSurgeriesLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          clinicSurgeriesLoading: false,
          error: 'An error has occured fetching the clinic\'s features',
        });
      });
  }
  handleToggleFeature = (surgeryId, clinicFeature) => {
    const { clinicId } = this.props;
    this.setState({ error: '' });
    if (clinicFeature) {
      return apiFetch(`/clinics/${clinicId}/surgeries/${surgeryId}`, { method: 'DELETE' })
        .then(() => {
          this.setState((prevState) => {
            return {
              appSurgeries: reject(prevState.appSurgeries, { surgery_id: surgeryId }),
            };
          });
        })
        .catch(this.handleError);
    }
    const reqOpts = {
      method: 'POST',
      body: {
        surgery_id: surgeryId,
      },
    };
    return apiFetch(`/clinics/${clinicId}/surgeries`, reqOpts)
      .then((result) => {
        this.setState(prevState => ({
          appSurgeries: prevState.appSurgeries.concat(result),
        }));
      })
      .catch(this.handleError);
  }
  handleError = () => {
    this.setState({
      error: 'An error occured updating the clinic\'s features',
    });
  }
  render() {
    const { classes, clinic } = this.props;
    const {
      availableSurgeries, availableSurgeriesLoading, appSurgeries, clinicSurgeriesLoading, error,
    } = this.state;
    const rows = reduce(availableSurgeries, (list, surgery) => {

      if(surgery.id === 1) return list; // 'None Provided'
      const clinicSurgery = find(appSurgeries, { surgery_id: surgery.id });
      list.push(
        <ListItem
          key={surgery.id}
          className={classes.listItem}
          onClick={() => this.handleToggleFeature(surgery.id, clinicSurgery)}
        >
          <Checkbox
            checked={Boolean(clinicSurgery)}
            classes={{ root: classes.checkbox }}
          />
          <ListItemText>{capitalize(surgery.name.split('_').join(' '))}</ListItemText>
        </ListItem>
      );
      return list;
    }, []);

    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="surgeries" clinic={clinic} />
          {(availableSurgeriesLoading || clinicSurgeriesLoading) && (
            <CircularProgress size={100} thickness={7} style={{ margin: '50px' }} />
          )}
          {error && (
            <h2 className={classes.error}>{error}</h2>
          )}
          <List>
            {rows}
          </List>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics, releases } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId, // in case clinic isn't loaded we can still fetch releases
    clinicReleases: releases.data,
  };
}

Surgeries.propTypes = {
  browseClinicsIfNecessary: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { browseClinicsIfNecessary })(withStyles(styles)(Surgeries));
