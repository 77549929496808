import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import Symptom from '../components/symptom';
import {
  browseSymptoms, editSymptom,
} from '../state/symptoms';


const baseStyles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
  symptomStyle: {
    margin: '0px auto 25px',
    width: '650px',
  },
};

export class SingleSymptom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditSymptom = this.handleEditSymptom.bind(this);
  }
  componentWillMount() {
    const { symptom } = this.props;
    // Needs to be a read instead of browse
    // Currently this relies on having all users/symptoms/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    if (!symptom) {
      this.props.browseSymptoms();
    }
  }
  handleEditSymptom(details) {
    return this.props.editSymptom(details)
      .then(() => this.props.router.push('/symptoms'))
      .catch(() => {
        // Handle Error
      });
  }
  render() {
    const { symptom, isFetching } = this.props;
    let noSymptomMessage;
    if (!symptom && isFetching) {
      noSymptomMessage = <h1> Fetching </h1>;
    }

    if (!symptom && !isFetching) {
      noSymptomMessage = <h1> Not Found </h1>;
    }
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          { noSymptomMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{symptom.name}</h1>
              <Symptom
                symptom={symptom}
                handleSubmit={this.handleEditSymptom}
                showClose={false}
                submitText="Update Symptom"
                style={baseStyles.symptomStyle}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

// Currently this relies on having all users/symptoms/clinics prefetched
// Next iteration will do reads for these if they don't exist
function mapStateToProps(state, ownProps) {
  const { symptoms } = state;
  const symptom = symptoms.data[ownProps.routeParams.symptomId];

  return {
    symptom,
    isFetching: symptoms.isFetching,
  };
}

const actionCreators = {
  browseSymptoms,
  editSymptom,
};

SingleSymptom.defaultProps = {
  customers: [],
  isFetching: true,
  symptom: null,
};

SingleSymptom.propTypes = {
  browseSymptoms: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  editSymptom: PropTypes.func.isRequired,
  symptom: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(SingleSymptom);
