import 'isomorphic-fetch';
import React from 'react';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { render } from 'react-dom';
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { createGenerateClassName, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';

import store from './store';
import activityInitializer from './initializers/activity';
import LoginPage from './pages/login';
import UsersPage from './pages/users';
import AccessLogsPage from './pages/access-logs';
import CustomersPage from './pages/customers';
import ClinicsPage from './pages/clinics';
import DeleteUser from './pages/delete-user';
import FeaturesPage from './pages/features';
import ProvidersPage from './pages/providers';
import ReleasesPage from './pages/esign-releases';
import SingleCustomerPage from './pages/single-customer';
import SingleClinicPage from './pages/single-clinic';
import SingleProviderPage from './pages/single-provider';
import SingleUserPage from './pages/single-user';
import SinglePqrsPage from './pages/single-pqrs';
import SingleSurgeryPage from './pages/single-surgery';
import SingleSymptomPage from './pages/single-symptom';
import SingleConditionPage from './pages/single-condition';
import SingleReleasePage from './pages/single-release';
import SurgeriesPage from './pages/surgeries';
import SymptomsPage from './pages/symptoms';
import PqrsPage from './pages/pqrs';
import BodyPainReportPage from './pages/body-pain-report';
import ConditionsPage from './pages/conditions';
import HeadacheReportPage from './pages/clinical-headache-report';
import ClinicalHeadacheMedicationsReportPage from './pages/clinical-headache-medications-report';
import ActivityReportPage from './pages/activity-report';
import DemographicReportPage from './pages/demographic-report';
import EmrInstances from './pages/emr-instances';
import SingleInstancePage from './pages/single-instance';
import EmrTypes from './pages/emr-types';
import AddProvider from './pages/add-provider';
import ClinicSurgeriesPage from './pages/clinic-surgeries';
import ClinicProsPage from './pages/clinic-pros';
import SmsMessagesPage from './pages/sms-messages';
import UsersAssociatedWithNumber from './pages/users-associated-with-number';
import Kpi from './pages/kpi';
import ClinicBilling from './pages/clinic-billing';
import ClinicFilters from './pages/clinic-filters';
import ClinicFlags from './pages/clinic-flags';
import ClinicInvoicing from './pages/clinic-invoicing';
import ClinicDeletePatients from './pages/clinic-delete-patients';
import PasswordReset from './pages/password-reset';
import Ivc from './pages/ivc';
import { verifyUser } from './state/self';
import './index.css';
import theme from './lib/muiTheme';

const themeV0 = getMuiTheme({
  /* theme for v0.x */
  fontFamily: 'Calibri, Univers LT Std, sans-serif',
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'admin-app',
});

function requireAuth(nextState, replace, cb) {
  return store.dispatch(verifyUser())
    .then(() => cb())
    .catch(() => {
      replace({ pathname: '/' });
      cb();
    });
}

activityInitializer();

render(
  (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <V0MuiThemeProvider muiTheme={themeV0}>
            <Router history={browserHistory}>
              <Route path="/" component={LoginPage} />
              <Route path="/password-reset" component={PasswordReset} onEnter={requireAuth} />
              <Route path="/access-logs" component={AccessLogsPage} onEnter={requireAuth} />
              <Route path="/pqrs" component={PqrsPage} onEnter={requireAuth} />
              <Route path="/pqrs/:pqrsId" component={SinglePqrsPage} onEnter={requireAuth} />
              <Route path="/symptoms" component={SymptomsPage} onEnter={requireAuth} />
              <Route path="/symptoms/:symptomId" component={SingleSymptomPage} onEnter={requireAuth} />
              <Route path="/surgeries" component={SurgeriesPage} onEnter={requireAuth} />
              <Route path="/surgeries/:surgeryId" component={SingleSurgeryPage} onEnter={requireAuth} />
              <Route path="/conditions" component={ConditionsPage} onEnter={requireAuth} />
              <Route path="/conditions/:conditionId" component={SingleConditionPage} onEnter={requireAuth} />
              <Route path="/clinics" component={ClinicsPage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId" component={SingleClinicPage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/releases" component={ReleasesPage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/features" component={FeaturesPage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/surgeries" component={ClinicSurgeriesPage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/pros" component={ClinicProsPage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/releases/:releaseId" component={SingleReleasePage} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/filters" component={ClinicFilters} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/flags" component={ClinicFlags} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/billing" component={ClinicBilling} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/invoicing" component={ClinicInvoicing} onEnter={requireAuth} />
              <Route path="/clinics/:clinicId/deletePatients" component={ClinicDeletePatients} onEnter={requireAuth} />
              <Route path="/customers" component={CustomersPage} onEnter={requireAuth} />
              <Route path="/customers/:customerId" component={SingleCustomerPage} onEnter={requireAuth} />
              <Route path="/users" component={UsersPage} onEnter={requireAuth} />
              <Route path="/user" component={SingleUserPage} onEnter={requireAuth} />
              <Route path="/delete-user" component={DeleteUser} onEnter={requireAuth} />
              <Route path="/providers" component={ProvidersPage} onEnter={requireAuth} />
              <Route path="/add-provider" component={AddProvider} onEnter={requireAuth} />
              <Route path="/providers/:providerId" component={SingleProviderPage} onEnter={requireAuth} />
              <Route path="/reports/clinical-headache" component={HeadacheReportPage} onEnter={requireAuth} />
              <Route path="/reports/body-pain" component={BodyPainReportPage} onEnter={requireAuth} />
              <Route path="/kpi" component={Kpi} onEnter={requireAuth} />
              <Route
                path="/reports/clinical-headache-medications"
                component={ClinicalHeadacheMedicationsReportPage}
                onEnter={requireAuth}
              />
              <Route path="/reports/activity" component={ActivityReportPage} onEnter={requireAuth} />
              <Route path="/reports/demographic" component={DemographicReportPage} onEnter={requireAuth} />
              <Route path="/emr/instances" component={EmrInstances} onEnter={requireAuth} />
              <Route path="/emr/types/:typeId/instances/:instanceId" onEnter={requireAuth} component={SingleInstancePage} />
              <Route path="/emr/types" component={EmrTypes} onEnter={requireAuth} />
              <Route path="/sms-messages" component={SmsMessagesPage} onEnter={requireAuth} />
              <Route path="/users-associated-with-number" component={UsersAssociatedWithNumber} onEnter={requireAuth} />
              <Route path="/ivc" component={Ivc} onEnter={requireAuth} />
            </Router>
          </V0MuiThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  ), document.getElementById('root'),
);
