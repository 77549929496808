import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';

import { colors } from '../lib/styles';

const styles = {
  label: {
    color: colors.white,
  },
  btn: {
    backgroundColor: colors.primaryColor,
    borderRadius: '5px',
    color: colors.white,
    width: '400px',
  },
};

class SubmitButton extends Component {
  render() {
    let btnDisabled = {};

    if (this.props.disabled) {
      btnDisabled = {
        opacity: 0.5,
      };
    }

    const labelStyle = Object.assign({}, styles.label, this.props.labelStyle);
    const btnStyle = Object.assign({}, styles.btn, this.props.btnStyle);
    return (
      <FlatButton
        disabled={this.props.disabled}
        label={this.props.text}
        labelStyle={labelStyle}
        hoverColor={colors.highlightDarker}
        style={{ ...btnStyle, ...btnDisabled }}
        onClick={this.props.onSubmit}
      />
    );
  }
}

export default SubmitButton;
