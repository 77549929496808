import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardText } from 'material-ui';
import { capitalize } from 'lodash';

const baseStyles = {
  container: {
    minWidth: '300px',
    margin: '10px',
  },
  liTitle: {
    fontWeight: 600,
  },
  ul: {
    listStyle: 'none',
    padding: 0,
  },
  li: {
    padding: '0 5',
  },
};

class KeyedListOf extends Component {
  render() {
    const { items } = this.props;
    const keys = Object.keys(items);
    return (
      <Card style={baseStyles.container}>
        <CardTitle
          title={this.props.title}
        />
        <CardText>
          <ul style={baseStyles.ul}>
            {keys.map((k) => {
              return (
                <li key={k} style={baseStyles.li}>
                  <span style={baseStyles.liTitle}>{capitalize(k.replace(/_/, ' '))}</span> - {items[k]}
                </li>
              );
            })}
          </ul>
        </CardText>
      </Card>
    );
  }
}

KeyedListOf.defaultProps = {
  items: {},
  title: '',
};

KeyedListOf.propTypes = {
  items: PropTypes.object,
  title: PropTypes.string,
};

export default KeyedListOf;
