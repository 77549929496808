import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';

import NpiSearch from '../components/npi-search';

import Provider from '../components/provider';
import Header from '../components/header';
import { browseCustomers } from '../state/customers';
import { browseProviders, editProvider, addProvider, deleteProvider } from '../state/providers';

const baseStyles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '65px',
  },
  container: {
    width: '100%',
  },
  row: {
    cursor: 'pointer',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};

export class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProvider: null,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddProvider = this.handleAddProvider.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    const { customers } = this.props;
    this.props.browseProviders();
    if (!customers || !customers.length) {
      this.props.browseCustomers();
    }
  }
  startAdding() {
    this.setState({
      adding: true,
      selectedData: {},
    });
  }
  handleAddProvider(details) {
    return this.props.addProvider(details)
      .then(() => this.props.router.push('/providers'));
  }
  handleCloseAdd() {
    this.setState({ adding: false });
  }
  handleSelectProvider = (selectedProvider) => {
    this.setState({ selectedProvider });
  }
  render() {
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          {this.state.selectedProvider ?
            (<Provider
              customers={this.props.customers}
              handleSubmit={this.handleAddProvider}
              handleCloseAdd={this.handleCloseAdd}
              provider={this.state.selectedProvider}
              showClose={false}
              style={baseStyles.topSection}
            />) :
              (<NpiSearch
                onSelectProvider={this.handleSelectProvider}
              />)
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customers } = state;
  return {
    customers: map(customers.data || [], c => c)
  };
}

const actionCreators = {
  addProvider,
  editProvider,
  browseCustomers,
  browseProviders,
  deleteProvider,
};

Providers.defaultProps = {
  customers: [],
  providers: {},
};

Providers.propTypes = {
  addProvider: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  browseProviders: PropTypes.func.isRequired,
  customers: PropTypes.array,
  providers: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Providers);
