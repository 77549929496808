import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { forEach, find } from 'lodash';
import {
  CircularProgress,
  TextField,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import { SortableTable } from 'common-ui';

import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const baseStyles = {
  container: {
    width: '100%',
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1000,
    minWidth: 400,
    width: 'max-content',
  },
  newFlag: {
    marginBottom: '50px',
  },
  flagRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addBtn: {
    margin: '0 20px',
  },
  img: {
    marginLeft: '10px',
  },
  hide: {
    display: 'none',
  },
  priorityField: {
    marginLeft: 5,
    marginRight: 5,
    width: 60,
    '& input': {
      padding: 0,
    },
  },
  descriptionField: {
    marginLeft: 5,
    marginRight: 5,
    width: 200,
    '& input': {
      padding: 0,
    },
  },
  errorText: {
    color: colors.errorRed,
  },
};

const columnData = [
  {
    id: 'description', align: false, disablePadding: false, label: 'Description', small: false,
  },
  {
    id: 'priority', align: false, disablePadding: false, label: 'Priority', small: false,
  },
  {
    id: 'icon', align: false, disablePadding: false, label: 'Icon', small: true,
  },
  {
    id: 'edit', align: false, disablePadding: false, small: true, sortable: false,
  },
];

class ClinicFlags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      flags: [],
      fetchingFlags: true,
      priority: null,
      description: null,
      editDescription: '',
      editPriority: '',
      disableSave: true,
      error: false,
      edit: false,
      flagId: null,
    };

    apiFetch(`/clinics/${props.clinicId}/flags`)
      .then((data) => {
        this.setState({ flags: data, fetchingFlags: false });
      });
  }

  onFileChange = (e) => {
    let disableSave = true;
    if (this.state.priority !== null && this.state.description !== null) {
      disableSave = false;
    }
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        displayFile: reader.result,
        file: files[0],
        disableSave,
      });
    };
    reader.readAsDataURL(files[0]);
  }

  onChangeInput = (value, field) => {
    let cleanValue = value;
    let disableSave = true;
    // handle enabling save button after data is entered into the last field, but before state updates
    const elseButDescription = field === 'description' && this.state.priority !== null && this.state.file !== null;
    const elseButPriority = field === 'priority' && this.state.description !== null && this.state.file !== null;
    if (elseButDescription || elseButPriority) {
      disableSave = false;
    }
    // set priority of less than 1 back to 1
    if (field === 'priority') {
      cleanValue = value < 1 ? 1 : value;
    }
    this.setState({ [field]: cleanValue, disableSave });
  }

  saveEdit = () => {
    const reqOpts = {
      method: 'PUT',
      body: {
        description: this.state.editDescription,
        priority: this.state.editPriority,
      },
    };

    return apiFetch(`/clinics/${this.props.clinicId}/flags/${this.state.flagId}`, reqOpts)
      .then((res) => {
        const state = {
          ...this.state,
          editDescription: '',
          editPriority: '',
          error: false,
          edit: false,
          flagId: null,
        };
        // find flag and replace with updated version
        const flagIndex = find(state.flags, { id: res.id });
        state.flags[flagIndex] = res;
        this.setState(state);
      }).catch(() => {
        // show duplicate priority message
        this.setState({ error: true });
      });
  }

  startAdding = () => {
    const { file, priority, description } = this.state;
    const formData = new FormData();
    formData.append('icon', file);
    formData.append('priority', priority);
    formData.append('description', description);
    const reqOpts = {
      method: 'POST',
      body: formData,
    };
    return apiFetch(`/clinics/${this.props.clinicId}/flags`, reqOpts)
      .then((res) => {
        const state = {
          ...this.state,
          file: null,
          fetchingFlags: false,
          priority: null,
          description: null,
          disableSave: true,
          error: false,
        };
        state.flags.push(res);
        this.setState(state);
      }).catch(() => {
        // show duplicate priority message
        this.setState({ error: true });
      });
  }

  render() {
    const { classes, clinic } = this.props;
    const {
      flags,
      fetchingFlags,
    } = this.state;
    const flagRows = [];
    forEach(flags, (flag) => {
      const newFlag = {};
      const current = this.state.flagId === flag.id;
      // styles for show/hide based on editing mode
      const editStyle = this.state.edit && current ? baseStyles.hide : {};
      const saveStyle = this.state.edit && current ? {} : baseStyles.hide;
      newFlag.description = (
        <Fragment>
          <TextField
            className={classes.descriptionField}
            style={saveStyle}
            label="Description"
            InputLabelProps={{ shrink: true }}
            value={this.state.editDescription}
            onChange={(e) => { this.onChangeInput(e.target.value, 'editDescription'); }}
          />
          <div style={{...editStyle, width: 210}}>{flag.description}</div>
        </Fragment>
      );
      newFlag.priority = (
        <Fragment>
          <TextField
            className={classes.priorityField}
            style={saveStyle}
            name="priority"
            value={this.state.editPriority}
            onChange={(e) => { this.onChangeInput(e.target.value, 'editPriority'); }}
            type="number"
            label="Priority"
            InputLabelProps={{ shrink: true }}
          />
          <span style={editStyle}>{flag.priority}</span>
        </Fragment>
      );
      newFlag.icon = (
        <img
          alt="icon"
          height={40}
          src={flag.icon}
          style={baseStyles.img}
          width={40}
        />
      );
      newFlag.edit = (
        <Fragment>
          <EditIcon
            style={editStyle}
            onClick={() => this.setState({ flagId: flag.id, edit: true, editDescription: flag.description, editPriority: flag.priority })}
          />
          <Save style={saveStyle} onClick={() => this.saveEdit()} />
          <Close style={{ ...saveStyle, color: colors.errorRed }}
            onClick={() => {
              const state = {
                ...this.state,
                editDescription: '',
                editPriority: '',
                error: false,
                edit: false,
                flagId: null,
              };
              this.setState(state);
            }}
          />
        </Fragment>
      );
      flagRows.push(newFlag);
    });
    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="flags" clinic={clinic} />
          <div className={classes.pageContent}>
            <div className={classes.newFlag}>
              <h3>Add Flag</h3>
              <div className={classes.flagRow}>
                <TextField
                  className={classes.descriptionField}
                  label="Description"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.description}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'description'); }}
                />
                <TextField
                  className={classes.priorityField}
                  name="priority"
                  value={this.state.priority}
                  onChange={(e) => { this.onChangeInput(e.target.value, 'priority'); }}
                  type="number"
                  label="Priority"
                  InputLabelProps={{ shrink: true }}
                />
                {this.state.file && <img
                  alt="icon"
                  height={40}
                  src={this.state.displayFile}
                  style={baseStyles.img}
                  width={40}
                />}
                <input
                  accept="image/svg+xml"
                  type="file"
                  onChange={this.onFileChange}
                  id="file-upload-button"
                  style={baseStyles.hide}
                />
                <label htmlFor="file-upload-button">
                  <Button className={classes.addBtn} variant="contained" component="span">
                    Upload Icon
                  </Button>
                </label>
                <Button
                  onClick={this.startAdding}
                  className={classes.addBtn}
                  variant="contained"
                  disabled={this.state.disableSave}
                >Save
                </Button>
              </div>

            </div>
            {!fetchingFlags ? (
              <Fragment>
                {this.state.error ? (
                  <div className={classes.errorText}>This priority level is already assigned.</div>
                ) : null}
                <SortableTable
                  columnData={columnData}
                  emptyMessage="There are no flags."
                  rows={flagRows}
                />
              </Fragment>
            ) : <CircularProgress />}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId,
  };
}

ClinicFlags.propTypes = {
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withStyles(baseStyles)(ClinicFlags));
