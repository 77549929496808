import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardText } from 'material-ui';
import { VictoryChart, VictoryScatter, VictoryTheme } from 'victory';

const baseStyles = {
  container: {
    minWidth: '300px',
    margin: '10px',
  },
  liTitle: {
    fontWeight: 600,
  },
};

class ScatterPlotReport extends Component {
  render() {
    const { data } = this.props;
    return (
      <Card style={baseStyles.container}>
        <CardTitle
          title={this.props.title}
        />
        <CardText>
          <VictoryChart
            theme={VictoryTheme.material}
            domain={{ x: [0, this.props.maxX], y: [0, this.props.maxY] }}
          >
            <VictoryScatter
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 },
              }}
              bubbleProperty="z"
              maxBubbleSize={25}
              minBubbleSize={5}
              size={7}
              data={data}
            />
          </VictoryChart>
        </CardText>
      </Card>
    );
  }
}

ScatterPlotReport.defaultProps = {
  data: [],
  maxY: 100,
  maxX: 100,
  title: '',
  xAxis: '',
  yAxis: '',
};

ScatterPlotReport.propTypes = {
  data: PropTypes.array,
  maxX: PropTypes.number,
  maxY: PropTypes.number,
  title: PropTypes.string,
};

export default ScatterPlotReport;
