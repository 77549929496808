import { throttle } from 'lodash';
import { logout } from '../state/self';
import store from '../store';

const logoutTime = 15 * 60000; // 15 minutes
const throttleTime = 1500;
const inactiveLogoutMsg = 'You have been logged out due to inactivity. Please log back in';
let lastActivity;
let lastActivityInterval;

function setLastActivity() {
  lastActivity = Date.now();
}

function checkForExpiredTimestamp() {
  if (Date.now() - lastActivity >= logoutTime) {
    const { loggedIn } = store.getState().self;

    if (loggedIn) {
      store.dispatch(logout(inactiveLogoutMsg));
    }
  }
}

export const throttledSetLastActivity = throttle(setLastActivity, throttleTime, {
  leading: true,
  trailing: true,
});

export default function activityInitializer() {
  lastActivityInterval = setInterval(checkForExpiredTimestamp, 2000);

  window.onload = throttledSetLastActivity;
  document.onmousemove = throttledSetLastActivity;
  document.onmousedown = throttledSetLastActivity;
  document.ontouchstart = throttledSetLastActivity;
  document.onclick = throttledSetLastActivity;
  document.onscroll = throttledSetLastActivity;
  document.onkeypress = throttledSetLastActivity;
}
