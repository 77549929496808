import { promiseHandler } from 'cooldux';
import { isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'pqrs');
const { editEnd, editHandler } = promiseHandler('edit', 'pqrs');
const { addEnd, addError, addHandler } = promiseHandler('add', 'pqrs');
const { deleteEnd, deleteHandler } = promiseHandler('delete', 'pqrs');

export function browsePqrs() {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/pqrs');
    return browseHandler(promise, dispatch);
  };
}

export function editPqrs(update) {
  return function dispatcher(dispatch) {
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/pqrs/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addPqrs(newPqrs) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'POST',
      body: omitBy(newPqrs, isUndefined),
    };
    const promise = apiFetch('/pqrs', options);
    return addHandler(promise, dispatch);
  };
}

export function deletePqrs(pqrsId) {
  return function dispatcher(dispatch) {
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/pqrs/${pqrsId}`, options)
      .then(() => pqrsId);

    return deleteHandler(promise, dispatch);
  };
}


const initialState = {
  error: null,
  isFetching: false,
  data: {},
};

function finishBrowse(state, pqrs) {
  const data = {};
  pqrs.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, pqrs) {
  const data = { ...state.data, [pqrs.id]: pqrs };
  return { ...state, error: null, data };
}

function finishAdd(state, pqrs) {
  const data = { ...state.data, [pqrs.id]: pqrs };
  return { ...state, error: null, data };
}

function finishDelete(state, pqrsId) {
  return { ...state, data: omit(state.data, pqrsId) };
}

function pqrs(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isFetching: false, error: action.payload };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case editEnd.type:
      return finishEdit(state, action.payload);
    default:
      return state;
  }
}

export default pqrs;
