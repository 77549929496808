
export const isPasswordValid = (oldPassword, newPassword, confirmPassword) => {
  const err = {};

  if (!oldPassword) {
    err.oldPassword = 'Please enter your old password';
  }

  if (!newPassword) {
    err.newPassword = 'Please enter your new password';
  }

  if (!confirmPassword) {
    err.confirmPassword = 'Please confirm your new password';
  }

  if (Object.keys(err).length === 0) {
    if (newPassword !== confirmPassword) {
      err.newPassword = 'Passwords do not match';
      err.confirmPassword = 'Passwords do not match';
    } else if (newPassword.length <= 12) {
      err.newPassword = 'Password must be at least 12 characters long';
    } else if (newPassword === oldPassword) {
      err.newPassword = 'New password must be different from old password';
    } else if (newPassword.search(/\d/) === -1) {
      err.newPassword = 'Password must contain at least one number';
    } else if (newPassword.search(/[A-Z]/) === -1) {
      err.newPassword = 'Password must contain at least one uppercase letter';
    } else if (newPassword.search(/[a-z]/) === -1) {
      err.newPassword = 'Password must contain at least one lowercase letter';
    } else if (newPassword.search(/[!@#$%^&*]/) === -1) {
      err.newPassword = 'Password must contain at least one of the following special characters: !@#$%^&*';
    } else if (newPassword.search(/\s/) !== -1) {
      err.newPassword = 'Password cannot contain spaces';
    }
  }

  return err;
};
