import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import { assign, forEach, noop } from 'lodash';

import { stateAbbrevs } from '../lib/state-abbrev';
import { colors } from '../lib/styles';

const baseStyles = {
  blueText: {
    color: colors.primaryColor,
  },
  selectStyle: {
    marginTop: 25,
    width: 110,
  },
};

const items = [];
forEach(stateAbbrevs, (stateAbbrev) => {
  items.push(<MenuItem value={stateAbbrev} key={stateAbbrev} primaryText={stateAbbrev} />);
});

class State extends Component {
  render() {
    const selectStyle = assign({}, baseStyles.selectStyle, this.props.style);
    return (
      <SelectField
        value={this.props.value}
        onChange={this.props.onChange}
        maxHeight={200}
        style={selectStyle}
        labelStyle={baseStyles.blueText}
        underlineStyle={{ display: 'none' }}
      >
        <MenuItem value="none" primaryText="State" />
        {items}
      </SelectField>
    );
  }
}

State.defaultProps = {
  value: 'none', // State Abbreviation ie 'AZ'
  onChange: noop,
  style: {},
};

State.propTypes = {
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default State;
