import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Header from '../components/header';
import Surgery from '../components/surgery';
import {
  browseSurgeries, editSurgery,
} from '../state/surgeries';


const baseStyles = {
  container: {},
  headerStyle: {
    textAlign: 'center',
  },
  mainBody: {
    margin: '10px',
  },
  surgeryStyle: {
    margin: '0px auto 25px',
    width: '650px',
  },
};

export class SingleSurgery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
    this.handleEditSurgery = this.handleEditSurgery.bind(this);
  }
  componentDidMount() {
    const { surgery } = this.props;
    // Needs to be a read instead of browse
    // Currently this relies on having all users/surgerys/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    if (!surgery) {
      this.props.browseSurgeries();
    }
  }
  handleEditSurgery(details) {
    return this.props.editSurgery(details)
      .then(() => {})
      .catch(() => {
        // Handle Error
      });
  }
  render() {
    const { surgery, isFetching } = this.props;
    let noSurgeryMessage;

    if (!surgery && isFetching) {
      noSurgeryMessage = <h1> Fetching </h1>;
    }

    if (!surgery && !isFetching) {
      noSurgeryMessage = <h1> Not Found </h1>;
    }
    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          {noSurgeryMessage ||
            <div>
              <h1 style={baseStyles.headerStyle}>{surgery.name}</h1>
              <Surgery
                surgery={surgery}
                handleSubmit={this.handleEditSurgery}
                showClose={false}
                submitText="Update Surgery"
                style={baseStyles.surgeryStyle}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

// Currently this relies on having all users/surgeries/clinics prefetched
// Next iteration will do reads for these if they don't exist
function mapStateToProps(state, ownProps) {
  const { surgeries } = state;
  const surgery = surgeries.data[ownProps.routeParams.surgeryId];

  return {
    surgery,
    isFetching: surgeries.isFetching,
  };
}

const actionCreators = {
  browseSurgeries,
  editSurgery,
};

SingleSurgery.defaultProps = {
  isFetching: true,
  surgery: null,
};

SingleSurgery.propTypes = {
  browseSurgeries: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  editSurgery: PropTypes.func.isRequired,
  surgery: PropTypes.object,
};

export default connect(mapStateToProps, actionCreators)(SingleSurgery);
