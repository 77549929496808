/* eslint-disable no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forEach, pick, includes, findIndex } from 'lodash';
import { Button, Dialog, DialogContent, FilledInput } from '@material-ui/core';
import Joi from '@hapi/joi';
import * as csv from 'csvtojson';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import HiddenContent from '../components/hidden-content';
import Loading from '../components/loading';

const styles = {
  errorMsg: {
    color: colors.errorRed,
    marginTop: '10px',
  },
  fileTypeErrorMsg: {
    color: colors.errorRed,
    textAlign: 'center',
  },
  populatedPatientList: {
    minHeight: '100px',
    maxHeight: '350px',
    marginBlockStart: '0px',
    marginBlockEnd: '10px',
    overflowY: 'scroll',
    padding: '0 50px',
  },
  patientList: {
    maxHeight: '300px',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  subHeader: {
    textAlign: 'center',
    width: '540px',
  },
  buttonsRow: {
    margin: '10px 0',
  },
};

const validationSchema = Joi.object({
  user_id: Joi.string().guid().required().messages({
    'string.empty': 'user id required',
  }),
  birth_date: Joi.date().iso().required().messages({
    'string.empty': 'birth_date required',
    'date.base': 'invalid birth_date',
  }),
  email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages({
    'string.empty': 'email required',
    'string.email': 'invalid email',
  }),
});

// \uFFFD is the character code for �, which is used in place of characters that the browser does not recognize
const hasUnknownCharacter = (characters) => {
  if (typeof characters === 'string') {
    return /\uFFFD/.test(characters);
  }

  return characters.some((str) => {
    return /\uFFFD/.test(str);
  });
};

const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY');
};

const initialState = {
  allValid: true,
  imports: [],
  submissionError: '',
  submissionResults: [],
  requestedPro: [],
  proSuccess: false,
  campaign: 'DEFAULT',
  campaign_start: '',
  hidden: true,
  error: '',
  fileReadingError: '',
  deleteEnd: false,
  bulkInviteEventType: null,
  bulkInviteProData: null,
  bulkInviteProWarning: null,
  verified: false,
  password: '',
  token: '',
  openVerify: false,
  loading: false,
};

// eslint-disable-next-line max-len
const defaultError = 'An error occured submitting this list, please review your import file carefully to ensure and try again. If this problem persists contact support.'

const PreImportRow = (props) => {
  return (
    <li style={props.isValid ? {} : { color: colors.errorRed }}>
      {props.user_id} {props.first_name} {props.last_name}, {formatDate(props.birth_date)}{props.error && `, ${props.error}`}
    </li>
  );
};

const PostImportRow = (props) => {
  const style = includes(props.delete_status, 'FAILED') ? { color: colors.errorRed } : {};

  return (
    <li style={style}>
      {props.user_id} {props.first_name} {props.last_name},   {props.delete_status}{props.error && `, Error: ${props.error}`}
    </li>
  );
};

const inputStyles = {
  root: {
    color: '#edeef0',
    background: '#207bcc',
    margin: '10px',
  },
  input: {
    background: colors.primaryColor,
    color: colors.highlightBright,
    padding: '27px 12px',
    '&::placeholder': {
      color: colors.white,
      fontSize: '18px',
      margin: '5px 15px',
      opacity: 1,
    },
  },
};
const LoginTextField = withStyles(inputStyles)((props) => {
  return (
    <FilledInput
      disableUnderline
      fullWidth={true}
      variant="filled"
      onKeyDown={props.onKeyDown}
      {...props}
    />
  );
});

class ClinicInvoicing extends Component {
  state = {
    ...initialState,
  }

  onChangeFile = (e) => {
    e.persist();

    this.setState({
      fileReadingError: '',
    });

    const { files } = e.target;
    // Forces onChange to get called again if same file is resubmitted
    if (files && files.length) {
      this.setState({ ...initialState, allValid: true, error: '' }, () => {
        forEach(files, (f) => {
          if (f.type !== 'text/csv') {
            this.setState({ error: 'Only .csv files can be uploaded', allValid: false });
          } else {
            const reader = new FileReader();
            reader.onload = readerEvent => this.handleAddFileContentsToState(readerEvent.target.result);
            reader.readAsText(f);
          }
        });
      });
    }
  }

  handleAddFileContentsToState = (fileContents) => {
    this.setState({ loading: true })
    const { clinicId } = this.props;
    const fileHasUnknownCharacter = hasUnknownCharacter(fileContents);
    csv({
      noheader: false,
      headers: ['user_id', 'first_name', 'last_name', 'email', 'birth_date'],
    }).fromString(fileContents)
      .subscribe((patient) => {
        return new Promise(async (resolve) => {
          if (patient.first_name === 'first_name') return resolve();
          patient.user_id = patient.user_id.trim();
          patient.first_name = patient.first_name.trim();
          patient.last_name = patient.last_name.trim();
          patient.birth_date = moment(patient.birth_date).toISOString('keepOffset');
          patient.email = patient.email.trim().toLowerCase();
          patient.isValid = true;

          const errors = [];

          if (fileHasUnknownCharacter) {
            const unknownCharacterFound = hasUnknownCharacter([patient.email]);
            if (unknownCharacterFound) {
              errors.push('unknown character');
            }
          }

          const validationResult = validationSchema.validate(patient, { abortEarly: false, allowUnknown: true });
          if (validationResult.error) {
            validationResult.error.details.forEach((error) => {
              errors.push(error.message);
            });
          }

          if (errors.length) {
            patient.isValid = false;
            patient.error = errors.join('; ');
            this.setState({
              // eslint-disable-next-line max-len
              fileReadingError: 'Errors found in csv upload. If unknown characters were found, please ensure the file is UTF-8 encoded.',
              allValid: false,
            });
          }
          resolve();
        });
      }).then(async (p) => {
        const options = {
          method: 'POST',
          body: { patients: p.map(i => pick(i, ['birth_date', 'email', 'user_id'])) },
        };

        await apiFetch(`/clinics/${clinicId}/verify_patients`, options)
          .then((res) => {
            const newState = {};
            newState.imports = [];
            res.map((i) => {
              const pIndex = findIndex(p, ['user_id', i.user_id]);
              if (i.error) {
                p[pIndex].isValid = false;
                p[pIndex].error = `${p[pIndex].error || ''} ${i.error}`;
                // eslint-disable-next-line max-len
                newState.fileReadingError = 'Errors found in csv upload. If unknown characters were found, please ensure the file is UTF-8 encoded.';
                newState.allValid = false;
                return newState.imports.unshift(p[pIndex]);
              }
              return newState.imports.push(p[pIndex]);
            });
            this.setState({...newState, loading: false });
          });
      });
  }

  handleDownloadImports = (exportList) => {
    const header = 'User ID, First Name, Last Name, DOB, Email, Error(s), Delete Status';
    const rows = [header];

    exportList.forEach((patient) => {
      const {
        user_id,
        first_name,
        last_name,
        email,
        birth_date,
        error,
        delete_status,
      } = patient;
      // eslint-disable-next-line max-len
      rows.push(`${user_id},${first_name},${last_name},${formatDate(birth_date)},${email}${error ? `,${error}` : ','}${delete_status ? `,${delete_status}` : ',N/A'}`);
    });

    const csvReport = rows.join('\n');
    this.handleStartDownload(csvReport);
  }

  handleStartDownload = (csvData) => {
    const dateStr = moment();

    const downloadTag = document.createElement('a');
    downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvData));
    downloadTag.setAttribute('download', `bulk_delete_${dateStr}.csv`);
    downloadTag.style.display = 'none';

    document.body.appendChild(downloadTag);

    downloadTag.click();

    document.body.removeChild(downloadTag);
  }

  handleSubmit = () => {
    if (this.state.verified) {
      return this.handleDelete();
    }
    this.setState({ openVerify: true });
  }

  handleVerify = async () => {
    const options = {
      method: 'POST',
      body: {
        password: this.state.password,
      },
    };
    try {
      const auth = await apiFetch('/auth/admin', options);
      this.setState({
        token: auth.token,
        verified: true,
        openVerify: false,
        submissionError: '',
      });
    } catch {
      this.setState({
        verified: false,
        openVerify: true,
        submissionError: 'An error occured in verifying user. Check password and resubmit.',
      });
    }
  }

  handleDelete = () => {
    this.setState({ loading: true });
    const { clinicId } = this.props;
    const { token } = this.state;
    const options = {
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: {
        patients: this.state.imports.map(i => pick(i, ['user_id', 'first_name', 'last_name', 'birth_date', 'email'])),
      },
    };

    apiFetch(`/clinics/${clinicId}/delete_patients`, options)
      .then((response) => {
        this.setState({
          submissionResults: response.data,
          submissionError: '',
          deleteEnd: true,
          loading: false,
        });
      })
      .catch((err) => {
        const message = includes(err.message, 'Unauthorized') ? 'Authorization has expired.' : defaultError;
        this.setState({
          submissionError: message,
          verified: false,
          deleteEnd: true,
          loading: false,
        });
      });
  }

  handleClearPreviousFile = (e) => {
    e.target.value = null;
  }

  render() {
    const { classes, clinic } = this.props;
    const {
      fileReadingError,
      submissionResults,
      deleteEnd,
      verified,
      openVerify,
      password,
      submissionError,
      loading,
      allValid,
    } = this.state;
    let patientRows;
    let exportList;
    if (submissionResults.length > 0) {
      exportList = submissionResults;
      patientRows = submissionResults.map((i, idx) => <PostImportRow key={i.user_id} {...i} number={idx + 1} />);
    } else {
      exportList = this.state.imports;
      patientRows = this.state.imports.map((i, idx) => <PreImportRow key={i.user_id} {...i} number={idx + 1} />);
    }

    return (
      <div className={classes.container}>
        <Dialog
          maxWidth="lg"
          aria-labelledby="simple-dialog-title"
          open={openVerify}
        >
          <DialogContent>
            Enter Password to Continue
            <LoginTextField
              key="password"
              placeholder="Password"
              onChange={e => this.setState({ password: e.target.value })}
              type="password"
              value={password}
            />
            <Button
              disabled={!password}
              color="primary"
              variant="contained"
              onClick={this.handleVerify}
            >
              Continue
            </Button>
            {submissionError && submissionError}
          </DialogContent>
        </Dialog>
        <Header />
        <HiddenContent hidden={!loading}>
          <Loading style={{ position: 'absolute', zIndex: 1 }} />
        </HiddenContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ClinicSubheader currentPage="deletePatients" clinic={clinic} />
          <h1>Bulk Delete Patients</h1>
          <p className={classes.errorMsg}>Warning: this function is irreversible</p>
          <Button
            variant="contained"
            color="secondary"
            component="label"
            disabled={loading}
          >
            Upload File
            <input
              onClick={this.handleClearPreviousFile}
              onChange={this.onChangeFile}
              type="file"
              style={{ display: 'none' }}
            />
          </Button>
          <HiddenContent hidden={loading || loading}>
            <div className={classes.fileTypeErrorMsg}>{this.state.error}</div>
          </HiddenContent>
          <h2 className={classes.subHeader}>Patients to Delete</h2>
          <ol className={patientRows.length ? classes.populatedPatientList : classes.patientList}>
            {patientRows}
          </ol>
          <div className={classes.buttonsRow}>
            <Button
              disabled={!this.state.allValid || Boolean(submissionResults.length || loading)}
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              {verified ? 'Delete Patients' : 'Continue'}
            </Button>
          </div>
          <HiddenContent hidden={!this.state.submissionError}>
            <span className={classes.errorMsg}>{this.state.submissionError}</span>
          </HiddenContent>
          <HiddenContent hidden={!fileReadingError}>
            <span className={classes.errorMsg}>{fileReadingError}</span>
          </HiddenContent>
          {deleteEnd || !allValid ? (
            <div className={classes.buttonsRow}>
              <Button
                onClick={() => this.handleDownloadImports(exportList)}
                variant="contained"
              >
                Export
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

ClinicInvoicing.propTypes = {
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { clinics } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ClinicInvoicing));
