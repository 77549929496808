import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Symptom from '../components/symptom';
import Header from '../components/header';
import Table from '../components/table';
import { browseCustomers } from '../state/customers';
import { browseSymptoms, editSymptom, addSymptom, deleteSymptom } from '../state/symptoms';

const baseStyles = {
  addBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  mainBody: {
    height: 'calc(100% - 120px)',
    maxHeight: '100%',
  },
  dataGrid: {
    height: '100%',
    marginTop: 10,
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};

export class Symptoms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: false,
    };
    this.startAdding = this.startAdding.bind(this);
    this.handleAddSymptom = this.handleAddSymptom.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
  }
  componentWillMount() {
    this.props.browseSymptoms();
  }
  startAdding() {
    this.setState({
      adding: true,
    });
  }
  handleAddSymptom(details) {
    return this.props.addSymptom(details);
  }
  handleCloseAdd() {
    this.setState({ adding: false });
  }
  buildSymptoms = (symptoms) => {
    return map(symptoms, (s) => {
      console.log('s', s);
      return {
        id: s.id,
        name: s.name,
        type: s.type.replace(/_/g, ' '),
      };
    });
  }
  render() {
    const { symptoms, router } = this.props;
    let addNewSection = (
      <Button
        onClick={this.startAdding}
        variant="contained"
      >Add Symptom</Button>
    );
    const rows = this.buildSymptoms(symptoms.data);

    const cols = [
      { field: 'id', headerName: 'ID', width: 150 },
      { field: 'name', headerName: 'Name', width: 300 },
      { field: 'type', headerName: 'Type', width: 300 },
    ];

    if (this.state.adding) {
      addNewSection = (<Symptom
        handleSubmit={this.handleAddSymptom}
        handleCloseAdd={this.handleCloseAdd}
        style={baseStyles.topSection}
      />);
    }

    const handleClick = ({ id }) => {
      router.push(`/symptoms/${id}`);
    };

    return (
      <>
        <Header />
        <Box padding={1.25} style={baseStyles.mainBody}>
          <Box style={baseStyles.addBtn}>{addNewSection}</Box>
          <div style={{ marginTop: 10, height: `${this.state.adding ? 80 : 100}%` }}>
            <Table cols={cols} rows={rows} onClick={handleClick} />
          </div>
        </Box>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { customers, symptoms } = state;
  return {
    customers: map(customers.data || [], c => c),
    symptoms,
  };
}

const actionCreators = {
  addSymptom,
  editSymptom,
  browseCustomers,
  browseSymptoms,
  deleteSymptom,
};

Symptoms.defaultProps = {
  customers: [],
  symptoms: {},
};

Symptoms.propTypes = {
  addSymptom: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  browseSymptoms: PropTypes.func.isRequired,
  symptoms: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(Symptoms);
