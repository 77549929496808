import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { capitalize, find, map, reject } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import compareAlphabetically from '../lib/compare-alphabetically';
import Checkbox from '../base/checkbox';
import Header from '../components/header';
import ClinicSubheader from '../components/clinic-subheader';
import { browseClinicsIfNecessary } from '../state/clinics';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '125px',
  },
  checkbox: {
    top: '0px !important',
  },
  container: {
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
  },
  error: {
    color: colors.errorRed,
  },
  row: {
    cursor: 'pointer',
  },
  tableHeader: {
    textAlign: 'center',
  },
  topSection: {
    margin: '15px auto',
    width: '700px',
  },
};


export class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appFeatures: [],
      availableFeatures: [],
      availableFeaturesLoading: false,
      clinicFeaturesLoading: false,
      error: '',
    };
  }
  componentWillMount() {
    const { clinicId } = this.props;
    this.props.browseClinicsIfNecessary();
    this.setState({ availableFeaturesLoading: true, clinicFeaturesLoading: true });

    apiFetch('/clinic_features')
      .then((availableFeatures) => {
        this.setState({
          availableFeaturesLoading: false,
          availableFeatures: availableFeatures.sort(compareAlphabetically),
        });
      })
      .catch((e) => {
        this.setState({
          availableFeaturesLoading: false,
          error: 'An Error has occured fetching available features',
        });
      });
    apiFetch(`/clinics/${clinicId}/app_features`)
      .then((appFeatures) => {
        this.setState({
          appFeatures,
          clinicFeaturesLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          clinicFeaturesLoading: false,
          error: 'An error has occured fetching the clinic\'s features',
        });
      });
  }
  handleToggleFeature = (feature, clinicFeature) => {
    const { clinicId } = this.props;
    this.setState({ error: '' });
    if (clinicFeature) {
      return apiFetch(`/clinics/${clinicId}/app_features/${feature}`, { method: 'DELETE' })
        .then(() => {
          this.setState((prevState) => {
            return {
              appFeatures: reject(prevState.appFeatures, { app_feature: feature }),
            };
          });
        })
        .catch(this.handleError);
    }
    const reqOpts = {
      method: 'POST',
      body: {
        app_feature: feature,
      },
    };
    return apiFetch(`/clinics/${clinicId}/app_features`, reqOpts)
      .then((result) => {
        this.setState(prevState => ({
          appFeatures: prevState.appFeatures.concat(result),
        }));
      })
      .catch(this.handleError);
  }
  handleError = () => {
    this.setState({
      error: 'An error occured updating the clinic\'s features',
    });
  }
  render() {
    const { classes, clinic } = this.props;
    const {
      availableFeatures, availableFeaturesLoading, appFeatures, clinicFeaturesLoading, error,
    } = this.state;
    const rows = map(availableFeatures, (app_feature) => {
      const clinicFeature = find(appFeatures, { app_feature });
      return (
        <ListItem
          key={app_feature}
          className={classes.listItem}
          onClick={() => this.handleToggleFeature(app_feature, clinicFeature)}
        >
          <Checkbox
            checked={Boolean(clinicFeature)}
            classes={{ root: classes.checkbox }}
          />
          <ListItemText>{capitalize(app_feature.split('_').join(' '))}</ListItemText>
        </ListItem>
      );
    });


    return (
      <div className={classes.container}>
        <Header />
        <div>
          <ClinicSubheader currentPage="features" clinic={clinic} />
          {(availableFeaturesLoading || clinicFeaturesLoading) && (
            <CircularProgress size={100} thickness={7} style={{ margin: '50px' }} />
          )}
          {error && (
            <h2 className={classes.error}>{error}</h2>
          )}
          <List>
            {rows}
          </List>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { clinics, releases } = state;
  const { clinicId } = ownProps.routeParams;
  const clinic = clinics.data[clinicId] || {};

  return {
    clinic,
    clinicId, // in case clinic isn't loaded we can still fetch releases
    clinicReleases: releases.data,
  };
}

Features.propTypes = {
  browseClinicsIfNecessary: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  clinicId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { browseClinicsIfNecessary })(withStyles(styles)(Features));
