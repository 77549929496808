import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
  },
});

export default theme;
