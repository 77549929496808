import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { capitalize, get, noop } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { login } from '../state/self';
import { colors } from '../lib/styles';
import { isPasswordValid } from '../lib/valildators';

const baseStyles = {
  bottomText: {
    color: colors.highlightMedium,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  btnLabel: {
    fontSize: '18px',
    lineHeight: '2.9',
  },
  btnStyle: {
    height: '50px',
    margin: '10px',
    paddingTop: '5px',
    width: '100%',
  },
  container: {
    backgroundAttachment: 'scroll',
    backgroundColor: colors.primaryColor,
    backgroundImage: 'url("img/login-background.png")',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
  },
  headerText: {
    color: colors.white,
    margin: '0 auto',
    paddingTop: '60px',
    paddingBottom: '25px',
    textAlign: 'center',
    width: '500px',
  },
  header: {
    margin: 0,
    paddingBottom: '10px',
  },
  headerImg: {
    height: '180px',
    width: '180px',
  },
  loginBox: {
    backgroundColor: colors.white,
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
  },
  form: {
    margin: 'auto',
    padding: '35px 50px 50px',
  },
  field: {
    borderRadius: '10px',
    display: 'block',
    height: '70px',
    padding: '10px',
  },
  shrinkStyle: {
    display: 'none',
  },
  subHeader: {
    color: colors.highlightBright,
    fontSize: '14px',
    fontWeight: 100,
    padding: '3px',
  },
  textFieldLabel: {
    color: colors.white,
    fontSize: '18px',
    margin: '5px 15px',
  },
  textFieldInput: {
    backgroundColor: colors.primaryColor,
    color: colors.highlightBright,
    padding: '10px',
  },
};

const defaultErrors = {
  response: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export class Login extends Component {
  constructor(props) {
    super(props);

    const isReset = get(props, 'location.query.reset_id', false);

    this.state = {
      errors: defaultErrors,
      msg: '',
      email: '',
      password: '',
      new_password: '',
      verify_password: '',
      currentAction: isReset ? 'RESET' : 'LOGIN',
      additionalAuthLockoutMessage: '',
      showAuthLockoutMessage: false,
      sendingMFA: false,
      mfaToken: '',
      authedUser: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleMFASubmit = this.handleMFASubmit.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleResetPasswordValidatedUser = this.handleResetPasswordValidatedUser.bind(this);
    this.handleUpdateEmail = this.handleChange.bind(this, 'email');
    this.handleUpdatePassword = this.handleChange.bind(this, 'password');
    this.handleUpdateNewPassword = this.handleChange.bind(this, 'new_password');
    this.handleUpdateVerifyPassword = this.handleChange.bind(this, 'verify_password');
    this.handleUpdateMFAToken = this.handleChange.bind(this, 'mfaToken');
    this.handleUpdateVerifyPassword = this.handleChange.bind(this, 'verify_password');
    this.showForgotPassword = this.changePageAction.bind(this, 'FORGOT');
    this.showLogin = this.changePageAction.bind(this, 'LOGIN');
  }
  handleChange(field, evt, value) {
    this.setState({ [field]: value });
  }
  handleLogin() {
    this.setState({
      errors: defaultErrors,
      msg: '',
      password: '',
      additionalAuthLockoutMessage: '',
      showAuthLockoutMessage: false,
    });

    this.props.login(this.state.email, this.state.password)
      .then((user) => {
        this.setState({ authedUser: user });
        if (user.roles && user.roles.includes('NEEDS_MFA')) {
          return this.setState({
            currentAction: 'NEEDS_MFA',
          });
        }
        if (user.expired) {
          return this.setState({
            currentAction: 'EXPIRED',
          });
        }
        if (user.email_confirmed) {
          return this.props.router.push('/users');
        }
      })
      .catch((e) => {
        if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
          this.setState({
            showAuthLockoutMessage: true,
            additionalAuthLockoutMessage: e.content.failedLoginAttemptMessage,
          });
          return;
        }
        this.setState({
          errors: {
            ...this.state.errors,
            response: 'Invalid Email or Password',
          },
        });
      });
  }
  handleMFASubmit() {
    this.setState({
      errors: defaultErrors,
      msg: '',
      mfaToken: '',
      sendingMFA: true,
    });

    apiFetch('/auth/mfa', {
      body: {
        code: this.state.mfaToken,
        user_identifier: this.state.email,
      },
      method: 'POST',
    })
      .then(() => {
        if (this.state.authedUser && this.state.authedUser.expired) {
          return this.setState({
            currentAction: 'EXPIRED',
          });
        }
        document.location.href = '/users';
      })
      .catch((e) => {
        this.setState({
          errors: {
            ...this.state.errors,
            response: 'Invalid PIN',
          },
          sendingMFA: false,
          mfaToken: '',
        });
        if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
          this.setState({
            showAuthLockoutMessage: true,
            additionalAuthLockoutMessage: e.content.failedLoginAttemptMessage,
          });
        }
      });
  }
  async handleNewMFACode() {
    apiFetch(`/auth/${this.state.authedUser.id}/resend_mfa`, { method: 'POST' }).then(() => {
      this.setState({
        errors: defaultErrors,
        msg: 'New code sent',
        sendingMFA: false,
        mfaToken: '',
        newMFAsent: true,
      });
    }).catch(() => {
      this.setState({
        errors: {
          ...this.state.errors,
          response: 'Error sending new code',
        },
        msg: '',
        sendingMFA: false,
        mfaToken: '',
      });
    });
  }

  handleResetPassword() {
    const { password, verify_password } = this.state;
    const resetId = this.props.location.query.reset_id;
    const resetPayload = {
      password,
      verify_password,
    };
    if (password !== verify_password) {
      return this.setState({
        errors: {
            ...this.state.errors,
            response: 'Passwords must match',
          },
      });
    }
    const requestOptions = {
      body: resetPayload,
      method: 'PUT',
    };

    apiFetch(`/reset/${resetId}`, requestOptions)
      .then((res) => {
        return this.setState({
          errors: defaultErrors,
          verify_password: '',
          password: '',
          currentAction: 'LOGIN',
          msg: 'Password reset successfully, you may now login.',
        });
      })
      .catch((error) => {
        let msg = 'Error updating password';
        if (error.content && error.content.message) {
          const { content: { message } } = error;
          msg = message;
        } else if (error.message) {
          msg = error.message;
        }
        const formattedError = capitalize(msg.split(': ')[1].replace('_', ' ').replace(/"/g, ''));
        this.setState({
          email: '',
          errors: {
            ...this.state.errors,
            response: formattedError,
          }
        });
      });
  }

  handleResetPasswordValidatedUser() {
    const { password, new_password, verify_password } = this.state;
    const err = isPasswordValid(password, new_password, verify_password);
    if (Object.keys(err).length > 0) {
      return this.setState({
        errors: err,
      })
    }
    const { self } = this.props;

    const requestOptions = {
      body: {
        old_password: password,
        new_password,
        verify_password,
      },
      method: 'POST',
    };
    apiFetch(`/users/${self.id}/reset`, requestOptions)
      .then(() => {
        document.location.href = '/users';
      })
      .catch((error) => {
        let msg = 'Error updating password';

        if (error.content && error.content.message) {
          const { content: { message } } = error;
          msg = message;
        } else if (error.message) {
          msg = error.message;
        }
        const formattedError = capitalize(msg.split(': ')[1].replace('_', ' ').replace(/"/g, ''));
        return this.setState({
          errors: {
            ...this.state.errors,
            response: formattedError,
          }
        });
      });
  }
  handleForgotPassword(event) {
    event.preventDefault();
    const resetPayload = {
      reset_type: 'ADMIN',
      email: this.state.email,
    };
    const requestOptions = {
      body: resetPayload,
      method: 'POST',
    };

    return apiFetch('/reset', requestOptions)
      .then((res) => {
        return this.setState({
          email: '',
          errors: defaultErrors,
          currentAction: 'LOGIN',
          msg: 'Please check your email for password reset instructions',
        });
      })
      .catch(() => {
        this.setState({
          email: '',
          errors: {
            ...this.state.errors,
            response: 'Error Sending Password Reset Email',
          },
        });
      });
  }
  changePageAction(view) {
    this.setState({
      currentAction: view,
    });
  }
  render() {
    const { showAuthLockoutMessage, additionalAuthLockoutMessage } = this.state;
    let form;
    if (this.state.currentAction === 'LOGIN') {
      form = [
        <TextField
          className="login-field"
          floatingLabelText="Email"
          floatingLabelShrinkStyle={baseStyles.shrinkStyle}
          floatingLabelStyle={baseStyles.textFieldLabel}
          fullWidth={true}
          inputStyle={baseStyles.textFieldInput}
          onChange={this.handleUpdateEmail}
          style={baseStyles.field}
          underlineShow={false}
          value={this.state.email}
        />,
        <TextField
          className="login-field"
          floatingLabelText="Password"
          floatingLabelShrinkStyle={baseStyles.shrinkStyle}
          floatingLabelStyle={baseStyles.textFieldLabel}
          fullWidth={true}
          hintText="Your Password"
          inputStyle={baseStyles.textFieldInput}
          onChange={this.handleUpdatePassword}
          style={baseStyles.field}
          type="password"
          underlineShow={false}
          value={this.state.password}
        />,
        <Button
          color="secondary"
          style={baseStyles.btnStyle}
          variant="contained"
          type="input"
          onClick={this.handleLogin}
        >
          Sign In
        </Button>,
        <p onClick={this.showForgotPassword} style={baseStyles.bottomText}>Forgot your password?</p>,
      ];
    }

    if (this.state.currentAction === 'NEEDS_MFA') {
      form = (
        <>
          <TextField
            className="login-field"
            floatingLabelText="One Time PIN Code"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            inputStyle={baseStyles.textFieldInput}
            onChange={this.handleUpdateMFAToken}
            style={baseStyles.field}
            underlineShow={false}
            value={this.state.mfaToken}
          />
          <Button
            color="secondary"
            variant="contained"
            style={baseStyles.btnStyle}
            type="input"
            disabled={this.state.sendingMFA || !this.state.mfaToken}
            onClick={this.handleMFASubmit}
          >
            Submit
          </Button>
          <Button
            key="resendMFA"
            style={{ ...baseStyles.btnStyle, display: this.state.newMFAsent ? 'none' : 'block' }}
            onClick={this.handleNewMFACode}
            variant="contained"
          >Request New Code
          </Button>
          <p onClick={this.showLogin} style={baseStyles.bottomText}>Cancel</p>
        </>
      );
    }

    if (this.state.currentAction === 'RESET') {
      form = (
        <form onSubmit={this.handleResetPassword}>
          <TextField
            className="login-field"
            floatingLabelText="New Password"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            hintText="Your Password"
            inputStyle={baseStyles.textFieldInput}
            onChange={this.handleUpdatePassword}
            style={baseStyles.field}
            type="password"
            underlineShow={false}
            value={this.state.password}
            error={this.state.errors.newPassword}
          />
          <TextField
            className="login-field"
            floatingLabelText="Verify Password"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            hintText="Your Password"
            inputStyle={baseStyles.textFieldInput}
            onChange={this.handleUpdateVerifyPassword}
            style={baseStyles.field}
            type="password"
            underlineShow={false}
            value={this.state.verify_password}
            error={this.state.errors.confirmPassword}
          />
          <Button
            color="secondary"
            style={baseStyles.btnStyle}
            variant="contained"
            type="input"
          >
            Reset Password
          </Button>
        </form>
      );
    }

    if (this.state.currentAction === 'FORGOT') {
      form = (
        <form onSubmit={this.handleForgotPassword}>
          <TextField
            className="login-field"
            floatingLabelText="Email Address"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            inputStyle={baseStyles.textFieldInput}
            onChange={this.handleUpdateEmail}
            style={baseStyles.field}
            underlineShow={false}
            value={this.state.email}
          />
          <Button
            color="secondary"
            variant="contained"
            style={baseStyles.btnStyle}
            type="input"
          >
            Reset Password
          </Button>
          <p onClick={this.showLogin} style={baseStyles.bottomText}>Cancel</p>
        </form>
      );
    }
    if (this.state.currentAction === 'EXPIRED') {
      form = [
        <div style={baseStyles.warningBanner}>
          <div style={baseStyles.warningRegular}>
            Your password has expired.
          </div>
          <div style={baseStyles.warningRegular}>
            Please create a new password to continue.
          </div>
        </div>,
        <FormControl fullWidth error={this.state.errors.oldPassword}>
          <TextField
            className="login-field"
            floatingLabelText="Old Password"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            inputStyle={baseStyles.textFieldInput}
            key="password"
            onChange={this.handleUpdatePassword}
            type="password"
            value={this.state.password}
            style={baseStyles.field}
            underlineShow={false}
          />
          <FormHelperText style={{ marginLeft: 10 }} id="old-password-text">{this.state.errors.oldPassword}</FormHelperText>
        </FormControl>,
        <FormControl fullWidth error={this.state.errors.newPassword}>
          <TextField
            key="newPassword"
            className="login-field"
            floatingLabelText="New Password"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            inputStyle={baseStyles.textFieldInput}
            style={baseStyles.field}
            underlineShow={false}
            onChange={this.handleUpdateNewPassword}
            type="password"
            value={this.state.new_password}
          />
          <FormHelperText style={{ marginLeft: 10 }} id="new-password-error-text">{this.state.errors.newPassword}</FormHelperText>
        </FormControl>,
        <FormControl fullWidth error={this.state.errors.confirmPassword}>
          <TextField
            key="verify_password"
            className="login-field"
            floatingLabelText="Verify Password"
            floatingLabelShrinkStyle={baseStyles.shrinkStyle}
            floatingLabelStyle={baseStyles.textFieldLabel}
            fullWidth={true}
            inputStyle={baseStyles.textFieldInput}
            style={baseStyles.field}
            underlineShow={false}
            onChange={this.handleUpdateVerifyPassword}
            type="password"
            value={this.state.verify_password}
          />
          <FormHelperText style={{ marginLeft: 10 }} id="confirm-password-error-text">{this.state.errors.confirmPassword}</FormHelperText>
        </FormControl>,
        <Button
          color="secondary"
          key="reset"
          style={baseStyles.btnStyle}
          onClick={this.handleResetPasswordValidatedUser}
          variant="contained"
        >Update Password
        </Button>,
        <p key="login" onClick={this.showLogin} style={baseStyles.bottomText}>Cancel</p>
      ];
    }
    return (
      <div style={baseStyles.container} >
        <div style={baseStyles.headerText}>
          <img
            alt="Mindset Medical Logo"
            src="img/informed_blue.svg"
            style={baseStyles.headerImg}
          />
          <h2 style={baseStyles.header}>Welcome to Informed</h2>
          <div style={baseStyles.subHeader}>You will never need to fill out</div>
          <div style={baseStyles.subHeader}>medical paperwork ever again!</div>
        </div>
        <div style={baseStyles.loginBox}>
          <div style={baseStyles.messageContainer}>
            {this.state.errors.response && <div style={baseStyles.errorText}>{this.state.errors.response}</div>}
            {showAuthLockoutMessage ? (
              <div style={baseStyles.errorText}>
                {`You have entered invalid credentials too many times. ${
                  additionalAuthLockoutMessage ? additionalAuthLockoutMessage + '. ' : ''
                }Please contact your administrator if you need login assistance.`}
              </div>
            ) : null}
            {this.state.msg || this.props.self.logoutMessage ? (
              <div style={baseStyles.msgText}>{this.state.msg || this.props.self.logoutMessage}</div>
            ) : null}
          </div>
          <div style={baseStyles.form}>
            {form}
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { self } = state;
  return { self };
}

function mapDispatchToProps(dispatch) {
  const actionCreators = { login };

  return bindActionCreators(actionCreators, dispatch);
}

Login.defaultProps = {
  location: {},
  login: noop,
  router: {},
  self: {},
};

Login.propTypes = {
  location: PropTypes.object,
  login: PropTypes.func,
  router: PropTypes.object,
  self: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
