import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { capitalize, forEach, get } from 'lodash';
import { List, ListItem } from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import { colors } from '../lib/styles';

const baseStyles = {
  itemStyles: {
    color: colors.black,
    fontSize: '1.5rem',
    textAlign: 'left',
  },
  secondaryText: {
    color: colors.highlightDark,
  },
};

class ProviderRow extends Component {
  onSelectProvider = () => {
    this.props.onSelect(this.props.provider);
  }
  render() {
    const { props } = this;
    const prefix = capitalize(get(props, 'provider.basic.name_prefix', ''));
    const first = capitalize(get(props, 'provider.basic.first_name', ''));
    const last = capitalize(get(props, 'provider.basic.last_name', ''));

    const desc = capitalize(get(props, 'provider.taxonomies.0.desc', ''));
    const address = get(props, 'provider.addresses.0.address_1', '');
    const city = capitalize(get(props, 'provider.addresses.0.city', ''));
    const state = get(props, 'provider.addresses.0.state', '');

    return (
      <ListItem
        data={props.provider}
        primaryText={`${prefix} ${first} ${last}`}
        onClick={this.onSelectProvider}
        style={baseStyles.itemStyles}
        secondaryText={(
          <p style={baseStyles.secondaryText}>
            {desc} - {address} {city} {state}
          </p>
          )}
        secondaryTextLines={2}
      />
    );
  }
}

ProviderRow.propTypes = {
  onSelect: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
};


class ProviderTable extends Component {
  render() {
    const providers = [];
    forEach(this.props.providers, p => {
      providers.push((<ProviderRow onSelect={this.props.onSelectProvider} key={p.number} provider={p} />));
      providers.push((<Divider key={`${p.number}-divider`} inset={true} />));
    });
    return (
      <List>
        <Subheader>Search Results</Subheader>
        {providers}
      </List>
    );
  }
}

ProviderTable.propTypes = {
  providers: PropTypes.array.isRequired,
  onSelectProvider: PropTypes.func.isRequired,
};

export default ProviderTable;
