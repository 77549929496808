import { promiseHandler } from 'cooldux';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'logs');

export function browseLogs(filter) {
  return function dispatcher(dispatch) {
    const promise = apiFetch('/access_logs', { params: filter });
    return browseHandler(promise, dispatch);
  };
}

const initialState = {
  error: null,
  isFetching: false,
  searchResults: [],
  data: {},
};

function finishBrowse(state, logs) {
  const data = {};
  const searchResults = [];
  logs.forEach((l) => {
    data[l.id] = l;
    searchResults.push(l.id);
  });
  return { ...state, searchResults, data, isFetching: false, error: null };
}


function logs(state = initialState, action) {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return state;
  }
}

export default logs;
