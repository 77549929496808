import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, omit } from 'lodash';

import Paper from 'material-ui/Paper';
import Button from '@material-ui/core/Button';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';

const baseStyles = {
  btn: {
    margin: '5px',
  },
  buttons: {
    marginTop: '20px',
  },
  checkbox: {
    marginLeft: '50px',
    margin: '30px 0px 0px 50px',
    width: '33%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '300px',
  },
};

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.customer,
      dataChanged: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancelAdd = this.onCancelAdd.bind(this);
    this.onUpdateName = this.onUpdateField.bind(this, 'name');
    this.onUpdateAddress = this.onUpdateField.bind(this, 'address');
    this.onUpdateAddress2 = this.onUpdateField.bind(this, 'address_2');
    this.onUpdateCity = this.onUpdateField.bind(this, 'city');
    this.onUpdateState = this.onUpdateField.bind(this, 'state');
    this.onUpdateZip = this.onUpdateField.bind(this, 'zip_code');
    this.onUpdatePhone = this.onUpdateField.bind(this, 'phone');
    this.onCheckActive = this.onCheckActive.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.customer !== this.props.customer) {
      this.setState({ ...nextProps.customer });
    }
  }
  onCancelAdd() {
    this.props.handleCloseAdd();
    this.setState({
      dataChanged: false,
      name: '',
      address: '',
      address_2: '',
      city: '',
      state: '',
      zip_code: '',
      active: false,
      error: '',
    });
  }
  onUpdateField(fieldName, e, value) {
    this.setState({
      [fieldName]: value,
      dataChanged: true,
    });
  }
  onSubmit() {
    const payload = omit(this.state, ['error', 'dataChanged']);
    this.props.handleSubmit(payload)
      .then(() => {
        this.setState({
          dataChanged: false,
          error: '',
          ...this.props.customer,
        });
        this.props.handleCloseAdd();
      })
      .catch(() => {
        this.setState({
          error: 'An error has occured',
        });
      });
  }
  onCheckActive() {
    this.setState({
      active: !this.state.active,
      dataChanged: true,
    });
  }
  render() {
    const containerStyle = Object.assign({}, baseStyles.container, this.props.style);
    return (
      <Paper style={containerStyle}>

        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Customer Name"
            hintText="Dr. John Smith"
            onChange={this.onUpdateName}
            value={this.state.name || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Address"
            hintText="1450 SW Roadside St"
            onChange={this.onUpdateAddress}
            value={this.state.address || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Address 2"
            hintText="Suite 430"
            onChange={this.onUpdateAddress2}
            value={this.state.address_2 || ''}
          />
        </div>

        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="City"
            hintText="Phoenix"
            onChange={this.onUpdateCity}
            value={this.state.city || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="State"
            hintText="AZ"
            onChange={this.onUpdateState}
            value={this.state.state || ''}
          />
          <TextField
            autoComplete="off"
            floatingLabelText="Postal Code"
            hintText="85281"
            onChange={this.onUpdateZip}
            value={this.state.zip_code || ''}
          />
        </div>

        <div style={baseStyles.fieldRow}>
          <TextField
            autoComplete="off"
            floatingLabelText="Phone"
            hintText="480 (867)-5309"
            onChange={this.onUpdatePhone}
            value={this.state.phone || ''}
          />
          <Checkbox
            label="Active"
            checked={this.state.active}
            onCheck={this.onCheckActive}
            style={baseStyles.checkbox}
          />
          <div style={baseStyles.buttons}>
            { this.props.showClose ? (
              <Button
                onClick={this.onCancelAdd}
                style={baseStyles.btn}
                variant="contained"
              >Cancel
              </Button>
            ) : null}
            <Button
              disabled={!this.state.dataChanged}
              onClick={this.onSubmit}
              style={baseStyles.btn}
              variant="contained"
            >{this.props.submitText}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

Customer.defaultProps = {
  handleCloseAdd: noop,
  customer: {
    name: '',
    address: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
    active: true,
  },
  showClose: true,
  style: {},
  submitText: 'Create Customer',
};

Customer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCloseAdd: PropTypes.func,
  customer: PropTypes.object,
  showClose: PropTypes.bool,
  style: PropTypes.object,
  submitText: PropTypes.string,
};

export default Customer;
