import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, map, omit, includes } from 'lodash';
import moment from 'moment';

import {
  AutoComplete, SelectField,
  MenuItem, RaisedButton,
} from 'material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/RemoveCircle';
import Header from '../components/header';
import Clinic from '../components/clinic';
import ImageUpload from '../components/image-upload';
import ClinicSubheader from '../components/clinic-subheader';
import searchFilter from '../lib/lowercase-search-filter';

import {
  browseClinics, editClinic, addClinic, deleteClinic,
  browseClinicProviders, addClinicProvider, deleteClinicProvider,
  browseClinicUsers, addClinicUser,
  browseClinicImages, browseClinicPatients, addClinicImage, readClinic
} from '../state/clinics';
import { browseCustomers } from '../state/customers';
import {
  readIntegrations, browseInstances, browseTypes,
  addIntegration,
} from '../state/emr';

import config from '../config';

import { browseProviders } from '../state/providers';
import { browseUsers } from '../state/users';
import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';

const baseStyles = {
  addBtn: {
    marginRight: '20px',
    position: 'absolute',
    right: '-10px',
    top: '65px',
  },
  bottomLists: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  clinicStyle: {
    margin: '0px auto 25px',
    width: '950px',
  },
  container: {
    width: '100%',
  },
  deleteButtonDialog: {
    background: colors.errorRed,
    '&:hover': {
      background: colors.errorRed,
    },
  },
  liStyle: {
    fontSize: '1.1em',
    listStyleType: 'none',
  },
  imageUpload: {
    margin: '10px auto',
  },
  listSection: {
    width: '500px',
  },
  listText: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mainBody: {
    margin: '10px',
  },
  tableHeader: {
    textAlign: 'center',
  },
  row: {
    cursor: 'pointer',
  },
  topSection: {
    margin: '0 auto 25px',
    width: '950px',
  },
  twoColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  twoColumnBox: {
    margin: '30px',
  },
};

const getUserLockedOut = (user) => {
  const { locked_out_at, lockout_duration } = user;
  const now = Date.now();
  let lockedOut = false;

  if (locked_out_at && lockout_duration) {
    const proLockedOutAt = new Date(locked_out_at).getTime();
    const proLockoutDuration = Number(lockout_duration);
    if (proLockedOutAt + proLockoutDuration > now) {
      lockedOut = true;
    }
  }

  return lockedOut;
};

const handleExportPatients = (patients) => {
  // eslint-disable-next-line max-len
  const header = 'User ID, First Name, Last Name, Email, Birth Date';
  const rows = [header];

  patients.forEach((patient) => {
    const {
      user_id,
      first_name,
      last_name,
      email,
      birth_date,
    } = patient;

    rows.push(`${user_id},${first_name},${last_name},${email},${birth_date}`);
  });

  const csvReport = rows.join('\n');
  const dateStr = moment();

  const downloadTag = document.createElement('a');
  downloadTag.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvReport));
  downloadTag.setAttribute('download', `patients_${dateStr}.csv`);
  downloadTag.style.display = 'none';

  document.body.appendChild(downloadTag);

  downloadTag.click();

  document.body.removeChild(downloadTag);
};

// makes searching case insensitive

export class Clinics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerSearchText: '',
      userSearchText: '',
      typeId: '',
      source_id: [],
      clinicUsers: [],
      userPendingDelete: null,
      dialogOpen: false,
    };
    this.handleEditClinic = this.handleEditClinic.bind(this);
    this.handleUploadLogo = this.handleUploadLogo.bind(this);
    this.onProviderSelect = this.onProviderSelect.bind(this);
    this.onUserSelect = this.onUserSelect.bind(this);
    this.onUpdateProviderText = this.onUpdateSearchText.bind(this, 'providerSearchText');
    this.onUpdateUserText = this.onUpdateSearchText.bind(this, 'userSearchText');
    this.onUpdateTypeId = this.onUpdateTypeId.bind(this);
    this.onAddIntegration = this.onAddIntegration.bind(this);
  }
  componentWillMount() {
    const { clinic, customers, clinicId } = this.props;
    // Needs to be a read instead of browse
    // Currently this relies on having all users/providers/clinics prefetched
    // Next iteration will do reads for these if they don't exist
    this.props.browseTypes();
    this.props.readIntegrations(clinicId);
    this.props.browseUsers();
    this.props.browseProviders();
    apiFetch('/sources').then((res) => {
      this.setState({ source_id: res });
    });
    if (!customers || !customers.length) {
      this.props.browseCustomers();
    }
    if (!clinic) {
      return this.props.readClinic(clinicId);
    }
    this.props.browseClinicProviders(clinic.id);
    this.props.browseClinicUsers(clinic.id);
    this.props.browseClinicImages(clinic.id);
    this.props.browseClinicPatients(clinic.id, '?limit=ALL');
  }
  componentWillReceiveProps(nextProps) {
    const oldClinicId = get(this.props, 'clinic.id', 0);
    const newClinicId = get(nextProps, 'clinic.id', 0);
    if (newClinicId && (newClinicId !== oldClinicId)) {
      this.props.browseClinicProviders(newClinicId);
      this.props.browseClinicUsers(newClinicId);
      this.props.browseClinicImages(newClinicId);
      this.props.browseClinicPatients(newClinicId);
    }
    if (nextProps.clinicUsers) {
      this.setState({ clinicUsers: [...nextProps.clinicUsers] });
    }
  }

  onUpdateTypeId(event, index, value) {
    this.setState({ typeId: value });
    this.props.browseInstances(value);
  }

  onAddIntegration(instanceId) {
    this.props.addIntegration(instanceId, this.props.clinicId);
  }

  onProviderSelect(provider) {
    this.props.addClinicProvider(this.props.clinic.id, provider.id);
    this.setState({
      providerSearchText: '',
    });
  }

  onUserSelect(user) {
    this.props.addClinicUser(this.props.clinic.id, user.id);
    this.setState({
      userSearchText: '',
    });
  }

  onUpdateSearchText(field, text) {
    this.setState({
      [field]: text,
    });
  }

  handleEditClinic(details) {
    return this.props.editClinic(details)
      .then(() => {
        // Display Success Message
      })
      .catch(() => {
        // Handle Error
      });
  }

  handleUploadLogo(img) {
    this.props.addClinicImage(this.props.clinic.id, img);
  }

  handleDeleteUser = () => {
    const { clinicId } = this.props;
    const { clinicUsers, userPendingDelete } = this.state;

    apiFetch(`/clinics/${clinicId}/users/${userPendingDelete.id}`, { method: 'DELETE' })
      .then(() => {
        this.setState({
          clinicUsers: clinicUsers.filter(user => user.id !== userPendingDelete.id),
          dialogOpen: false,
          userPendingDelete: null,
        });
      });
  }

  handleOpenDeleteDialog = (user) => {
    this.setState({
      dialogOpen: true,
      userPendingDelete: user,
    });
  }

  handleCloseDeleteDialog = () => {
    this.setState({
      dialogOpen: false,
      userPendingDelete: null,
    });
  }

  render() {
    const {
      classes,
      clinic,
      clinicId,
      clinicProviders,
      emr,
      isFetching,
      providers,
      users,
    } = this.props;
    const { clinicUsers, dialogOpen } = this.state;

    let noClinicMessage;
    let patientTotal = '';
    let patients = {};

    if (clinic && clinic.patients) {
      patientTotal = clinic.patients.length;
      patients = clinic.patients;
    }
    if (!clinic && isFetching) {
      noClinicMessage = <h1> Fetching </h1>;
    }
    if (!clinic && !isFetching) {
      noClinicMessage = <h1> Not Found </h1>;
    }
    const logoImage = `${config.API_URL}/clinics/${clinicId}/logo/400?cache=${get(clinic, 'imageCacheId')}`;

    const providerList = clinicProviders.map((provider) => {
      return <li key={`provider-${provider.id}`}>{`${provider.name} - ${provider.provider_specialty}`}</li>;
    });

    const integrationList = map(emr.integrations, row => (
      <li key={row.id}>{`${row.name} - ${row.version}`}</li>
    ));

    const typesMenuItems = map(emr.typeData, row => (
      <MenuItem key={row.id} value={row.id} primaryText={row.name} />
    ));

    const instanceList = map(emr.instanceData, (row) => {
      const exists = includes(map(emr.integrations, 'id'), row.id);
      if (!exists) {
        return (<li key={row.id} style={baseStyles.liStyle}>
          {row.name} - <RaisedButton
            label="Add Integration"
            onClick={() => this.onAddIntegration(row.id)}
          />
        </li>);
      }
      return (<li key={row.id} style={baseStyles.liStyle}>
        {row.name} - <RaisedButton
          label="Integration Exists"
          disabled
          onClick={() => this.onAddIntegration(row.id)}
        />
      </li>);
    });

    return (
      <div style={baseStyles.container}>
        <Header />
        <div style={baseStyles.mainBody}>
          {noClinicMessage ||
            <div>
              <ClinicSubheader currentPage="main" clinic={clinic} />
              <ImageUpload
                alt="Clinic Logo"
                containerStyle={baseStyles.imageUpload}
                handleUpload={this.handleUploadLogo}
                displayImage={logoImage}
                uploadText="Upload New Logo"
              />
              <div style={{ display: 'flex', margin: '10px auto', justifyContent: 'center' }}>
                <h3>Total Patients: {patientTotal}</h3>
                <Button style={{ height: '30px', alignSelf: 'center', margin: ' 0 10px ' }} onClick={() => handleExportPatients(patients)} variant="contained">
                  Export
                </Button>
              </div>
              <Clinic
                clinic={omit(clinic, ['logo', 'providers', 'users'])}
                customers={this.props.customers}
                source_id={this.state.source_id}
                handleSubmit={this.handleEditClinic}
                showClose={false}
                submitText="Update Clinic"
                style={baseStyles.clinicStyle}
              />
              <div style={baseStyles.bottomLists}>
                <div>
                  <h2>Providers</h2>
                  <AutoComplete
                    dataSource={providers}
                    dataSourceConfig={{ text: 'name', value: 'id' }}
                    filter={searchFilter}
                    fullWidth={true}
                    hintText="Add a Provider"
                    onNewRequest={this.onProviderSelect}
                    openOnFocus={false}
                    onUpdateInput={this.onUpdateProviderText}
                    searchText={this.state.providerSearchText}
                    underlineShow={true}
                  />
                  <ul>
                    {providerList}
                  </ul>
                </div>
                <div>
                  <h2>Users</h2>
                  <AutoComplete
                    dataSource={users}
                    dataSourceConfig={{ text: 'username', value: 'id' }}
                    filter={searchFilter}
                    fullWidth={true}
                    hintText="Add a User"
                    onNewRequest={this.onUserSelect}
                    openOnFocus={false}
                    onUpdateInput={this.onUpdateUserText}
                    searchText={this.state.userSearchText}
                    underlineShow={true}
                  />
                  <List dense={true}>
                    {clinicUsers.map((user) => {
                      const userLockedOut = getUserLockedOut(user);
                      const style = userLockedOut ? { color: colors.errorRed } : {};
                      return (
                        <ListItem key={user.id}>
                          <ListItemText
                            primary={`${user.username} - ${user.email}`}
                            classes={{ primary: classes.listText }}
                            style={style}
                          />
                          <ListItemSecondaryAction onClick={() => this.handleOpenDeleteDialog(user)}>
                            <IconButton aria-label="Delete">
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
              <div style={baseStyles.twoColumn}>
                <h2>EMR Instances</h2>
              </div>
              <div style={baseStyles.twoColumn}>
                <div style={baseStyles.twoColumnBox}>
                  <h3>Current Instances</h3>
                  <ul>
                    {integrationList}
                  </ul>
                </div>
                <div style={baseStyles.twoColumnBox}>
                  <h3>Add Instances</h3>
                  <SelectField
                    autoComplete="off"
                    floatingLabelText="Choose a Type to Add Integration"
                    value={this.state.typeId}
                    onChange={this.onUpdateTypeId}
                  >
                    {typesMenuItems}
                  </SelectField>
                  {instanceList}
                </div>
              </div>
            </div>
          }
        </div>
        <Dialog
          open={dialogOpen}
          onClose={this.handleCloseDeleteDialog}
        >
          <DialogTitle>Remove user from clinic?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The user will no longer be associated with the clinic as a provider
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteDialog} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={this.handleDeleteUser}
              variant="contained"
            >
              Remove User
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

// Currently this relies on having all users/providers/clinics prefetched
// Next iteration will do reads for these if they don't exist
function mapStateToProps(state, ownProps) {
  const { clinics, customers, emr, instances, users, providers } = state;
  const clinic = clinics.data[ownProps.routeParams.clinicId];
  const clinicProviderIds = get(clinic, 'providers', []);
  const clinicUserIds = get(clinic, 'users', []);
  const clinicId = ownProps.routeParams.clinicId;

  const clinicProviders = clinicProviderIds.map(pId => providers.data[pId]);
  const clinicUsers = clinicUserIds.map(uId => users.data[uId]);

  // So users/providers at a clinic don't show as an option to select
  const filteredUsers = map(users.data, u => u)
    .filter(u => !clinicUserIds.includes(u.id));

  const filteredProviders = map(providers.data, p => p)
    .filter(p => !clinicProviderIds.includes(p.id));
  return {
    clinic,
    clinicId,
    emr,
    isFetching: clinics.isFetching,
    clinicProviders,
    clinicUsers,
    customers: map(customers.data || [], c => c),
    instances,
    users: filteredUsers,
    providers: filteredProviders,
  };
}

const actionCreators = {
  addClinic,
  addClinicProvider,
  addClinicUser,
  addClinicImage,
  addIntegration,
  browseClinicImages,
  browseClinicPatients,
  browseClinicProviders,
  browseClinicUsers,
  browseClinics,
  readClinic,
  browseCustomers,
  browseInstances,
  browseProviders,
  browseTypes,
  browseUsers,
  deleteClinic,
  deleteClinicProvider,
  editClinic,
  readIntegrations,
};

Clinics.defaultProps = {
  clinic: null,
  customers: [],
  providers: [],
  users: [],
};

Clinics.propTypes = {
  addClinicImage: PropTypes.func.isRequired,
  addClinicProvider: PropTypes.func.isRequired,
  addClinicUser: PropTypes.func.isRequired,
  addIntegration: PropTypes.func.isRequired,
  browseClinicImages: PropTypes.func.isRequired,
  browseClinicPatients: PropTypes.func.isRequired,
  browseClinicProviders: PropTypes.func.isRequired,
  browseClinicUsers: PropTypes.func.isRequired,
  browseClinics: PropTypes.func.isRequired,
  browseCustomers: PropTypes.func.isRequired,
  browseInstances: PropTypes.func.isRequired,
  browseProviders: PropTypes.func.isRequired,
  browseUsers: PropTypes.func.isRequired,
  browseTypes: PropTypes.func.isRequired,
  clinic: PropTypes.object,
  clinicId: PropTypes.string.isRequired,
  clinicUsers: PropTypes.array.isRequired,
  customers: PropTypes.array,
  //  deleteClinic: PropTypes.func.isRequired,
  //  deleteClinicProvider: PropTypes.func.isRequired,
  editClinic: PropTypes.func.isRequired,
  //  isFetching: PropTypes.bool,
  readIntegrations: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(baseStyles)(Clinics));
